import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {
			padding: 15,
			marginTop: 10,
		},
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			padding: 4,
			marginTop: 10,
			flexDirection: 'column',
		},
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	title: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.9rem',
		},
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		width: '260px',
	},
}));

const initialState = [
	{
	  "name": "Akhmetyanova A.I.",
	  "body": "Modeling the basis of homodesmic reactions of chemical compounds",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Azizova Zuleyxa Oktay",
	  "body": "Hardy type operators in the local \"complementary\" generalized variable exponent weighted Morrey spaces",
	  "from": "Azerbaijan State Oil and Industry University"
	},
	{
	  "name": "Garifullin Rustem Nailevish",
	  "body": "Модифицированные серии интегрируемых дискретных уравнений на квадратной решетке с нестандартной симметрийной структурой",
	  "from": "Институт математики с ВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Kerimbaev R.K., Dosmagulova K.A.",
	  "body": "Algorithmic complexity of linear nonassociative algebra",
	  "from": "Al-Farabi Kazakh National University, Almaty"
	},
	{
	  "name": "Mussabayeva G.K., Tleukhanova N.T.",
	  "body": "Property of the Fourier coefficients of a function from the Lorentz space $L_{\\bar{2},\\bar{r}}$ with a mixed metric",
	  "from": "Gumilyov ENU, Nur-Sultan"
	},
	{
	  "name": "Nikolaeva S.S.",
	  "body": "Parameter estimation of chaotic maps using particle swarm optimization",
	  "from": "ИМех УФИЦ РАН, Уфа"
	},
	{
	  "name": "Yusupova R.M., Lavrik I.D., Karamov D.D.,  ",
	  "body": "Accretion flow on Joshi-Malafarina-Narayan naked singularity",
	  "from": "Institute of Molecule and Crystal Physics, Уфа"
	},
	{
	  "name": "Абанин А.В.",
	  "body": " Топологическая структура семейства композиционных операторов в пространствах Бергмана",
	  "from": "Южный федеральный университет, Ростов-на-Дону"
	},
	{
	  "name": "Абдрахманов Айдар Радикович",
	  "body": "Моделирование циклического заводнения путем задания периодических условий Дирихле для задачи двухфазной фильтрации",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Абдуллин А.В.",
	  "body": "О построении областей синхронизации неавтономного уравнения Ван-дер-Поля",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Абдурагимов Г.Э.",
	  "body": "О существовании и единственности положительного решения краевой задачи для одного нелинейного функционально-дифференциального уравнения второго порядка",
	  "from": " ДГУ, Махачкала"
	},
	{
	  "name": "Абузярова Н.Ф.",
	  "body": "Синтезируемые последовательности и главные подмодули в модуле Шварца",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Авхадиев Ф.Г.",
	  "body": " Усиленные неравенства типа Харди, содержащие градиент функции расстояния области ",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Авхадиев Ф.Г. ",
	  "body": "Теорема о сходимости градиентов функций расстояния при исчерпании области",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Агзамова М.Р., Маннанова Г.И., Губайдуллин И.М. ",
	  "body": "Представление процесса каталитического  риформинга в виде графов",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Агзамова Миляуша Раиловна",
	  "from": "Уфимский государственный нефтяной технический университет",
	  "body": "Нелинейные интегральные уравнения типа свертки в комплексных пространствах"
	},
	{
	  "name": "Акманова С.В.",
	  "body": "Устойчивость и бифуркации непрерывно-дискретных динамических систем",
	  "from": "Магнитогорский государственный технический университет им. Г.И.Носова , Магнитогорск"
	},
	{
	  "name": "Алдашев С.А. ",
	  "body": "Критерий однозначной разрешимости спектральной смешанной задачи для многомерного уравнения Лаврентьева-Бицадзе",
	  "from": "КазНПУ, Алматы"
	},
	{
	  "name": "Алдашев Серик Аймурзаевич",
	  "from": "Казахский государственный педагогический университет, Алматы",
	  "body": "Критерий однозначной разрешимости спектральной смешанной задачи для многомерного уравнения Лаврентьева-Бицадзе"
	},
	{
	  "name": "Алексеева Е.С., Рассадин А.Э. ",
	  "body": "О некоторых обобщениях потенциалов Калоджеро, Морзе и Пёшля-Теллера",
	  "from": "член Нижегородского математического общества; член Правления Нижегородского математического общества, Нижний Новгород"
	},
	{
	  "name": "Алланазарова Т.Ж., Муминов У.Б.",
	  "body": "Интегрирование модифицированного уравнения Кортевега-де Фриза с нагруженными членами",
	  "from": "Каракалпакский госуниверситет им. Бердаха, Нукус"
	},
	{
	  "name": "Алланазарова Тазагул Жуманиязовна",
	  "body": "ИНТЕГРИРОВАНИЕ МОДИФИЦИРОВАННОГО УРАВНЕНИЯ КОРТЕВЕГА-ДЕ ФРИЗА С НАГРУЖЕННЫМИ ЧЛЕНАМИ",
	  "from": "Каракалпакский государственный университет, Нукус"
	},
	{
	  "name": "Амосов Г.Г.",
	  "body": " О сингулярных возмущениях полугрупп операторов в гильбертовом пространстве и на алгебре всех ограниченных операторов ",
	  "from": "Математический институт им. В.А. Стеклова РАН, Москва"
	},
	{
	  "name": "Анияров А. А., Нурахметов Д.Б., Джумабаев С.А., Кусаинов Р.К.",
	  "body": "Об одном способе решении обратной задачи балки со средоточечными элементами ",
	  "from": "Институт математики и математического моделирования, Алматы"
	},
	{
	  "name": "Арефьев И., Еникеева Л.В. ",
	  "body": "Математическое моделирование процесса сернокислотного алкилирования изобутана олефинами",
	  "from": " УФИЦ ИНК РАН , Уфа"
	},
	{
	  "name": "Арефьев Илья Александрович",
	  "from": "Институт Нефтехимии и Катализа УФИЦ РАН, Уфа",
	  "body": "Математическое моделирование процесса сернокислотного алкилирования изобутана олефинами"
	},
	{
	  "name": "Асташкин С.В. ",
	  "body": "Новые примеры симметричных пространств на $[0,1]$, не изоморфных симметричным пространствам на $(0,\\infty)$",
	  "from": "Самарский национальный исследовательский университет имени академика С. П. Королева, Самара"
	},
	{
	  "name": "Асхабов С.Н",
	  "body": "Нелинейные интегральные уравнения типа свертки в комплексных пространствах",
	  "from": "Чеченский государственный педагогический университет, Чеченский государственный университет, Грозный"
	},
	{
	  "name": "Асхабов Султан Нажмудинович",
	  "from": "Чеченский государственный университет",
	  "body": "Приближенные точечные симметрии одного дробно-дифференциального обобщения уравнения нелинейной фильтрации"
	},
	{
	  "name": "Ахметзянова Л.У., Кирьянова О.Ю., Губайдуллин И.М. ",
	  "body": "Компьютерное моделирование петлевой изотермической амплификации",
	  "from": " УФИЦ ИНК РАН , Уфа"
	},
	{
	  "name": "Ахметзянова Лиана Ульфатовна",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН",
	  "body": "Компьютерное моделирование петлевой изотермической амплификации"
	},
	{
	  "name": "Ахметшина А.Д., Губайдуллин М.Б., Латыпов Д.Г.",
	  "body": "О нелинейных колебаниях",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Ахметшина Азалия Давлетгареевна",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "КВАЗИКЛАССИЧЕСКАЯ АСИМПТОТИКА СОБСТВЕННЫХ ЧИСЕЛ ОПЕРАТОРА ШТУРМА–ЛИУВИЛЛЯ С КОМПЛЕКСНЫМ КУСОЧНО-ГОЛОМОРФНЫМ ПОТЕНЦИАЛОМ"
	},
	{
	  "name": "Байзаев С., Джумаев Б.М. ",
	  "body": "Об общем решении переопределенной системы двух комплексных уравнений с частными производными",
	  "from": "ТГУ, Худжанд"
	},
	{
	  "name": "Баширова Анар Набиевна",
	  "body": "Мультипликаторы кратных рядов Фурье-Хаара функций из анизотропных пространств Лоренца",
	  "from": "Евразийский национальный университет имени Л.Н.Гумилева, Нур-Султан, Казахстан"
	},
	{
	  "name": "Бегматов А.Х.",
	  "body": "Задачи интегральной геометрии и интегральные уравнения первого рода",
	  "from": "Самаркандский государственный университет, Самарканд"
	},	
	{
	  "name": "Бегматов А.Х., Исмоилов А., Шарипова М. ",
	  "body": "Integral geometry problem for a family of rays in three-dimensional space",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Бегматов А.Х., Усманов А.В. ",
	  "body": "The problem of reconstructing a function in a strip via integrals from curves with a singularity",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Бегматов Акрам Хасанович",
	  "from": "Самаркандский государственный университет, Самарканд",
	  "body": "INTEGRAL GEOMETRY PROBLEM FOR A FAMILY OF RAYS IN THREE-DIMENSIONAL SPACE"
	},
	{
	  "name": "Бекмаганбетов К.А., Толеугазы Е.",
	  "body": "Об аналогах теоремы Конюшкова-Стечкина в анизотропных пространствах Лоренца ",
	  "from": "Казахстанский филиал МГУ имени М.В. Ломоносова, Нур-Султан"
	},
	{
	  "name": "Белова А.С.,  ",
	  "body": "Исследование локальных бифуркаций гамильтоновых  автономных динамических системах",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Бикбова Г.Р., Губайдуллин И.М., Маннанова Г.И. ",
	  "body": "Шестнадцатикомпонентная кинетическая модель процесса каталитического крекинга",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Бикчентаев А.М., Алхасан Х.И. ",
	  "body": "Коммутаторы и изоклинные проекторы в гильбертовом пространстве",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Близнюк К.А., Мазепа Е. А.",
	  "body": "Разрешимость краевых задач для стационарного уравнения Шредингера в классе $\\varphi$ -- эквивалентных функций на некомпактных римановых многообразиях",
	  "from": "Волгоградский государственный университет, Волгоград"
	},
	{
	  "name": "Бобренёва Ю.О., Губайдуллин И.М. ",
	  "body": "Разностная схема для решения задачи массопереноса в системе сеть ''трещин - матрица''",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Болотнова Р.Х., Гайнуллина Э.Ф. Коробчинская В.А., Файзуллина Э.А.",
	  "body": "Моделирование пространственных динамических процессов в водных пенах и вскипающих струях",
	  "from": "ИМех УФИЦ РАН, Уфа"
	},
	{
	  "name": "Бондаренко Н.П., Ефремова Л.С. ",
	  "body": "Обратная задача Штурма-Лиувилля с аналитическими функциями в краевом условии",
	  "from": "Самарский университет, Саратовский государственный университет, Самара"
	},	
	{
	  "name": "Борисов Д.И. ",
	  "body": "О возникновении множественных резонансов в задачах с разбегающимися возмущениями ",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Бравый Е.И. ",
	  "body": "Об условиях разрешимости периодической краевой задачи для гибридной системы  двух линейных  функционально-дифференциальных уравнений с поточечными ограничениями на функциональны операторы",
	  "from": "ПНИПУ, Пермь"
	},
	{
	  "name": "Брайчев Георгий Генрихович",
	  "body": "О взаимном росте нулей и убывании тейлоровских коэффициентов целых функций",
	  "from": "Московский педагогический государственный университет. Москва"
	},
	{
	  "name": "Брюно А.Д.",
	  "body": " Об устойчивостях в системах Гамильтона",
	  "from": "Институт прикладной математики им. М.В.Келдыша РАН, Москва"
	},	
	{
	  "name": "Брюно А.Д., Батхин А.Б. ",
	  "body": "Алгоритмы степенной геометрии для решения алгебраического уравнения ",
	  "from": "Институт прикладной математики им. М.В.Келдыша РАН, Москва"
	},
	{
	  "name": "Бутерин С.А.",
	  "body": "Обратная спектральная задача для глобально нелокальных операторов с замороженным аргументом на геометрических графах",
	  "from": "Саратовский государственный университет, Саратов"
	},
	{
	  "name": "Валеев Н.Ф., Назирова Э.А. ",
	  "body": "Обратные спектральные задачи для малых колебаний упругой пластины с дополнительными  точечными креплениями",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Валеев Нурмухамет Фуатович",
	  "from": "ИМВЦ УФИЦ РАН, Уфа",
	  "body": "ПРЕДСТАВЛЕНИЕ ПРОЦЕССА КАТАЛИТИЧЕСКОГО  РИФОРМИНГА В ВИДЕ ГРАФОВ"
	},
	{
	  "name": "Веденин А.В.",
	  "body": "Быстро сходящиеся черновские аппроксимации к решению параболического дифференциального уравнения на вещественной прямой",
	  "from": "НИУ ВШЭ , Нижний Новгород"
	},	
	{
	  "name": "Вильданова В.Ф. ",
	  "body": "Существование и единственность решения задачи Коши для уравнения агрегации в гиперболическом пространстве",
	  "from": "Башкирский государственный педагогический университет ,Уфа"
	},
	{
	  "name": "Вильданова В.Ф., Мукминов Ф.Х. ",
	  "body": "Возмущение нелинейного эллиптического оператора потенциалами из пространства мультипликаторов",
	  "from": " БГПУ имени М. Акмуллы, Уфа"
	},	
	{
	  "name": "Власов В.В. ",
	  "body": "Спектральный анализ и представление решений вольтерровых интегро-дифференциальных уравнений, возникающих в теории вязкоупругости ",
	  "from": "МГУ им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Вовденко А.Г., Вовденко М.К., Коледина К.Ф., Губайдуллин И.М.",
	  "body": "Моделирование химической реакции получения бензилбутилового эфира",
	  "from": " УФИЦ ИНК РАН , Уфа"
	},
	{
	  "name": "Воронова Ю.Г., Жибер А.В. ",
	  "body": "Классификация нелинейных гиперболических уравнений специального класса с интегралами второго и третьего порядка",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Воронова Юлия Геннадьевна",
	  "body": "Классификация нелинейных гиперболических уравнений специального класса с интегралами второго и третьего порядка",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Габдрахманова Л.Р., Еникеева Л.В. ",
	  "body": "Обзор Алгоритма Гармонического поиска, история создания алгоритма, схема его работы, примеры применения",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Габдрахманова Ляйсан Рамиловна",
	  "from": "Уфимский Государственный Нефтяной Технический Университет",
	  "body": "Обзор Алгоритма Гармонического поиска, история создания алгоритма, схема его работы, примеры применения"
	},
	{
	  "name": "Гаджимурадов Т.А., Агаларов А.M., Балякин А.А., Жулего В.Г. ",
	  "body": "Бегущие волны в SIR модели с нелинейным уровнем заболеваемости и пространственной диффузией",
	  "from": "Институт физики ДФИЦ РАН, Махачкала"
	},
	{
	  "name": "Гаджимурадов Тельман Алимагомедович",
	  "from": "Институт физики ДФИЦ РАН",
	  "body": "Бегущие волны в SIR модели с нелинейным уровнем заболеваемости и пространственной диффузией."
	},
	{
	  "name": "Гадоев М.Г., Исхоков Д.С.",
	  "body": "Оценка резольвенты и асимптотика спектра одного класса эллиптических операторов с несогласованным вырождением ",
	  "from": "Политехнический институт (филиал) Северо-Восточного федерального университета им. М.К.Аммосова, Мирный"
	},
	{
	  "name": "Гайдамак О.Г. , Коробчинская О.Г. , Луценко В.И. , Силова Е.В. ",
	  "body": "Полином как определитель матричного оператора",
	  "from": "БашГУ, Уфа"
	},
	{
	  "name": "Гайнуллина Элина Фанилевна",
	  "body": "Моделирование пространственных динамических процессов в водных пенах и вскипающих струях",
	  "from": "Институт механики им. Р.Р. Мавлютова УФИЦ РАН"
	},
	{
	  "name": "Гайсин А.М.",
	  "body": "Теоремы типа Левинсона-Шёберга-Волфа и двойственные задачи",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},	
	{
	  "name": "Гайсин Р.А.",
	  "body": "Построение регулярной минорантной последовательности",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},	
	{
	  "name": "Гайсина Г.А. ",
	  "body": "Вычисление порядка при условии согласованности показателей и коэффициентов рядаДирихле ",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Галимова Гульнара Робертовна",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "СПЕКТРАЛЬНЫЕ СВОЙСТВА ОПЕРАТОРА ШТУРМА–ЛИУВИЛЛЯ НА КРИВОЙ С ОГРАНИЧЕННЫМ НАКЛОНОМ"
	},
	{
	  "name": "Галин А.Ш.,  Еникеев М.Р. ",
	  "body": "Распознавание сфер мезофазы игольчатого кокса на изображении с помощью алгоритмов машинного обучения",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Галин Айдар Шамилевич",
	  "from": "Уфимский государственный нефтяной технический университет",
	  "body": "Распознавание сфер мезофазы игольчатого кокса на изображении с помощью алгоритмов машинного обучения"
	},
	{
	  "name": "Гималтдинова А.А.,  ",
	  "body": "Первая краевая задача для трехмерного уравнения смешанного типа с двумя плоскостями изменения типа",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Гималтдинова Альфира Авкалевна",
	  "from": "Уфимский государственный нефтяной технический университет",
	  "body": "Первая краевая задача для трехмерного уравнения смешанного типа с двумя плоскостями изменения типа"
	},
	{
	  "name": "Губайдуллин Ирек Марсович",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН, Уфа",
	  "body": "Параметрический анализ устойчивости границы парето оптимальных условий проведения каталитических процессов"
	},
	{
	  "name": "Давыдова Э.В.",
	  "body": "О спектральных свойствах прямолинейных точек либрации  ограниченной задачи трех тел"
	},
	{
	  "name": "Джабраилова А.Н. ",
	  "body": "O кратной полноте системы собственных и присоединенных элементов операторного пучка в гильбертовом пространстве",
	  "from": "Институт Математики и Механики АН Азербайджана, Баку"
	},
	{
	  "name": "Джумабаева А.А., ЖетписбаеваА.Е. ",
	  "body": "О порядках приближения функций многих переменных ",
	  "from": "Евразийского национального университета имени Л.Н.Гумилева, Нур-Султан"
	},
	{
	  "name": "Дубовцев Д.А., Еникеева Л.В., Губайдуллин И.М.",
	  "body": "Оптимизация процесса синтеза метил-трет-бутилового эфира путем разработки математической модели",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН, Уфа"
	},
	{
	  "name": "Дубовцев Дмитрий Александрович",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН, Уфа",
	  "body": "ОПТИМИЗАЦИЯ ПРОЦЕССА СИНТЕЗА МЕТИЛ-ТРЕТ-БУТИЛОВОГО ЭФИРА ПУТЕМ РАЗРАБОТКИ МАТЕМАТИЧЕСКОЙ МОДЕЛИ"
	},
	{
	  "name": "Екомасов Евгений Григорьевич",
	  "from": "ИФМК УФИЦ РАН, Уфа",
	  "body": "Применение модифицированного уравнения синус-Гордона для описания динамики локализованных магнитных неоднородностей в мультислойных структурах"
	},
	{
	  "name": "Еникеева Л.В.  Губайдуллин И.М.",
	  "body": "Эвристические методы при построении кинетических моделей химических процессов",
	  "from": "НГУ, Новосибирск"
	},
	{
	  "name": "Еникеева Лениза Васимовна",
	  "from": "Новосибирский государственный университет",
	  "body": "Эвристические методы при построении кинетических моделей химических процессов"
	},
	{
	  "name": "Ефремова Л.С. ",
	  "body": "О сохранении свойства интегрируемости при малых возмущениях косых произведений отображений интервала ",
	  "from": "Нижегородский государственный университет, Нижний Новгород"
	},
	{
	  "name": "Жибер Анатолий Васильевич",
	  "body": "Классификация нелинейных гиперболических уравнений специального класса с интегралами второго и третьего порядка",
	  "from": "Институт математики с ВЦ УФИЦ РАН"
	},
	{
	  "name": "Загидуллин Ш.Г., Коледина К.Ф., Губайдуллин И.М.",
	  "body": "Математическое моделирование процесса гидрирования полициклических ароматических углеводородов",
	  "from": " ПАО АНК \"Башнефть\" \"Башнефть-Новойл\", Уфа"
	},
	{
	  "name": "Зайцева Е.С., Губайдуллин И., Губайдуллин И.М.",
	  "body": "Разработка математического описания расчета октанового числа бензинов каталитического риформинга с групповой кинетикой",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Зайцева Наталья Владимировна",
	  "body": "О гладких классических решениях гиперболических дифференциально-разностных уравнений с n сдвигами",
	  "from": "Московский государственный университет им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Зарипова Элиза Альбертовна",
	  "body": "Восстановление каротажных кривых методами машинного обучения",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Зарянов А.А. ",
	  "body": "О построении аналитических решений уравнений реакция-диффузия с двумерным Винеровским процессом",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Зарянов Артем Александрович",
	  "from": "Уфимский Государственный Авиационный Университет",
	  "body": "Нелинейные локализованные волны уравнения синус-Гордона в модели с примесями"
	},
	{
	  "name": "Зикиров О.С., Сагдуллаева М.М., Еникеев М.Р.",
	  "body": "Об одной задаче с интегральным условием для нагруженного уравнения теплопроводности",
	  "from": "Национальный университет Узбекистана имени Мирзо Улугбека, Ташкент"
	},
	{
	  "name": "Зикиров Обиджан Салижанович",
	  "body": "Об одной нелокальной задаче с интегральным условием для уравнения составного типа",
	  "from": "Национальный университет Узбекистана им. Мирзо Улугбека, Ташкент"
	},
	{
	  "name": "Илолов М.И., Козоброд В.Н.,  Лашкарбеков С.М. ",
	  "body": "Об устойчивости стационарных решений стохастических дифференциальных уравнений с последствием",
	  "from": "Центр инновационного развития науки и новых технологий АН  Респ. Таджикистан, Душанбе"
	},
	{
	  "name": "Илолов М.И.1, Гулджонов Д.Н.2, Рахматов Дж.Ш.1",
	  "body": "Полулинейные интегро- дифференциальные уравнения дробного порядка в банаховом пространстве",
	  "from": "Центр инновационного развития науки и новых технологий АНРесп. Таджикистан, Душанбе1, Институт математики им. А. Джураева АН Таджикистан2"
	},
	{
	  "name": "Исаев К.П., Юлмухаметов Р.",
	  "body": "Геометрия  радиальных гильбертовых пространств, допускающих   безусловные базисы из воспроизводящих ядер",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Исаева Мадина Мусаевна",
	  "from": "Институт физики им. Х.И.Амирханова ДФИЦ РАН, Махачкала",
	  "body": "О построении аналитических решений уравнений реакция-диффузия с двумерным Винеровским процессом"
	},
	{
	  "name": "Исмаилов Алишер Сиддикович",
	  "from": "Самаркандский государственный университет, Самарканд",
	  "body": "INTEGRAL GEOMETRY PROBLEM FOR A FAMILY OF RAYS IN THREE-DIMENSIONAL SPACE"
	},
	{
	  "name": "Исхакова Р.В. ",
	  "body": "Построение инвариантно-разностной схемы для уравнения Эмдена-Фаулера",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Исхакова Розалина Валеевна",
	  "from": "Уфимский государственный авиационный технический университет, Уфа",
	  "body": "Аналитическая классификация ростков типичных полугиперболических отображений"
	},
	{
	  "name": "Ишкин Х.К., Ахметшина А.Д.",
	  "body": "Квазиклассическая асимптотика собственных чисел оператора Штурма--Лиувилля с комплексным кусочно-голоморфным потенциалом",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Ишкин Х.К., Галимова Г.",
	  "body": "Спектральные свойства  оператора Штурма--Лиувилля на кривой с ограниченным наклоном",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Ишкин Х.К., Набиулина А.А.",
	  "body": "Асимптотика спектра оператора  Штурма--Лиувилля с мероморфным потенциалом в случае нетривиальной монодромии",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Ишкин Х.К., Нуртдинов Р.Ф., Амангильдин Т.Г., Латыпов Д.Г.",
	  "body": "Асимптотика спектра  дифференциального оператора IV порядка со многими точками поворота",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Ишкин Хабир Кабирович",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "СПЕКТРАЛЬНЫЕ СВОЙСТВА ОПЕРАТОРА ШТУРМА–ЛИУВИЛЛЯ НА КРИВОЙ С ОГРАНИЧЕННЫМ НАКЛОНОМ"
	},
	{
	  "name": "Кабанко Михаил Владимирович",
	  "body": "АНАЛИТИЧЕСКИЕ ФУНКЦИИ БЕСКОНЕЧНОГО ПОРЯДКА В ПОЛУПЛОСКОСТИ С РАДИАЛЬНО РАСПРЕДЕЛЕННЫМИ НУЛЯМИ",
	  "from": "Курский государственный университет, Курск"
	},
	{
	  "name": "Калимбетов Б.Т., Хабибуллаев Ж.О.",
	  "body": "Асимптотическое решение сингулярно возмущенного интегро-дифференциального уравнения с быстро осциллирующей неоднородностью",
	  "from": " Университет Ахмеда Ясави, Туркестан"
	},
	{
	  "name": "Калимбетов Б.Т.. Темирбеков М.А.",
	  "body": "Регуляризованная асимптотика решений сингулярно возмущенного уравнения с частными производными с быстро осциллирующими коэффициентами",
	  "from": " Университет Ахмеда Ясави, Туркестан"
	},	
	{
	  "name": "Каменский М.И., Макаренков О.Ю. ",
	  "body": "О периодических решениях уравнений со sweeping процессами ",
	  "from": "Воронежский государственный университет, Воронеж"
	},
	{
	  "name": "Кангужин Б.Е. ",
	  "body": "Восстановление граничных условий дифференциальных операторов по набору спектров ",
	  "from": "КазахскийНациональный университет им.аль-Фараби, Алматы"
	},
	{
	  "name": "Канкенова А.М. ",
	  "body": "Неравенства типа Харди-Литтлвуда ",
	  "from": "Евразийский национальный университет имени Л.Н. Гумилева, Нур-Султан"
	},
	{
	  "name": "Карамова Э.Р., Еникеев М.Р. ",
	  "body": "Расчет точности прогнозирования операций в технологическом ремонтном процессе нефтяных скважин с использованием рекуррентной нейронной сети",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Кароматулои А.",
	  "body": "Приложение дифференциальные уравнения к некоторым задач экологии",
	  "from": "ТГФЭУ, Душанбе"
	},
	{
	  "name": "Кашникова А.П.",
	  "body": "Существование решений квазилинейных эллиптических уравнений в пространствах Музилака-Орлича-Соболева для неограниченных областей",
	  "from": "Башкирский государственный университет, Стерлитамак"
	},
	{
	  "name": "Каюмов И.Р., Маклаков Д.В.",
	  "body": " Об одной нелинейной вариационной проблеме теории кавитирующих профилей ",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Каюмов И.Р., Хамматова Д.М., Поннусами С. ",
	  "body": "Неравенства типа Бора и их обобщения",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Каюмов Р.А., Тазюков Б.Ф.",
	  "body": "Методика определения коэффициента постели в задаче потери устойчивости среднего слоя трехслойного стержня при растяжении",
	  "from": "КазГАСУ, Казань"
	},
	{
	  "name": "Кирьянова О.Ю., Губайдуллин И.М. ",
	  "body": "равнение генетических штрих-кодов родственных сортов растений",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Кирьянова Ольга юр",
	  "from": "Уфимский государственный нефтяной технический университет",
	  "body": "Сравнение генетических штрих-кодов родственных сортов растений"
	},
	{
	  "name": "Клевцова Ю.Ю.",
	  "body": "On the inviscid limit of stationary measures  for the stochastic system of the quasi-solenoidal Lorenz model for a baroclinic atmosphere",
	  "from": "Федеральное государственное бюджетное образовательное учреждение высшего образования Сибирский государственный университет телекоммуникаций и информатики, Новосибирск,"
	},
	{
	  "name": "Клячин А.А.",
	  "body": "О $C^1$-сходимости кусочно-полиномиальных решений вариационного уравнения 4-го порядка",
	  "from": "Волгоградский государственный университет, Волгоград"
	},
	{
	  "name": "Кожевникова Л.М.",
	  "body": "Существование энтропийных решений квазилинейных эллиптических уравнений в пространствах Музилака-Орлича-Соболева",
	  "from": "Стерлитамакский филиал Башкирского государственного университета, Стерлитамак"
	},
	{
	  "name": "Кожевникова Лариса Михайловна",
	  "from": "Стерлитамакский филиал Башкирского государственного университета, Стерлитамак",
	  "body": "Существование энтропийных решений квазилинейных эллиптических уравнений в пространствах Музилака-Орлича-Соболева"
	},
	{
	  "name": "Коледина К.Ф., Губайдуллин И.М., Коледин С.Н. ",
	  "body": "Параметрический анализ устойчивости границы Парето оптимальных условий проведения каталитических процессов",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Коледина Камила Феликсовна",
	  "from": "Уфа",
	  "body": "ПЛОТНОСТЬ СОСТОЯНИЙ И СТРУКТУРА ОСНОВНОГО СОСТОЯНИЯ МОДЕЛИ МАГНИТНОГО ДЕНДРИМЕРА."
	},
	{
	  "name": "Колесников И.А.",
	  "body": "Однопараметрический метод определения параметров в интеграле Кристоффеля-Шварца",
	  "from": "НИ ТГУ, Томск"
	},
	{
	  "name": "Колесников Иван Александрович",
	  "from": "Томский государственный университет, Томск",
	  "body": "Однопараметрический метод определения параметров в интеграле Кристоффеля-Шварца"
	},
	{
	  "name": "Конечная Н.Н. ",
	  "body": "Главный член асимптотики решений  дифференциальных  уравнений четвертого порядка с негладкими коэффициентами",
	  "from": "САФУ имени М.В. Ломоносова, Архангельск"
	},
	{
	  "name": "Копежанова А.Н.",
	  "body": " Суммируемость преобразования Фурье функции из пространства Лоренца ",
	  "from": "Евразийский национальный университет имени Л.Н. Гумилева, Нур-Султан"
	},
	{
	  "name": "Коротяев Е.Л. ",
	  "body": "Traceformulae for Schrodinger operators ",
	  "from": "Санкт-Петербургский государственный университет, Санкт-Петербург"
	},
	{
	  "name": "Красносельский А.М.",
	  "body": " Бифуркации Андронова-Хопфа, определяемые нелинейностью с запаздыванием",
	  "from": "Институт проблем передачи информации имени А. А. Харкевича РАН, Москва"
	},
	{
	  "name": "Кужаев А.Ф., Рафиков А., Рафиков А.И.",
	  "body": "Об одной оценке на индикатор целой функции экспоненциального типа",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Кузнецова М.Н., Сакиева А.У., Хабибуллин И.Т.",
	  "body": "Условия интегрируемости для уравнений типа двумеризованной цепочки Тоды",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Кузнецова Мария Андреевна",
	  "from": "Саратовский государственный университет, Саратов",
	  "body": "Sturm--Liouville differential operators on time scales and properties of their spectral characteristics"
	},
	{
	  "name": "Кузнецова Мария Николаевна",
	  "from": "Институт математики с ВЦ УФИЦ РАН, Уфа",
	  "body": "Условия интегрируемости для уравнений типа двумеризованной цепочки Тоды"
	},
	{
	  "name": "Кучкарова А.Н.",
	  "body": "Задача Трикоми- Неймана для вырождающегося уравнения смешанного типа",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Кушербаева У.Р.",
	  "body": " Об одном уравнении Карлемана-Векуа с полюсом ",
	  "from": "КазахскийНациональный университет им.аль-Фараби, Алматы"
	},
	{
	  "name": "Кушербаева У.Р. ",
	  "body": "Начально-краевая задача для уравнения Карлемана-Векуа с полярной особенностью",
	  "from": "Казахский  Национальный университет им.  аль-Фараби, Алматы"
	},
	{
	  "name": "Лакаев С.Н.",
	  "body": " Пороговые эффекты в системе двух бозонов на оптической решетке ",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Латыпов Дамир Гакилович",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "АСИМПТОТИКА СПЕКТРА ДИФФЕРЕНЦИАЛЬНОГО ОПЕРАТОРА IV ПОРЯДКА СО МНОГИМИ ТОЧКАМИ ПОВОРОТА"
	},
	{
	  "name": "Литвинов В.Л.",
	  "body": "Продольные колебания каната с движущимися границами",
	  "from": "МГУ им.М.В. Ломоносова, Москва"
	},
	{
	  "name": "Лобода А.В.",
	  "body": "О вырожденности орбит разложимых алгебр Ли",
	  "from": "ВГТУ, Воронеж"
	},
	{
	  "name": "Лобода А.В.,  Каверина В.К.",
	  "body": "Об орбитах 7-мерных алгебр Ли, содержащих три абелевых 4-мерных идеала",
	  "from": "ВГТУ, Воронеж"
	},
	{
	  "name": "Лобода Александр Васильевич",
	  "from": "Воронежский государственный технический университет",
	  "body": "Оценка факторов, влияющих на границы прогноза, в методе локальной аппроксимации нерегулярных временных рядов"
	},
	{
	  "name": "Лукащук В.О., Тимирбаева Э.И.",
	  "body": "Приближенные точечные симметрии одного дробно-дифференциального обобщения уравнения нелинейной фильтрации",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Мазитов А.А., Губайдуллин И.М.",
	  "body": "Математическое моделирование нестационарного течения многофазного потока в пористой среде",
	  "from": " ИНК УФИЦ РАН, Уфа"
	},
	{
	  "name": "Мазитов Айнур Асгатович",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН, Уфа",
	  "body": "Математическое моделирование нестационарного течения многофазного потока в пористой среде"
	},
	{
	  "name": "Макин Александр Сергеевич",
	  "body": "Характеристика спектров периодической и антипериодической краевых задач, порожденных несамосопряженным оператором Дирака",
	  "from": "Российский технологический университет (МИРЭА), Москва"
	},
	{
	  "name": "МаксимовВ.П.",
	  "body": " Динамические модели с непрерывным и дискретным временем ",
	  "from": "Пермский государственный национальный исследовательский университет, Пермь"
	},
	{
	  "name": "Малютин К.Г., Кабанко М.В.",
	  "body": " Регулярные множества в пространствах целых и аналитических в полуплоскости функций",
	  "from": "Курский государственный университет, Курск"
	},
	{
	  "name": "Малютин К.Г., Кабанко М.В. ",
	  "body": "Аналитические функции бесконечного порядка в полуплоскости с радиально распределенными нулями",
	  "from": "Курский государственный университет, Курск"
	},
	{
	  "name": "Малютин К.Г., Малютина Т.И., Ревенко А.А. ",
	  "body": "Экстремальные задачи в теории Вимана-Валирона",
	  "from": "Курский государственный университет, Курск"
	},
	{
	  "name": "Малютин Константин Геннадьевич",
	  "body": "Аналитические функции бесконечного порядка в полуплоскости с радиально распределенными нулями",
	  "from": "Курский государственный университет, Курск"
	},
	{
	  "name": "Марванов Р.И.",
	  "body": "Несекториальный оператор Штурма--Лиувилля с дискретным спектром",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Марванов Рустем Ильдарович",
	  "from": "Башкирский государственный университет",
	  "body": "Несекториальный оператор Штурма - Лиувилля с дискретным спектром"
	},
	{
	  "name": "Марков П.И., Маякова С.А. ",
	  "body": "Оценка факторов, влияющих на границы прогноза, в методе локальной аппроксимации нерегулярных временных рядов",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Марков Павел Ильич",
	  "from": "Уфимский государственный авиационный технический университет, Уфа",
	  "body": "Обратные спектральные задачи для малых колебаний упругой пластины с дополнительными  точечными креплениями."
	},
	{
	  "name": "Марковский А.Н.",
	  "body": "Представление полигармонических функций",
	  "from": "КубГУ , Краснодар"
	},
	{
	  "name": "Масягин В.Ф., Жалнин Р.В., Тишкин В.Ф.",
	  "body": "Применение энтропийного лимитера для решения трехмерных уравнений газовой динамики с использованием неявной схемы метода Галеркина с разрывными базисными функциями",
	  "from": " Национальный исследовательский Мордовский государственный университет, Саранск"
	},
	{
	  "name": "Махмутова Д., Еникеева Л.В",
	  "body": "Метод роя частиц",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Мелихов С.Н.",
	  "body": " Об алгебрах голоморфных функций и аналитических функционалов ",
	  "from": "Южный федеральный университет, Ростов-на-Дону"
	},
	{
	  "name": "Меньшикова Э.Б.",
	  "body": "Ограничения на распределение меры Рисса субгармонической функции в области",
	  "from": "БашГУ, Уфа"
	},
	{
	  "name": "Меньшикова Энже Булатовна",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "Ограничения на распределение меры Рисса субгармонической функции в области"
	},
	{
	  "name": "Мирзаев Олим Эркинович",
	  "body": "ИЗОСПЕКТРАЛЬНЫЕ ОПЕРАТОРЫ ШТУРМА-ЛИУВИЛЛЯ НА КОНЕЧНОМ ОТРЕЗКЕ",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Мирзоев К.А.",
	  "body": "Интегральное представление сумм некоторых рядов, связанных со специальными функциями",
	  "from": "МГУ им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Мирзоев К.А. ",
	  "body": "Интегральное представление сумм некоторых рядов, связанных со специальными функциями ",
	  "from": "МГУ им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Мирзоев К.А., Сафонова Т.А.",
	  "body": "Вокруг теоремы Гаусса о значениях дигамма- функции Эйлера в рациональных точках",
	  "from": "МГУ им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Мирзорахимов М.Х.",
	  "body": "Решение одной несамосопряженной смешанной задачи для однородного волнового уравнения в многомерном пространстве",
	  "from": "Академия государственного управления при Президенте Республики Таджикистан, "
	},
	{
	  "name": "Михайлов В.А., Аблаев Ф.М., Михайлова Т.А.",
	  "body": "Экспериментально-сравнительный анализ алгоритмов поиска подстроки в тексте",
	  "from": " КФУ, Казань"
	},
	{
	  "name": "Михайлов Владимир Анатольевич",
	  "from": "Казанский федеральный университет, Казань",
	  "body": "Экспериментально-сравнительный анализ алгоритмов поиска подстроки в тексте"
	},
	{
	  "name": "Мокеев А.С.",
	  "body": " О некоммутативных операторных графах, порождаемых положительными операторозначными мерами ",
	  "from": "Санкт-Петербургский государственный университет, Санкт-Петербург"
	},
	{
	  "name": "Мугалимова Р.С., Мугалимова Р.С., Коледина К.Ф.",
	  "body": "Математическое моделирование гетерогенной экзотермической реакции A $\\longrightarrow$ B на зерне и в слое катализатора",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН , Уфа"
	},
	{
	  "name": "Музафаров С.М.",
	  "body": "Моделирование импульсно-частотных характеристик и колебательных процессов в системах  со сложными запаздываниями",
	  "from": "Башкирский государственный университет, Сибай"
	},
	{
	  "name": "Муканов А.Б.",
	  "body": " Ободном критерии Лоренца ",
	  "from": "Казахстанский филиал МГУ имени М.В. Ломоносова, Нур-Султан"
	},
	{
	  "name": "Мукминов Т.Ф., Хабиров С.В.",
	  "body": "О простых волнах конических движений",
	  "from": " УГАТУ, Уфа"
	},
	{
	  "name": "Мукминов Тимур Флюрович",
	  "body": "О простых волнах конических движений",
	  "from": "Уфимский государственный авиационный технический университет, Уфа"
	},
	{
	  "name": "Мукминов Ф.Х.",
	  "body": " Возмущение нелинейного эллиптического оператора сингулярным потенциалом ",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Муллаянова А.Ф., Губайдуллин И.М. ",
	  "body": "Оценка прочности трубопроводов, имеющих коррозионные дефекты",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Муравник А.Б.",
	  "body": "Задачи в цилиндрических областях  для некоторых сингулярных квазилинейных эллиптических и параболических уравнений",
	  "from": " АО Концерн Созвездие, Воронеж; Российский университет дружбы народов, Москва, Воронеж"
	},
	{
	  "name": "Мусабаева Г.К.",
	  "body": " Теорема типа Харди-Литтлвуда для анизотропного пространства Лоренца ",
	  "from": "Евразийский национальный университет имени Л.Н. Гумилева, Нур-Султан"
	},
	{
	  "name": "Мусин И.Х. ",
	  "body": "О некоторых классах бесконечно дифференцируемых функций, допускающих продолжение до целых функций ",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Мустафина И.Ж.",
	  "body": "О приближенном построении границы области устойчивости  нелинейных периодических  систем",
	  "from": "УКГП, Учалы"
	},
	{
	  "name": "Мухамадиев Э.М., Назимов А.Б.",
	  "body": "Об однозначной разрешимости одной системы интегральных уравнений",
	  "from": "Вологодский государственный университет, Вологда"
	},
	{
	  "name": "Мухамадиев Э.М., Наимов А.Н. ",
	  "body": "Об ограниченных траекториях трехмерных автономных систем с главной градиентной частью",
	  "from": "Вологодский государственный университет, Вологда"
	},
	{
	  "name": "Мухаметрахимова А.И.",
	  "body": " О равномерной резольвентной сходимости в задачах с мелкой перфорацией вдоль заданного многообразия: случай усреднённого краевого условия Дирихле ",
	  "from": "Башкирский государственный педагогический университет, Уфа"
	},
	{
	  "name": "Мухтаров Я.., Буриев Т.Э. ",
	  "body": "Исследование устойчивости нулевого  решения  системы дифференциальных уравнений",
	  "from": " СамГУ, Самарканд"
	},
	{
	  "name": "Набиулина Алина Аликовна",
	  "from": "Башкирский Государственный университет, Уфа",
	  "body": "Асимптотика спектра оператора Штурма–Лиувилля с мероморфным потенциалом в случае нетривиальной монодромии"
	},
	{
	  "name": "Навроцкая Е.В., Смирнов Д.А.,  Безручко Б.П. ",
	  "body": "Разработка метода восстановления структуры связей в системе из трех осцилляторов с помощью моделирования  фазовой динамики",
	  "from": "СГУ имени Н.Г. Чернышевского, СФ ИРЭ РАН , Саратов"
	},
	{
	  "name": "Назаров В.Н.,  Харисов А.Т., Самсонов К.Ю.",
	  "body": "{Применение модифицированного уравнения синус-Гордона для описания динамики локализованных магнитных неоднородностей в мультислойных структурах",
	  "from": "ИФМК УФИЦ РАН, Уфа"
	},
	{
	  "name": "Назирова Э.А., Сагитова А.Р. Я.Т.Султанаев",
	  "body": "Об асимптотическом разложении решений системы дифференциальных уравнений 1-го порядка  с сильно-осциллирующим коэффициентом",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Наимов Алижон Набиджанович",
	  "body": "Об ограниченных траекториях трехмерных автономных систем с главной градиентной частью",
	  "from": "Вологодский государственный университет, Вологда"
	},
	{
	  "name": "Напалков В.В.",
	  "body": " О совпадении гильбертовых пространств с воспроизводящими ядрами, связанных специальным преобразованием ",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Насыров С.Р.",
	  "body": "Однопараметрические семейства эллиптических функций с несколькими полюсами",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Насыров С.Р. ",
	  "body": "Однопараметрические семейства эллиптических функций с несколькими полюсами ",
	  "from": "Казанский (Приволжский) федеральный университет, Казань"
	},
	{
	  "name": "Насыров Ф.С. ",
	  "body": "О стохастическом  и потраекторно-детерминированном принципах максимума",
	  "from": "УГАТУ, Уфа"
	},
	{
	  "name": "Негмонов С.Х.",
	  "body": " Представление  решений одного класса  системы дифференциальных уравнений в комплексной плоскости",
	  "from": "Таджикский государственный финансово-экономический университет, Душанбе"
	},
	{
	  "name": "Николаева Надежда Геннадьевна",
	  "from": "Южно-Уральский государственный университет",
	  "body": "Parameter estimation of chaotic maps using particle swarm optimization"
	},
	{
	  "name": "Никонорова Р.Ф.",
	  "body": "Простые решения уравнений динамики одноатомного газа",
	  "from": "ИМех УФИЦ РАН, Уфа"
	},
	{
	  "name": "Новокшенов В.Ю. ",
	  "body": "Нули семейств полиномов, ассоциированных с уравнениями Пенлеве ",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Нурахметов Д.Б. Джумабаев С.А.",
	  "body": " О спектре краевой задачи Эйлера-Бернулли ",
	  "from": "Институт математики и математического моделирования, Алматы"
	},
	{
	  "name": "Нуров И.Д. Ахмедов Д.Т. ",
	  "body": "К теории периодических и ограниченных решений нелинейных систем дифференциальных уравнений второго порядка ",
	  "from": "НИИ Таджикского национального университета, Душанбе"
	},
	{
	  "name": "Нуров И.Д. Шарифзода З.И. ",
	  "body": "Анализ устойчивости стационарных решений нелинейной системы фотосинтеза",
	  "from": "НИИ Таджикского национального университета, Душанбе"
	},
	{
	  "name": "Нурсултанов Е.Д. Баширова А.Н. ",
	  "body": "Мультипликаторы кратных рядов Фурье-Хаара функций из анизотропных пространств Лоренца ",
	  "from": "Казахстанский филиал МГУ имени М.В. Ломоносова, Нур-Султан"
	},
	{
	  "name": "Нурсултанов Е.Д. Мукеева Ж.М.",
	  "body": "О некоторых интерполяционных свойствах интегрального оператора в анизотропных пространствах",
	  "from": "Казахстанский филиал МГУ имени М.В. Ломоносова, Нур-Султан"
	},

	{
	  "name": "Нуртдинов Рустам Фаритович",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "АСИМПТОТИКА СПЕКТРА ДИФФЕРЕНЦИАЛЬНОГО ОПЕРАТОРА IV ПОРЯДКА СО МНОГИМИ ТОЧКАМИ ПОВОРОТА"
	},
	{
	  "name": "Овчинников А.С., Бострем И.Г., Синицын В.Е., Екомасов  Е.Г., Кишин Дж. ",
	  "body": "Дискретные магнитные бризеры в моноаксиальном хиральном гелимагнетике",
	  "from": "Уральский федеральный университет, Екатеринбург"
	},
	{
	  "name": "Павленко В.А.",
	  "body": "О предельном переходе в некоторых гамильтоновых системах Кимуры",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Папкович М.В., Скоромник О.В.",
	  "body": "Многомерное интегральное преобразование с G--функцией Мейера в ядре в весовых просранствах суммируемых функций",
	  "from": "ПГУ , Новополоцк"
	},
	{
	  "name": "Петросян Г.Г.",
	  "body": "О разрешимости антипериодической задачи для полулинейных дифференциальных уравнений дробного порядка $1 <q <2$",
	  "from": " ВГУИТ, ВГПУ, Воронеж"
	},
	{
	  "name": "Петросян Гарик Гагикович",
	  "from": "ВГУИТ, ВГПУ (Воронеж, Россия)",
	  "body": "Математическое моделирование химических реакций: исследование влияния вариации кинетических параметров на ожидаемый состав продуктов"
	},
	{
	  "name": "Пиров Р. ",
	  "body": "Условия совместности и многообразия решений некоторых нелинейных систем четырех дифференциальных уравнений в частных производных с тремя неизвестными в $R^3$",
	  "from": "ТГПУ имени С. Айни, Душанбе"
	},
	{
	  "name": "Полынцева С.В., Сугежик А.Л. ",
	  "body": "Разрешимость коэффициентной обратной задачи для квазилинейного параболического уравнения",
	  "from": "СФУ, Красноярск"
	},
	{
	  "name": "Полынцева Светлана Владимировна",
	  "from": "Сибирский федеральный университет, Красноярск",
	  "body": "Разрешимость коэффициентной обратной задачи для квазилинейного параболического уравнения"
	},
	{
	  "name": "Привалов Л.Ю. ",
	  "body": "Моделирование процесса распространения волны в многослойной среде",
	  "from": "УГАТУ, Уфа"
	},
	{
	  "name": "Привалов Лаврентий Юрьевич",
	  "from": "Уфимский государственный авиационный технический университет, Уфа",
	  "body": "Моделирование процесса распространения волны в многослойной среде"
	},
	{
	  "name": "Пугач Н.Г.",
	  "body": "Моделирование поведения спирального слоя сверхпроводящего спинового вентиля",
	  "from": "НИИЯФ МГУ им. М.В. Ломоносова и Научно-исследовательский Университет Высшая Школа Экономики, Москва"
	},
	{
	  "name": "Расулов Т.Х.",
	  "body": "О мощности дискретного спектра одного семейства 2х2-операторных матриц",
	  "from": "Бухарский государственный университет, Бухара"
	},
	{
	  "name": "Расулов Т.Х., Дилдмуродов Э.Б.",
	  "body": "О мощности дискретного спектра одного семейства $2\\times 2$--операторных матриц",
	  "from": "БухГУ, Бухара"
	},
	{
	  "name": "Раутиан Н.А. ",
	  "body": "Полугруппы для вольтерровых интегро-дифференциальных уравнений, возникающих в теории вязкоупругости",
	  "from": "МГУ им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Рахимова А.И.",
	  "body": " Частный случай теоремы о порождающих в кольце целых функций ",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Рахимова Махсуда Аюбовна",
	  "body": "Об ограниченных на всей плоскости решениях квазилинейных переопределенных систем двух уравнений с частными производными",
	  "from": "Таджикский государственный университет права, бизнеса и политики, Таджикистан"
	},
	{
	  "name": "Ремизов И.Д. ",
	  "body": "Черновские аппроксимации решений эволюционных уравнений и операторных полугрупп",
	  "from": "МЛ ДСП НИУ ВШЭ, "
	},
	{
	  "name": "Ризванова Татьяна Ризвановна",
	  "body": "Фрустрации и упорядочение в модели Поттса с числом состояний спина  q = 4 на решетке Кагоме с учетом вторых ближайших соседей.",
	  "from": "Институт физики им. Х.И. Амирханова, ДагНЦ РАН, Махачкала"
	},
	{
	  "name": "Родикова Евгения Геннадьевна",
	  "from": "Брянский государственный университет имени акад. И.Г. Петровского",
	  "body": "О некоторых оценках в классе И.И. Привалова по площади"
	},
	{
	  "name": "Садриева Р.Т., Сидельникова Н.А.",
	  "body": "Асимптотическое поведение фундаментальной матрицы системы дифференциальных уравнений второго порядка в вырожденном случае",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Саидов Б.Б. , Шадманов М.У.",
	  "body": "Граничные задачи для одного класса  системы в полных дифференциалах с сингулярными точками",
	  "from": "Таджикский государственный финансово-экономический университет, Душанбе"
	},
	{
	  "name": "Сакбаев В.Ж. ",
	  "body": "Предельные свойства композиций независимых случайных преобразований и слабая сходимость мер ",
	  "from": "МФТИ, Математический центр мирового уровня Математический институт им. В.А. Стеклова РАН, Москва"
	},
	{
	  "name": "Сакс Р.С. ",
	  "body": "Пространства Соболева в классах потенциальных и  вихревых полей",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Салимова А.Е., Хабибуллин Б.Н.",
	  "body": "Развитие и обобщения теоремы Мальявена - Рубела о целых функциях"
	},
	{
	  "name": "Самсонов К.Ю., Гумеров ,А.М., Кудрявцев Р.В., Екомасов Е.Г.",
	  "body": "Нелинейные локализованные волны уравнения синус-Гордона в модели с примесями",
	  "from": "ТюмГУ, Тюмень"
	},
	{
	  "name": "Самсонов Кирилл Юрьевич",
	  "from": "Тюменский государственный университет, Тюмень",
	  "body": "О разрешимости антипериодической задачи для полулинейных дифференциальных уравнений дробного порядка $1 <q <2$"
	},
	{
	  "name": "Сафаров Д.С., Миратов С.К. ",
	  "body": "Решение  уравнения дуффинга с  отклоняющимся аргументом",
	  "from": "БохГУ, Бохтар"
	},
	{
	  "name": "Сафаров Д.С., Мухаммадали Д.",
	  "body": "О размерности пространства  метааналитических функций многих комплексных переменных",
	  "from": "БохГУ, Бохтар"
	},
	{
	  "name": "Сафиуллина Лиана Фануровна",
	  "body": "Математическое моделирование химических реакций: исследование влияния вариации кинетических параметров на ожидаемый состав продуктов",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Сафонова Т.А.",
	  "body": " Вычисление сумм некоторых сходящихся числовых рядов ",
	  "from": "САФУ имени М.В. Ломоносова, Архангельск"
	},
	{
	  "name": "Сергеев А.Г. ",
	  "body": "Квантовые дифференциалы в пространствах функций",
	  "from": "Математический институт им. В. А. Стеклова РАН, Москва"
	},
	{
	  "name": "Силова Е.В., Гайдамак О.Г., Коробчинская О.Г., Луценко В.И.",
	  "body": "Представление решения системы уравнений в частных производных",
	  "from": "БашГУ, Уфа"
	},
	{
	  "name": "Симонов П.М. ",
	  "body": "К вопросу об устойчивости системы линейных гибридных функционально-дифференциальных уравнений с последействием",
	  "from": "Пермский государственный национальный исследовательский университет, Пермь"
	},
	{
	  "name": "Солиев Ю.С.",
	  "body": "О квадратурных формулах с кратными узлами для гиперсингулярных интегралов Адамара по действительной оси ",
	  "from": "МАДИ, Москва"
	},
	{
	  "name": "Сташ А.Х. ",
	  "body": "Свойства частот Сергеева уравнения Хилла",
	  "from": "Кавказский математический центр, Адыгейский государственный университет, Майкоп"
	},
	{
	  "name": "Сугежик Айдана Леонидовна",
	  "from": "Сибирский федеральный университет, Красноярск",
	  "body": "Разрешимость коэффициентной обратной задачи для квазилинейного параболического уравнения"
	},
	{
	  "name": "Сулейманов Б.И. ",
	  "body": "Мероморфность решений широкого класса уравнений типа Пенлеве",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Ташпулатов С.М.",
	  "body": "Two-electron singlete in the impurity hubbard model",
	  "from": "Institute of Nuclear Physics of Academy of Sciences of Republik of Uzbekistan, Ташкент"
	},
	{
	  "name": "Тимирбаева Эвелина Ильдаровна",
	  "from": "Уфимский государственный авиационный технический университет",
	  "body": "Параметрический анализ устойчивости границы Парето оптимальных условий проведения каталитических процессов"
	},
	{
	  "name": "Туленов К.С., Бекбаев Н., Нессипбаев Е. ",
	  "body": "Boundedness of the Hilbert transformon Lorentz spaces and applications",
	  "from": "Институт математики и математического моделирования, Алматы"
	},
	{
	  "name": "Тураев Расул Нортожиевич",
	  "from": "Чирчикский государственный педагогический институт",
	  "body": "Нелокальная задача со свободной границей для квазилинейного уравнения диффузии с учетом нелинейной конвекции"
	},
	{
	  "name": "Турсунов Ф.Р.",
	  "body": " Задача Коши для уравнения Лапласа в неограниченной области ",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Узянбаев Р.М.,  Бобренева Ю.О.",
	  "body": "Моделирование динамики давления в трещиноватом коллекторе в зависимости от граничных условий",
	  "from": "МГУ им. Н. П. Огарева, Саранск"
	},
	{
	  "name": "Узянбаев Равиль Мунирович",
	  "from": "МГУ им. Н. П. Огарева",
	  "body": "Моделирование динамики давления в трещиноватом коллекторе в зависимости от граничных условий."
	},
	{
	  "name": "Усманов Азимхон Валижанович",
	  "from": "Самаркандский государственный университет, Самарканд",
	  "body": "THE PROBLEM OF RECONSTRUCTING A FUNCTION IN A STRIP VIA INTEGRALS FROM CURVES WITH A SINGULARITY"
	},
	{
	  "name": "Фадеев А.С., Саяпова Е.В.",
	  "body": "Работа с большими данными при анализе приложений с магазина apple store",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Фазлытдинов Марат Флюрович",
	  "from": "ООО Газпромнефть НТЦ, Санкт-Петербург",
	  "body": "Центральное многообразие в математической модель реакции Белоусова-Жаботинского"
	},
	{
	  "name": "Фазуллин З.Ю.",
	  "body": "Критерий равенства нулю регуляризованной суммы со скобками",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Фаизов Рафаэль Рустамович",
	  "from": "Казанский государственный архитектурно-строительный университет, Казань",
	  "body": "Краевая задача Римана для одного класса обобщенных аналитических функций"
	},
	{
	  "name": "Файрузов М.Э., Лубышев Ф.В.  , Лубышев Ф.В.",
	  "body": "Об одном методе решения смешанной краевой задачи для уравнения эллиптического типа в сочетании с методом сеток",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Фасхутдинов А.Г., Коледина К.Ф., Губайдуллин И.М.",
	  "body": "Многоцелевая оптимизация каталитической изомеризации  на основе математической модели",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Фасхутдинова Р.И., Еникеева Л.В., Губайдуллин И.М.",
	  "body": "Исследование кинетики реакций каталитической изомеризации  методами математического моделирования",
	  "from": "Институт нефтехимии и катализа УФИЦ РАН, Уфа"
	},
	{
	  "name": "Федюков Александр Анатольевич",
	  "body": "Управление динамическим объектом при ограничениях на управляющие и фазовые переменные",
	  "from": "ННГУ имени Н.И.Лобачевского, Нижний Новгород, Россия"
	},
	{
	  "name": "Филин Н.В.",
	  "body": "Симметрийный анализ системы уравнений Баера - Нанзиато",
	  "from": "Челябинский государственный университет, Челябинск"
	},
	{
	  "name": "Филиппов В.Н., Эгамов А.И.",
	  "body": "Обобщенное решение начально-краевой задачи для некоторого класса функционально-дифференциальных уравнений",
	  "from": " ННГУ имени Н.И.Лобачевского, Нижний Новгород"
	},
	{
	  "name": "Филиппов Викторий Николаевич",
	  "from": "Нижегородский государственный университет им.Н.И.Лобачевского , Нижний Новгород",
	  "body": "Обобщенная задача Коши для матрично-сверточного оператора"
	},
	{
	  "name": "Хабибуллин Булат Нурмиевич",
	  "from": "Башкирский государственный университет, Уфа",
	  "body": "Integrals of subharmonic functions  and their differences with weight over small sets"
	},
	{
	  "name": "Хабибуллин И.Т., Хакимова А.Р. ",
	  "body": "Некоторые приложения обобщенных инвариантных многообразий",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Хакимова А.Р.",
	  "body": " Приложения обобщенных инвариантных многообразий ",
	  "from": "ИМВЦ УФИЦ РАН, Уфа"
	},
	{
	  "name": "Хакимова Айгуль Ринатовна",
	  "body": "Некоторые приложения обобщенных инвариантных многообразий.",
	  "from": "Институт математики с ВЦ УФИЦ РАН"
	},
	{
	  "name": "Хасанов А.Б., Алланазарова Т.Ж.",
	  "body": "О модифицированном уравнения Кортевега-де Фриза с нагруженным членом ",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Хасанов А.Б., Хоитметов У.А. ",
	  "body": "Интегрирование нагруженного уравнения Кортевега-де Фриза в классе быстроубывающих функций",
	  "from": "Самаркандский государственный университет, Самарканд"
	},
	{
	  "name": "Хасанов Т.Г., Нормуродов Х.Н.",
	  "body": "Интегрирование нагруженного уравнения Кортевега-де Фриза со свободным членом",
	  "from": " УрГУ , Ургенч"
	},
	{
	  "name": "Хасанов Темур Гафуржанович",
	  "body": "ИНТЕГРИРОВАНИЕ НАГРУЖЕННОГО УРАВНЕНИЯ КОРТЕВЕГА-ДЕ ФРИЗА СО СВОБОДНЫМ ЧЛЕНОМ",
	  "from": "УРГЕНЧСКОГО ГОСУДАРСТВЕННОГО УНИВЕРСИТЕТА, Ургенч"
	},
	{
	  "name": "Хасанов Ю.Х., Касымова Е.Ф. ",
	  "body": "Преобразование типа свертки и наилучшее приближение периодических функций",
	  "from": "РТСУ, Душанбе"
	},
	{
	  "name": "Цыганов Ш.И.",
	  "body": "Расчёт коэффициентов интенсивности контактов с последующим инфицированием в SIR-модели  Кермака-Маккендрика для пандемии COVID-19 в весенний и летний периоды 2020 года",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Шабалин П.Л. ",
	  "body": "Задача Гильберта для одного класса обобщенных аналитических функций ",
	  "from": "Казанский государственный архитектурно-строительный университет, Казань"
	},
	{
	  "name": "Шабалин П.Л., Фаизов Р.Р ",
	  "body": "Краевая задача Римана для одного класса обобщенных аналитических функций",
	  "from": "Казанский государственный архитектурно-строительный университет, Казань"
	},
	{
	  "name": "Шавлуков А.М. ",
	  "body": "Особенность омбилического типа решений уравнений одномерной газовой динамики ",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Шайхуллина П.А. ",
	  "body": "Аналитическая классификация ростков типичных полугиперболических отображений",
	  "from": "Челябинский государственный университет, Челябинск"
	},
	{
	  "name": "Шайхуллина Полина Алексеевна",
	  "from": "Челябинский государственный университет, Челябинск",
	  "body": "О вырожденности орбит разложимых алгебр Ли"
	},
	{
	  "name": "Шамсудинов Ф.М., Ханимкулов А.С. ",
	  "body": "Интегральные представления решений для  одной переопределенной   системы    дифференциальных  уравнений  второго порядка   c    особыми   коэффициентами",
	  "from": "БохГУ, Бохтар"
	},
	{
	  "name": "Шамсудинов Ф.М., Хомиддин С.. ",
	  "body": "Об  одной передопределенной системы дифференциальных уравнений второго порядка с сингулярными коэффициентами",
	  "from": "БохГУ, Бохтар"
	},
	{
	  "name": "Шарипов Б., Джумаев Э. Х.",
	  "body": "О некоторых переопределённых системах дифференциальных уравнений второго порядка",
	  "from": "Таджикский государственный финансово-экономический университет, Душанбе"
	},
	{
	  "name": "Шарипов Б., Джумаев Э.Х. ",
	  "body": "Формула представления решений одного класса эллиптической системы уравнений второго порядка",
	  "from": "ТГФЭУ, Душанбе"
	},
	{
	  "name": "Шарипов Руслан Абдулович",
	  "body": "Об ограничениях на высоту сверхвысоких зданий с надувным каркасом",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Шарифзода З.И., Нуров И.Д.",
	  "body": "Об устойчивости стационарного решения одной нелинейной системы дифференциальных уравнений",
	  "from": "НИИ Таджикского национального университета, Душанбе"
	},
	{
	  "name": "Шварева Е.Н., Еникеева Л.В. ",
	  "body": "Алгоритмы поиска гармонии и электромагнетизма в оптимизационных задачах",
	  "from": "Уфимский государственный нефтяной технический университет, Уфа"
	},
	{
	  "name": "Шерстюков В.Б.",
	  "body": "Об асимптотическом поведении целой функции с корнями на луче",
	  "from": "НИЯУ МИФИ, "
	},
	{
	  "name": "Шилов Николай Вячеславович",
	  "from": "АНО ВО Университет Иннодолис, Иннополис",
	  "body": "Towards platform-independent specification and verification of the standard trigonometry functions in computer arithmetics"
	},
	{
	  "name": "Шкаликов А.А. ",
	  "body": "Multipliers in Sobolev spaces and their applications in the theory of differential operators",
	  "from": "МГУ им. М.В. Ломоносова, Москва"
	},
	{
	  "name": "Эгамов Альберт Исмаилович",
	  "from": "Нижегородский университет Н.И.Лобачевского",
	  "body": "Обобщенное решение начально-краевой задачи для некоторого класса  функционально-дифференциальных уравнений"
	},
	{
	  "name": "Эйвазов Э.Х.",
	  "body": "Модели магнитных Лапласианов с магнитным полем четвертого рода",
	  "from": "Бакинский государственный университет, Баку"
	},
	{
	  "name": "Эргашбоев Т., Воситова А.Д. ",
	  "body": "Об обыкновенных дифференциальных уравнениях, допускающих трёхмерную алгебру Ли",
	  "from": "ХГУ имени Б.Гафурова, Худжанд"
	},
	{
	  "name": "Юмагулов М.Г., Ибрагимова Л.С.,  Белова А.С.",
	  "body": "Методы теории возмущений в задаче  о параметрическом резонансе  для линейных периодических  гамильтоновых систем",
	  "from": "Башкирский государственный университет, Уфа"
	},
	{
	  "name": "Юсупова Розалия Мансуровна",
	  "from": "Институт физики молекул и кристаллов УФИЦ РАН, Уфа",
	  "body": "ACCRETION FLOW ON JOSHI-MALAFARINA-NARAYAN NAKED SINGULARITY"
	},
	{
	  "name": "Якупов Нух Махмудович",
	  "body": "Исследование оболочек сложной геометрии",
	  "from": "ИММ КазНЦ РАН, Казань"
	}
  ]
  function throttle(func, ms) {

	let isThrottled = false,
	  savedArgs,
	  savedThis;
  
	function wrapper() {
  
	  if (isThrottled) { // (2)
		savedArgs = arguments;
		savedThis = this;
		return;
	  }
  
	  func.apply(this, arguments); // (1)
  
	  isThrottled = true;
  
	  setTimeout(function() {
		isThrottled = false; // (3)
		if (savedArgs) {
		  wrapper.apply(savedThis, savedArgs);
		  savedArgs = savedThis = null;
		}
	  }, ms);
	}
  
	return wrapper;
  }
export default function Members() {
	const classes = useStyles();
	const [members, setMembers] = useState(initialState);
	const filterMember = (e) => {
		let variant;
		e.currentTarget.id === 'search-name' ? (variant = 'name') : (variant = 'body');

		const value = e.target.value;
		let copy = [...initialState];
		variant === 'name'
			? setMembers(copy.filter((el) => el.name.search(new RegExp(value, 'ig')) !== -1))
			: setMembers(copy.filter((el) => el.body.search(new RegExp(value, 'ig')) !== -1));
	};

	return (
		<Container maxWidth='lg' className={classes.container}>
			<Paper className={classes.paper}>
				<Box p={4} className={classes.heading}>
					<Typography variant='h3' className={classes.title}>
						Архив принятых заявок: {initialState.length}
					</Typography>
					<Box className={classes.inputs}>
						<TextField
							id='search-name'
							size='small'
							fullWidth
							onChange={throttle(filterMember, 400)}
							label='Поиск по фамилии'
						/>
						<TextField
							id='search-body'
							size='small'
							fullWidth
							onChange={filterMember}
							label='Поиск по названию'
						/>
					</Box>
				</Box>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>№</TableCell>
								<TableCell align='center'>ФИО</TableCell>
								<TableCell align='center'>Название доклада</TableCell>
								<TableCell align='center'>Организация, город</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{members.map((row, index) => (
								<TableRow key={row.name + ' ' + row.body}>
									<TableCell component='th' scope='row'>
										{index + 1}
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.body}</TableCell>
									<TableCell>{row.from}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
}
