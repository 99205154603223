import React from 'react'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import { shortName } from '../../constants'
import collection1 from '../../assets/collectionTezis23/Тезисы_УОМШ-2023, 1 том.pdf'
import collection2 from '../../assets/collectionTezis23/Тезисы_УОМШ-2023, 2 том.pdf'

const Collection = () => {
    return (
        <LayoutPage headling={`Сборник тезисов ${shortName}`}>
            <ul>
                {/* <li><a href={collection1}>Тезисы УОМШ-2023. Том 1</a></li>
                <li><a href={collection2}>Тезисы УОМШ-2023. Том 2</a></li> */}
            </ul>
        </LayoutPage>
    )
}

export default Collection
