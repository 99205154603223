import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import PersonIcon from '@material-ui/icons/Person';
import LayoutPage from '../../hoc/LayoutPage/LayoutPage';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 60,
		[theme.breakpoints.down('sm')]: {
			padding: 10
		}
	},
	heading: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(28)
		}
	},
	body: {
		padding: '30px 0',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			padding: 0
		}
	},
	subheading: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(14)
		}
	},
	avatar: {
		width: theme.spacing(8),
		height: theme.spacing(8)
	},
}));

const Commitet = () => {
	const classes = useStyles();
	const commitet = [
		{
			name: 'Садовничий Виктор Антонович',
			from: 'Московский государственный университет им. М.В.Ломоносова, г. Москва',
			member: 'Академик РАН',
			rang: 'Председатель'
		},
		{
			name: 'Захаров Вадим Петрович',
			from: 'УУНиТ, г. Уфа',
			member: 'Д. хим. н.',
			rang: 'Сопредседатель'
		},
		{
			name: 'Шкаликов Андрей Андреевич',
			from: 'МГУ им. М.В. Ломоносова, г. Москва',
			member: 'Член-корреспондент РАН',
			rang: 'Сопредседатель'
		},
		{
			name: 'Арсланов Марат Мирзаевич',
			from: 'НОМЦ ПФО, г. Казань',
			member: 'Академик АН Респ. Татарстан',
			rang: 'Сопредседатель'
		},
		{
			name: 'Сергеев Армен Глебович',
			from: 'МИАН, г. Москва',
			member: 'Д.ф.-м.н.',
			rang: 'Сопредседатель'
		},
		{
			name: 'Аптекарев Александр Иванович',
			from: 'ИПМ им. М.В. Келдыша РАН, г.Москва',
			member: 'Член-корреспондент РАН',
			rang: 'Заместитель председателя'
		},
		{
			name: 'Юлмухаметов Ринад Салаватович',
			from: 'ИМВЦ УФИЦ РАН, г. Уфа',
			member: 'Член-корреспондент АН Респ. Башкортостан',
			rang: 'Заместитель председателя'
		},
		{
			name: 'Доброхотов Сергей Юрьевич',
			from: 'ИПМех РАН, г. Москва',
			member: 'Д.ф.-м.н',
			rang: 'Заместитель председателя'
		},
		{
			name: 'Тишкин В.Ф.',
			from: 'ИПМ им. М.В. Келдыша РАН, г. Москва',
			member: 'Член-корреспондент РАН',
			rang: 'Член'
		},
		{
			name: 'Мухамадиев Э.М.',
			from: 'ВГТУ, г. Вологда',
			member: 'Член-корреспондент АН Респ. Таджикистан',
			rang: 'Член'
		},
		{
			name: 'Насыров С.Р.',
			from: 'КФУ, г. Казань',
			member: 'Член-корреспондент АН  Респ. Татарстан',
			rang: 'Член'
		},
	];

	const commonList = [
		{
			"name": "Абанин А.В.",
			"from": "ЮФУ, г. Ростов-на-Дону",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Авхадиев Ф.Г.",
			"from": "КФУ, г. Казань",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Асташкин С.В. ",
			"from": "Самарский университет,  г. Самара",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Веретенников А.Ю.",
			"from": "ИППИ РАН, г. Москва",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Гайсин А.М.",
			"from": "ИМВЦ  УФИЦ  РАН, г. Уфа",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Кангужин Б.Е.",
			"from": "Казахский национальный университет им. аль-Фараби, г. Алма-Ата",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Каюмов И.Р.",
			"from": "НОМЦ ПФО, г. Казань",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Кордюков Ю.А.",
			"from": "ИМВЦ  УФИЦ  РАН, г. Уфа",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Максимов В.П.",
			"from": "ПГНИУ, г. Пермь",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Мирзоев К.А.",
			"from": "МГУ им. М.В. Ломоносова, г. Москва",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Мукминов Ф.Х.",
			"from": "ИМВЦ  УФИЦ  РАН, г. Уфа",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Нурсултанов Е. Д.",
			"from": "Казахстанский филиал МГУ им. М.В. Ломоносова, Казахстан",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Починка О.В.",
			"from": "НИУ ВШЭ , г. Нижний Новгород",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Сакбаев В.Ж.",
			"from": "МФТИ,  МИАН, г. Москва",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Скубачевский А.Л.",
			"from": "РУДН, г. Москва.",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Султанаев Я.Т.",
			"from": "БГПУ им. М. Акмуллы, г. Уфа",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Федоров В.Е.",
			"from": "ЧелГУ, г. Челябинск",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Хабибуллин И.Т.",
			"from": "ИМВЦ  УФИЦ  РАН, г. Уфа",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		},
		{
			"name": "Хасанов А.Б.",
			"from": "СамГУ, г. Самарканд",
			"member": "Д.ф.-м.н.",
			"rang": "Член"
		}
	].sort((a, b) => a.name > b.name ? 1 : -1)

	const ListAvatar = ({ name, rang, from, member }) => (
		<>
			<Box>
				<Box mx={2}>
					<Grid container alignItems='center' spacing={3}>
						<Hidden mdDown>
							<Grid item>
								<Avatar className={classes.avatar}>
									<PersonIcon fontSize='large' />
								</Avatar>
							</Grid>
						</Hidden>

						<Grid item>
							<Typography variant='h5'>
								<Box fontSize={13}>{member}</Box>
								<Box fontStyle='oblique'>{name}</Box>
							</Typography>
							<Typography gutterBottom сolor='textSecondary' variant='body2'>
								{from}
							</Typography>
							<Typography variant='body1'>{rang} программного комитета</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box my={2}>
					<Divider variant='middle' />
				</Box>
			</Box>
		</>
	);

	return (
		<LayoutPage headling='Программный комитет'>
			<Container className={classes.body}>
				{[...commitet, ...commonList].map(e => (
					<ListAvatar key={e.name.slice(0, 5)} name={e.name} from={e.from} member={e.member} rang={e.rang} />
				))}
			</Container>
		</LayoutPage>

	);
};

export default Commitet;
