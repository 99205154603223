import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const AlertUI = props => {
	const {message, type, clearError} = props
	const [isShow, setShow] = useState(false);

	useEffect(() => {
		setShow(!!message);		
	}, [message]);

	const handleClose = () => {
		setShow(false);
		clearError()
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isShow}			
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	);
};

AlertUI.propTypes = {
	message: PropTypes.string,
	type: PropTypes.string,	
};

export default (AlertUI);
