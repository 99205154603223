import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import PersonIcon from '@material-ui/icons/Person';
import LayoutPage from '../../hoc/LayoutPage/LayoutPage';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 60,
		[theme.breakpoints.down('sm')]: {
			padding: 10
		}
	},
	heading: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(28)
		}
	},
	body: {
		padding: '30px 0',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			padding: 0
		}
	},
	subheading: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(14)
		}
	},
	avatar: {
		width: theme.spacing(8),
		height: theme.spacing(8)
	},
}));

const СommitetOrg = () => {
	const classes = useStyles();
	const commitet = [
		{
			name: 'Фазуллин Зиганур Юсупович',
			from: 'УУНиТ, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Председатель'
		},
		{
			name: 'Мусин Ильдар Хамитович',
			from: 'ИМВЦ УФИЦ РАН, г. Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Заместитель председателя'
		},
		{
			name: 'Юмагулов Марат Гаязович',
			from: 'УУНиТ, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Заместитель председателя'
		},
		{
			name: 'Ишкин Х.К.',
			from: 'УУНиТ, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Мустафина С.А.',
			from: 'УУНиТ, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Кривошеева О.А.',
			from: 'УУНиТ, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Нуров И.Д.',
			from: 'ТНУ, г. Душанбе',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Хабибуллин Б.Н.',
			from: 'ИМВЦ УФИЦ РАН, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Сулейманов Б.И.',
			from: 'ИМВЦ УФИЦ РАН, г.Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Губайдуллин И.М.',
			from: 'ИНК УФИЦ РАН, г. Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Муравник А.Б.',
			from: 'РУДН, г. Москва.',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Морозкин Н.Д.',
			from: 'УУНиТ,  г. Уфа',
			member: 'Д.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Гарифуллин Р.Н.',
			from: 'ИМВЦ УФИЦ РАН, г. Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Ибрагимова Л.С.',
			from: 'УУНиТ, г.Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Сагитова А.Р.',
			from: 'УУНиТ, г.Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Садриева Р.Т.',
			from: 'УУНиТ, г.Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Гайсина Г.А.',
			from: 'УУНиТ, г.Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Кужаев А.Ф.',
			from: 'УУНиТ, г.Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Тазюков Б.Ф.',
			from: 'НОМЦ ПФО, г. Казань',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Файзрахманов М.Х.',
			from: 'НОМЦ ПФО, г. Казань',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Белова А.С.',
			from: 'УУНиТ, г.Уфа',
			member: 'К.ф.-м.н.',
			rang: 'Член'
		},
		{
			name: 'Габдрахманов Р.И.',
			from: 'УУНиТ, г.Уфа',
			member: '',
			rang: 'Секретарь'
		},
		{
			name: 'Нургалеев И.Ф.',
			from: 'УУНиТ, г.Уфа',
			member: '',
			rang: 'Секретарь'
		},

	];
	const ListAvatar = ({ name, rang, from, member }) => (
		<>
			<Box>
				<Box mx={2}>
					<Grid container alignItems='center' spacing={3}>
						<Hidden mdDown>
							<Grid item>
								<Avatar className={classes.avatar}>
									<PersonIcon fontSize='large' />
								</Avatar>
							</Grid>
						</Hidden>

						<Grid item>
							<Typography variant='h5'>
								<Box fontSize={13}>{member}</Box>
								<Box fontStyle='oblique'>{name}</Box>
							</Typography>
							<Typography gutterBottom сolor='textSecondary' variant='body2'>
								{from}
							</Typography>
							<Typography variant='body1'>{rang} организационного комитета</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box my={2}>
					<Divider variant='middle' />
				</Box>
			</Box>
		</>
	);

	return (
		<LayoutPage headling='Организационный комитет'>
			<Container className={classes.body}>
				{commitet.map(e => (
					<ListAvatar key={e.name.slice(0, 5)} name={e.name} from={e.from} member={e.member} rang={e.rang} />
				))}
			</Container>
		</LayoutPage>

	);
};

export default СommitetOrg;
