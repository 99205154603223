import React from 'react'
import Typography from '@material-ui/core/Typography'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import { TIMING, YEAR } from '../../constants'

const About = () => {
    return (
        <LayoutPage headling='О конференции'>
            <>
                <Typography variant='body1' paragraph><strong>Международная научная конференция «УФИМСКАЯ ОСЕННЯЯ МАТЕМАТИЧЕСКАЯ ШКОЛА — {YEAR}»</strong></Typography>
                <Typography variant='body1' paragraph><strong>Организаторы:</strong> Уфимский университет науки и технологий, Институт математики с ВЦ
                    УФИЦ РАН (г. Уфа) , НОМЦ Приволжского федерального округа.</Typography>
                <Typography variant='body1' paragraph><strong>Место проведения:</strong> Институт информатики, математики и робототехники УУНиТ (г.Уфа, ул.
                    З.Валиди, 32).</Typography>
                <Typography variant='body1' paragraph><strong>Сроки проведения:</strong> {TIMING}.</Typography>
                <Typography variant='body1' paragraph><strong>Председатель Программного комитета:</strong> академик РАН Садовничий Виктор Антонович (МГУ им.
                    М.В.Ломоносова).</Typography>
                <Typography variant='body1' paragraph><strong>Председатель Организационного комитета:</strong> д.ф.-м.н. Фазуллин Зиганур Юсупович.</Typography>
                <Typography variant='body1' paragraph><strong>Контактные данные Оргкомитета:</strong> Институт информатики, математики и робототехники УУНиТ (г. Уфа, ул. З. Валиди 32а), 450074.
                    <div>Тел.: <a href="tel:+79177662488">(+7) 917-766-24-88</a> (WhatsApp)</div> </Typography>
                <Typography variant='body1' paragraph><strong>Сайт:</strong><a href="https://conf-bashedu-fmit.ru/"> www.conf-bashedu-fmit.ru</a></Typography>
                <Typography variant='body1' paragraph><strong>e-mail:</strong><a href="ufa.mat.sh@mail.ru"> ufa.mat.sh@mail.ru</a> </Typography>

            </>
        </LayoutPage>
    )
}

export default About
