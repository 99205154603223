import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {
			padding: 15,
			marginTop: 10,
		},
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			padding: 4,
			marginTop: 10,
			flexDirection: 'column',
		},
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	title: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.9rem',
		},
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		width: '260px',
	},
}));

const initialState = [
    {
        "name": "Abed S.A., Nikolaeva I.A., Novikov A.A.",
        "body": "Generalisation of Michelson contrast for operators and its properties",
        "organization": "Diyala University; Innopolis University; Sobolev Institute of Mathematics",
        "from": "Baquba; Innopolis; Novosibirsk"
    },
    {
        "name": "Авхадиев Ф.Г.",
        "body": "Оценки жесткости кручения и основной частоты",
        "organization": "КФУ",
        "from": "Казань"
    },
    {
        "name": "Аитбаева А.А.",
        "body": "Определение параметров упругого закрепления стержня",
        "organization": "ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Баззаев А.К.",
        "body": "О сходимости разностных схем для дифференциальных уравнений дробного порядка",
        "organization": "СОГУ им. К.Л. Хетагурова, ВИУ",
        "from": "Владикавказ"
    },
    {
        "name": "Барабанов Е.А., Быков В.В.",
        "body": "Полное описание показателя Перрона линейной дифференциальной системы с неограниченными коэффициентами",
        "organization": "Институт математики НАН Беларуси; МГУ им. М.В.Ломоносова",
        "from": "Минск; Москва"
    },
    {
        "name": "Баранов А.Д., Каюмов И.Р., Хамматова Д.М., Хасянов Р.Ш.",
        "body": "Неравенства типа Вейсслера в пространствах Бергмана",
        "organization": "СПбГУ; КФУ",
        "from": "Санкт-Петербург; Казань"
    },
    {
        "name": "Башмаков Р.А., Фокеева Н.О.",
        "body": "О динамике давления в трещине ГРП при переходных режимах работы скважины",
        "organization": "БашГУ; ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Боброва И.А.",
        "body": "Non-abelian Painlev\\'e systems with generalized Okamoto integral",
        "organization": "НИУ ВШЭ",
        "from": "Москва"
    },
    {
        "name": "Бондаренко Н.П.",
        "body": "Обратные спектральные задачи для дифференциальных операторов с коэффициентами-распределениями",
        "organization": "СГУ, Самарский университет",
        "from": "Саратов, Самара"
    },
    {
        "name": "Ветохин А.Н.",
        "body": "О бэровской классификации локальной энтропии динамической системы",
        "organization": "МГУ имени М.В.Ломоносова, МГТУ им. Н.Э. Баумана",
        "from": "Москва"
    },
    {
        "name": "Власов В.В.",
        "body": "Спектральный анализ вольтерровых  интегро-дифференциальных уравнений",
        "organization": "МГУ имени М.В.Ломоносова",
        "from": "Москва"
    },
    {
        "name": "Гапеев М.И., Паровик Р.И., Солодчук А.А.",
        "body": "Математическая модель высокочастотной геоакустической эмиссии на основе связанных осцилляторов",
        "organization": "ИКИР ДВО РАН",
        "from": "Паратунка, Россия"
    },
    {
        "name": "Гребенникова И.В.",
        "body": "К задаче управления сингулярно возмущенной системой с запаздыванием при геометрических ограничениях",
        "organization": "УГГУ",
        "from": "Екатеринбург"
    },
    {
        "name": "Губарев Ю.Г., Сунь Ш.",
        "body": "Исследование неустойчивости одномерных состояний динамического равновесия самогравитирующего газа Власова--Пуассона",
        "organization": "ИГиЛ СО РАН, НГУ",
        "from": "Новосибирск"
    },
    {
        "name": "Жуйков К.Н.",
        "body": "Индекс дифференциально-разностных операторов на бесконечном цилиндре",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Керимбаев Р.К.",
        "body": "Алгебраическое многообразие келлеровых многочленов",
        "organization": "КазНУ",
        "from": "Алматы"
    },
    {
        "name": "Козко А.И., Лужина Л.М., Попов А.Ю., Чирский В.Г.",
        "body": "Оценки определяющих функций в математической модели задачи экономического роста",
        "organization": "МГУ им. М.В. Ломоносова",
        "from": "Москва"
    },
    {
        "name": "Корпебай Г.Т., Айсагалиев С.",
        "body": "Оптимальное быстродействие линейных систем с фазовыми и интегральными ограничениями",
        "organization": "КазНУ",
        "from": "Алматы"
    },
    {
        "name": "Линчук Л.В.",
        "body": "Факторизация уравнений с частными производными",
        "organization": "СПбПУ",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Литвинов В.Л.",
        "body": "Stochastic longitudinal vibrations viscoelastic beam with moving boundaries",
        "organization": "МГУ им. М.В. Ломоносова, СамГТУ",
        "from": "Москва, Самара"
    },
    {
        "name": "Малютин К.Г.",
        "body": "О типе мероморфной функции конечного порядка в полуплоскости",
        "organization": "КГУ",
        "from": "Курск"
    },
    {
        "name": "Муравник А.Б.",
        "body": "Уравнения и неравенства с нелинейностями KPZ-типа: качественные свойства решений",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Муртазина Р.Д., Галина Г.К., Сидельникова Н.А., Уразбахтина Л.З.",
        "body": "Характеристические системы уравнений в частных производных первого порядка",
        "organization": "УГАТУ; БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Невский М.В.",
        "body": "О константах эквивалентности для модулей непрерывности различного вида",
        "organization": "ЯрГУ им. П.Г. Демидова",
        "from": "Ярославль"
    },
    {
        "name": "Орипов Т.С.",
        "body": "О решении одной системы дифференциальной уравнений в комплексной плоскости",
        "organization": "Денауский институт предпринимательство и педагогики",
        "from": " Денау, Узбекистан"
    },
    {
        "name": "Поляков Д.М.",
        "body": "Асимптотика собственных значений самосопряженного оператора четвертого порядка с негладкими коэффициентами",
        "organization": "ЮМИ ВНЦ РАН",
        "from": "Владикавказ"
    },
    {
        "name": "Рудько Я.В., Корзюк В.И.",
        "body": "Локальное классическое решение задачи Коши для полулинейного гиперболического уравнения в случае двух независимых переменных",
        "organization": "Белорусский ГУ",
        "from": "Минск"
    },
    {
        "name": "Сагитова А.Р., Марданов Б.И.",
        "body": "Об асимптотическом поведении решений дифференциальных уравнений произвольного порядка с осциллирующими коэффициентами",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Сагитова А.Р., Шакирова А.В.",
        "body": "Об асимптотическом поведении решений уравнения Штурма-Лиувилля с осциллирующим потенциалом",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Сакс Р.С.",
        "body": "Новые семейства пространств Соболева трехмерных векторных полей",
        "organization": "ИМВЦ",
        "from": "Уфа"
    },
    {
        "name": "Салимова А.Е.",
        "body": "Теорема единственности для целых функций экспоненциального типа",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Смирнова А.С.",
        "body": "Lp-аппроксимации решений параболических дифференциальных уравнений на многообразиях",
        "organization": "НИУ ВШЭ",
        "from": "Нижний Новгород"
    },
    {
        "name": "Солиев Ю.С.",
        "body": "Некоторые квадратурные формулы для преобразования Гильберта",
        "organization": "МАДИ",
        "from": "Москва"
    },
    {
        "name": "Тамаева В.А.",
        "body": "Ортогональная аддитивность произведения степеней линейных функционалов",
        "organization": "ЮМИ ВНЦ РАН",
        "from": "Владикавказ"
    },
    {
        "name": "Тасевич А.Л.",
        "body": "О сильно эллиптическом функционально-дифференциальном уравнении с ортотропными сжатиями",
        "organization": "ФИЦ ИУ РАН, РУДН",
        "from": "Москва"
    },
    {
        "name": "Хасанов Ю.Х.",
        "body": "Об абсолютной сходимости кратных рядов Фурье почти- периодических функций",
        "organization": "РТСУ",
        "from": "Душанбе"
    },
    {
        "name": "Хусенов Б.Э.",
        "body": "Construction of a quenching A(z)-analytic function",
        "organization": "Бухарский государственный университет",
        "from": "Бухара"
    },
    {
        "name": "Шарипов Р.А.",
        "body": "О динамике трёхмерной вселенной в четырёхмерном пространстве-времени",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Шарипов Р.А.",
        "body": "О реализуемости любой конечной схемы эволюции в задаче Коллатца",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Шарипов Б., Джумаев Э.Х.",
        "body": "Представления решений одного класса систем трёх уравнений в частных производных комплексной плоскости с сингулярными коэффициентами",
        "organization": "ТГФЭУ; МГУ им. М.В.Ломоносова в г. Душанбе",
        "from": "Душанбе"
    },
    {
        "name": "Юрко В.А.",
        "body": "Inverse Spectral Problems for Differential Operators",
        "organization": "СГУ",
        "from": "Саратов"
    },
    {
        "name": "Ячиков И.М., Починская В.А.",
        "body": "Математическое моделирование гидравлических параметров водяного охлаждения медной стенки слябового кристаллизатора МНЛЗ",
        "organization": "ЮУрГУ",
        "from": "Челябинск"
    },
    {
        "name": "Баранов А.Д., Каюмов И.Р.",
        "body": "О неравенствах Долженко для рациональных функций",
        "organization": "СПбГУ; КФУ",
        "from": "Санкт—Петербург; Казань"
    },
    {
        "name": "Бикчентаев А.М., Хадур М.",
        "body": "Разности идемпотентов в  С*-алгебрах и квантовый эффект Холла, II. Неограниченные идемпотенты",
        "organization": "КФУ",
        "from": "Казань"
    },
    {
        "name": "Духновский С.А.",
        "body": "Групповой анализ дискретной системы Карлемана",
        "organization": "НИУ МГСУ",
        "from": "Москва"
    },
    {
        "name": "Плеханова М.В., Ижбердеева Е.М.",
        "body": "Разрешимость нелинейного вырожденного уравнения с производной Джрбашяна - Нерсесяна",
        "organization": "ЧелГУ, ЮУрГУ",
        "from": "Челябинск"
    },
    {
        "name": "Шавлуков А.М.",
        "body": "Катастрофы решений уравнений одномерной газовой динамики и наследование ростков катастроф решений волнового уравнения",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Бойко К.В., Федоров В.Е.",
        "body": "О разрешимости линейного неоднородного уравнения с несколькими производными Герасимова - Капуто в секториальном случае",
        "organization": "ЧелГУ",
        "from": "Челябинск"
    },
    {
        "name": "Раутиан Н. А.",
        "body": "Исследование вольтерровых интегро-дифференциальных уравнений методами теории полугрупп операторов",
        "organization": "МГУ им. М.В. Ломоносова, МЦФиПМ",
        "from": "Москва"
    },
    {
        "name": "Починка О. В., Шубин Д.Д.",
        "body": "Неособые потоки  морса-смейла с тремя периодическими орбитами на ориентируемых 3-многообразиях",
        "organization": "НИУ ВШЭ",
        "from": "Нижний Новгород"
    },
    {
        "name": "Ташбулатов С.М.",
        "body": "Structure of essential spectra and discrete spectrum of the energy operator of six-electron systems in the Hubbard model. Second triplet state",
        "organization": "ИЯФ АН РУз.",
        "from": "Ташкент"
    },
    {
        "name": "Сабитов К.Б.",
        "body": "Обратные коэффициентные задачи для телеграфного уравнения с переменным потенциалом",
        "organization": "Институт стратегических исследований РБ,  Стерли-тамакский филиал БашГУ",
        "from": "Стерлитамак"
    },
    {
        "name": "Одинабеков Д.М.",
        "body": "Условия нетеровости для двумерных сингулярных интегральных операторов",
        "organization": "Филиал МГУ им. М.В. Ломоносова  в г. Душанбе",
        "from": "Душанбе"
    },
    {
        "name": "Хасанов А.Б.",
        "body": "ЗАДАЧА КОШИ ДЛЯ УРАВНЕНИЯ ХИРОТА В КЛАССЕ ПЕРИОДИЧЕСКИХ БЕСКОНЕЧНОЗОННЫХ ФУНКЦИИ",
        "organization": "Самаркандский государственный университет",
        "from": "Самарканд"
    },
    {
        "name": "Равчеев А.В.",
        "body": "Описание линейного эффекта Перрона при параметрических возмущениях линейной дифференциальной системы с неограниченными коэффициентами",
        "organization": "МГУ им. М.В. Ломоносова",
        "from": "Москва"
    },
    
    {
        "name": "Илолов М.И., Лашкарбеков С.М., Рахматов Дж.Ш.",
        "body": "Задача Коши, обратная к корректной для дробных эволюционных стохастических уравнений",
        "organization": "Центр инновационного развития науки и новых технологий НАН Таджикистана",
        "from": "Душанбе"
    },
    {
        "name": "Шишкин А.Б., Саранчук Ю.C.",
        "body": "Однородное уравнение π-свертки",
        "organization": "КубГУ",
        "from": "Краснодар"
    },
    {
        "name": "Доброхотов С.Ю., Миненков Д.С., Назайкинский В.Е.",
        "body": "Асимптотические решения нелинейных уравнений мелкой воды в бассейнах с пологими берегами",
        "organization": "ИПМехРАН",
        "from": "Москва"
    },
    {
        "name": "Новиков С.Я., Избяков И.М.",
        "body": "Математические задачи в восстановлении сигнала без фаз",
        "organization": "Самарский университет",
        "from": "Самара"
    },
    {
        "name": "Скубачевский А.Л.",
        "body": "Априорные оценки решений смешанной задачи для системы Власова-Пуассона в бесконечном цилиндре",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Иванов Н.О., Скубачевский А.Л.",
        "body": "Гладкость обобщенных решений краевых задач для дифференциально-разностных уравнений второго порядка с переменными коэффициентами",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Латыпов И.И.",
        "body": "Асимптотика решения сингулярно возмущенной краевой задачи",
        "organization": "Бирский филиал БашГУ",
        "from": "Бирск"
    },
    {
        "name": "Мухамадиев Э.М., Нуров И.Д.",
        "body": "О топологическом методе анализа точки бифуркации дифференциального уравнения второго порядка",
        "organization": "ВоГУ",
        "from": "Вологда"
    },
    {
        "name": "Беляева Ю.",
        "body": "On the existence of weak solutions to the boundary value problem for the Vlasov-Poisson system with an external magnetic field",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Takhirov J., Djumanazarova Z.",
        "body": "On the diffusive epidemic model SEI with immigrations",
        "organization": "Institute of Mathematics",
        "from": "Tashkent"
    },
    {
        "name": "Таран А.Е., Кажарова И.А., Фоменко Н.А.",
        "body": "Функция влияния света в модели динамики биомассы фитопланктона, математическое моделирование",
        "organization": "ИКТИБ ЮФУ; НИУ им. И.М. Губкина",
        "from": "Таганрог; Москва"
    },
    {
        "name": "Каюмов Ш., Куралов Б.А., Эсанов Э.А.",
        "body": "Конструирования многопараметрических математических моделей задачи фильтрации флюидов в слоистых пористых средах",
        "organization": "ТГТУ",
        "from": "Ташкент"
    },
    {
        "name": "Солонуха О.В.",
        "body": "Достаточно общие условия существования решений существенно нелинейных параболических дифференциально-разностных уравнений",
        "organization": "ФИЦ ИУ РАН, РУДН",
        "from": "Москва"
    },
    {
        "name": "Мухамадиев Э., Наимов А.Н., Назимов А.Б.",
        "body": "О вычислении вращения конечномерного векторного поля",
        "organization": "ВоГУ",
        "from": "Вологда"
    },
    {
        "name": "Рахимова М.А.",
        "body": "",
        "organization": "Многомерные переопределенные системы уравнений в частных производных с двумя комплексными переменными",
        "from": "Таджикский государственный университет права, бизнеса и политики"
    },
    {
        "name": "Олими А.Г., Охунов Н.К.",
        "body": "Общее представление решений и задачи Коши-Рикье для одного операторно-дифференциального уравнения с тремя сингулярными точками",
        "organization": "ХГУ",
        "from": "Худжанд"
    },
    {
        "name": "Омарова А.Г.",
        "body": "Численное решение одной краевой задачи для уравнения теплопроводности с дробной производной Капуто",
        "organization": "Дагестанский государственный университет",
        "from": "Махачкала"
    },
    {
        "name": "Загоруйко А.Н.",
        "body": "Математическое моделирование каталитических и электрохимических устройств",
        "organization": "НГТУ",
        "from": "Новосибирск"
    },
    {
        "name": "Слепнёв С.В., Коледина К.Ф.",
        "body": "Анализ данных на примере кинетики сложных процессов люминесценции на Python",
        "organization": "УГНТУ, ИНК УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Усманова А.А., Коледина К.Ф.",
        "body": "Моделирование многостадийной гетерогенной реакции на поверхности цеолитного катализатора с учетом процесса диффузии",
        "organization": "ИНК УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Асхабов С.Н.",
        "body": "Об одном нелинейном интегро-дифференциальном уравнении с суммарно-разностным ядром",
        "organization": "ЧГУ им. А.А. Кадырова",
        "from": "Грозный"
    },
    {
        "name": "Рахматзода M.A, Ахмерова Э.Ф.",
        "body": "Формула регуляризованного следа дифференциального оператора 2m -го порядка с периодическими граничными условиями",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Бортковская М.Р.",
        "body": "Исследование системы обыкновенных дифференциальных уравнений для моделирования конкуренции в естественном языке",
        "organization": "СПБГУ",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Алфимов Г.Л., Федотов А.П., Куценко Н.А.",
        "body": "Stationary modes for vector nonlinear Schrodinger-type equations: a numerical procedure for complete search",
        "organization": "МИЭТ",
        "from": "Москва"
    },
    {
        "name": "Плеханова М.В., Шуклина А.Ф.",
        "body": "Смешанное управление для вырожденных нелинейных уравнений с дробными производными Герасимова - Капуто",
        "organization": "ЧелГУ, ЮУрГУ",
        "from": "Челябинск"
    },
    {
        "name": "Павленко В.А.",
        "body": "Построение решений аналогов временных уравнений Шредингера соответствующих гамильтоновой системе H^{2+2+1}$Кимуры",
        "organization": "ИМ ВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Пескова Е.Е., Снытников В.Н.",
        "body": "Численное моделирование конверсии этан-метановых смесей под воздействием лазерного излучения в прямой осесимметричной трубе",
        "organization": "Мордовский государственный университет, Институт Катализа СО РАН",
        "from": "Саранск, Новосибирск"
    },
    {
        "name": "Рахимова А.И.",
        "body": "О гиперциклических операторах в весовых пространствах бесконечно дифференцируемых функций",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Artemeva M.V., Korpusov M.O.",
        "body": "Blow up of an abstract Cauchy problem solution whith nonlinear operator coefficients and non-coercive source",
        "organization": "МГУ",
        "from": "Москва"
    },
    {
        "name": "Boltachev A.V., Savin A.Yu.",
        "body": "Index of twisted elliptic boundary value problems associated with isometric group actions",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Шевелёв А.П., Гильманов А.Я., Канонирова Е.П.",
        "body": "Разработка математической модели для прогнозирования характерных размеров зоны дренирования пласта с трещиной авто-ГРП",
        "organization": "ТюмГУ",
        "from": "Тюмень"
    },
    {
        "name": "Абанин А.В.",
        "body": "Динамические свойства оператора дифференцирования в весовых пространствах целых функций",
        "organization": "ЮМИ ВНЦ РАН, ЮФУ",
        "from": "Владикавказ, Ростов-на-Дону"
    },
    {
        "name": "Хасанов А.Б., Нормуродов Х.Н., Худоёров У.О.",
        "body": "Задачи Коши для нелинейного уравнения типа синус-Гордона в классе периодических  бесконечнозонных функций",
        "organization": "СамГУ, СамГАСИ",
        "from": "Самарканд"
    },
    {
        "name": "Хасанов А.Б., Маннонов Г.А., Эшбеков Р.",
        "body": "Задача Коши для уравнения Хирота в классе периодических бесконечнозонных функции",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Подрыга В.О., Поляков С.В., Тарасов Н.И., Усачев В.А.",
        "body": "Математическое моделирование процессов сверхзвукового холодного газодинамического напыления наночастиц на подложки",
        "organization": "ИПМ им. М.В. Келдыша РАН, МАДИ",
        "from": "Москва"
    },
    {
        "name": "Алексеева Е.С., Рассадин А.Э.",
        "body": "Новая аппроксимация экспоненты и е\\\"{e} применение в теории C_0-полугрупп",
        "organization": "ВШЭ",
        "from": "Нижний Новгород"
    },
    {
        "name": "Язовцева О.С., Губайдуллин И.М., Загоруйко А.Н., Пескова Е.Е.",
        "body": "Численное моделирование окислительной регенерации цилиндрического зерна катализатора",
        "organization": "НИ Мордовский государственный университет; УГНТУ; ИК СО РАН",
        "from": "Саранск; Уфа; Новосибирск"
    },
    {
        "name": "Спеле В.В., Лукащук С.Ю.",
        "body": "Компьютерное моделирование распространения волн в средах со степенной пространственной нелокальностью",
        "organization": "УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Хабибуллин Б.Н.",
        "body": "Развития  теорем Бёрлинга - Мальявена и Мальявена - Рубела о целых функциях",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Напалков В.В. (мл.), Нуятов А.А.",
        "body": "О совпадении  гильбертовых пространств интегрируемых с квадратом функций",
        "organization": "ИМ ВЦ УФИЦ РАН, ННГУ",
        "from": "Уфа; Нижний Новгород"
    },
    {
        "name": "Лубышев Ф.В., Файрузов М.Э.",
        "body": "Математическое моделирование оптимальных процессов, описываемых полулинейными уравнениями эллиптического типа с разрывными коэффициентами и решениями",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Гаврилов О.А.",
        "body": "Численное исследование одной нелокальной задачи из теории теплопроводности",
        "organization": "МГУ",
        "from": "Саров"
    },
    {
        "name": "Иванов А.В.",
        "body": "Регуляризация обрезанием для ковариантного оператора Лапласа",
        "organization": "МИ им. В.А.Стеклова, Санкт-Петербургский ММи им. Л. Эйлера",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Лесникова Ю.И., Труфанов Н.А., Каменских А.А.",
        "body": "Моделирование изгиба с натягом оптического волокна типа Panda при учете нелинейно деформируемого двухслойного защитного покрытия",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Волкова А.Р., Федоров В.Е.",
        "body": "Линейное неоднородное уравнение с производной Хилфера и ограниченным оператором",
        "organization": "ЧелГУ",
        "from": "Челябинск"
    },
    {
        "name": "Лукащук С.Ю.",
        "body": "О приближенных симметриях одного нелокально возмущенного уравнения типа Шредингера",
        "organization": "УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Цопанов И.Д.",
        "body": "Регуляризованные следы для возмущений Гильберта-Шмидта дискретных спектральных операторов",
        "organization": "СОГУ им. К.Л. Хетагурова",
        "from": "Владикавказ"
    },
    {
        "name": "Шарафутдинов А.Ш.",
        "body": "Градуировка C*-алгебр, порожденных свободными произведениями абелевых полугрупп",
        "organization": "ФГБОУ ВО КГЭУ",
        "from": "Казань"
    },
    {
        "name": "Савчук А.М., Садовничая И.В.",
        "body": "Об операторной группе, порожденной одномерной системой Дирака",
        "organization": "МГУ им. М.В. Ломоносова",
        "from": "Москва"
    },
    {
        "name": "Kuznetsova M.A., Buterin S.A.",
        "body": "Inverse spectral problem for Sturm--Liouville operator with frozen argument and singular coefficients",
        "organization": "Saratov State University",
        "from": "Saratov"
    },
    {
        "name": "Бекназаров Дж.Х.",
        "body": "Оптимальные приближения функций суммами Фурье Чебышева в пространстве Гильберта",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Игнатьев М.Ю.",
        "body": "О поведении данных рассеяния систем с особенностью при малых значениях спектрального параметра",
        "organization": "СГУ",
        "from": "Саратов"
    },
    {
        "name": "Какушкин С.Н.",
        "body": "Развитие численного метода нахождения собственных функций возмущенных самосопряженных операторов",
        "organization": "ИТиСО, Администрация МР Белорецкий район РБ",
        "from": "Белорецк"
    },
    {
        "name": "Мирзоев К.А., Сафонова Т.А.",
        "body": "Лакунарные рекуррентные соотношения для многочленов Бернулли и Эйлера",
        "organization": "МГУ; САФУ",
        "from": "Москва; Архангельск"
    },
    {
        "name": "Буриев Т.Э., Эргашев В.Э.",
        "body": "Динамика системы хищник-жертва с учетом нижней критической плотности популяций жертв и внутривидовой конкуренции",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Ахматов З.А., Тотиева Ж.Д.",
        "body": "Квазидвумерная обратная задача для интегро-дифференциального уравнения вязкоупругости",
        "organization": "ЮМИ ВНЦ РАН, СК ЦМИ ВНЦ РАН",
        "from": "Владикавказ"
    },
    {
        "name": "Апушкинская Д.Е.",
        "body": "Апостериорные оценки ошибок в параболических задачах с препятствием",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Samatov B.T., Juraey B.I.",
        "body": "Dynamics of attainability domain for inertial motions with integro-geometric constraints",
        "organization": "Namangan State University, Andijan State University",
        "from": "Namangan, Andijan"
    },
    {
        "name": "Klevtsova Yu.Yu.",
        "body": "On some inequality for the stationary measure for one model of the atmosphere",
        "organization": "SSC RAS",
        "from": "Novosibirsk"
    },
    {
        "name": "Martynov E.",
        "body": "Inverse problems for the generalized Kawahara equation",
        "organization": "RUDN",
        "from": "Moscow"
    },
    {
        "name": "Гималтдинова А.А.",
        "body": "О задаче Дирихле для уравнения смешанного типа с тремя плоскостями изменения типа",
        "organization": "УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Samatov B.T., Akbarov A.Kh.",
        "body": "Pursuit differential game under Gr’onwall type constraints on controls",
        "organization": "Namangan State University, Andijan State University",
        "from": "Namangan, Andijan"
    },
    {
        "name": "Тухлиев К.",
        "body": "Наилучшие приближения некоторых классов свёрток в пространстве Гильберта",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Кабанко М.В.",
        "body": "О типе мероморфной функции конечного порядка",
        "organization": "КГУ",
        "from": "Курск"
    },
    {
        "name": "Кораблина Ю.В.",
        "body": "Об ограниченности классических операторов на весовых квазибанаховых пространствах голоморфных функций",
        "organization": "ЮМИ ВНЦ РАН",
        "from": "Владикавказ"
    },
    {
        "name": "Кривошеева О.А.",
        "body": "Построение исключительного множества",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Макаров Р.В.",
        "body": "Неравенства типа Харди с дополнительными слагаемыми и параметрические уравнения типа Лэмба",
        "organization": "К(П)ФУ",
        "from": "Казань"
    },
    {
        "name": "Маликов А.М.",
        "body": "Приближении функций в среднем на всей оси алгебраическими полиномами с весом Чебышeва-Эрмита",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Попов А.Ю., Семенова Т.Ю.",
        "body": "Уточнение оценки скорости равномерной сходимости ряда Фурье непрерывной периодической функции ограниченной вариации",
        "organization": "МГУ им. М.В. Ломоносова, МЦФПМ",
        "from": "Москва"
    },
    {
        "name": "Семенова Т.Ю.",
        "body": "Оценка наименьшего положительного корня синус-ряда гармонической функции",
        "organization": "МГУ им. М.В. Ломоносова, МЦФПМ",
        "from": "Москва"
    },
    {
        "name": "Акопян Р.С., Даринский Б.М., Лобода А.В.",
        "body": "О 4-мерных представлениях 3-мерной разложимой алгебры Ли",
        "organization": "МИРЭА; ВГУ; ВГТУ",
        "from": "Москва; Воронеж"
    },
    {
        "name": "Тухлиев Д.К.",
        "body": "Точные константы в экстремальных задачах среднеквадратических приближениях аналитических в круге функций",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Крутских В.В., Лобода А.В.",
        "body": "Компьютерные алгоритмы исследования голоморфно однородных гиперповерхностей",
        "organization": "ВГУ; ВГТУ",
        "from": "Воронеж"
    },
    {
        "name": "Иванова О.А., Мелихов С.Н.",
        "body": "Операторы адамаровского типа в пространствах голоморфных функций",
        "organization": "ЮФУ; ЮМИ ВНЦ РАН",
        "from": "Ростов-на-Дону; Владикавказ"
    },
    {
        "name": "Иванов П.А.",
        "body": "Произведение Дюамеля в пространствах целых функций экспоненциального типа",
        "organization": "ЮФУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Атанов А.В.",
        "body": "Об орбитах в C^4 разложимых алгебр Ли",
        "organization": "ВГУ",
        "from": "Воронеж"
    },
    {
        "name": "Хакимов А.Г.",
        "body": "К статической устойчивости формы поперечного сечения трубопровода, цилиндрической оболочки, углеродной нанотрубки",
        "organization": "ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Филиппов Г.А.",
        "body": "О моделировании процесса производства двукомпонентных строительных материалов",
        "organization": "НИУ МГСУ",
        "from": "Москва"
    },
    {
        "name": "Сухинов А.И., Чистяков А.Е., Кузнецова И.Ю., Никитина А.В.",
        "body": "Применение регуляризованной разностной схемы для решения задачи гидродинамики",
        "organization": "ДГТУ; ЮФУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Привалов Л.Ю., Маякова С.А.",
        "body": "Оценка сходимости и устойчивости алгоритма гидравлического расчета разветвленных течений, основанного на применении графов",
        "organization": "ИМех УФИЦ РАН, УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Кризский В.Н., Викторов С.В., Лунтовская Я.А.",
        "body": "Об определении переходного сопротивления катодно-поляризуемого магистрального трубопровода по данным магнитометрии с учетом слоистости вмещающей среды",
        "organization": "СПГУ; СФ БашГУ",
        "from": "Санкт-Петербург; Стерлитамак"
    },
    {
        "name": "Каменских А.А., Панькова А.П.",
        "body": "3D моделирование напряженно-деформированного состояния сферической опорной части при сочетании горизонтальной и вертикальной нагрузок от мостового пролета",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Шагапов В.Ш., Рафикова Г.Р., Мамаева З.З.",
        "body": "Динамика распространения собственных колебаний столба жидкости в вертикальной скважине",
        "organization": "ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Ильина К.П., Васильев В.И.",
        "body": "Вычислительный эксперимент начально-краевой задачи для уравнения реакции-диффузии",
        "organization": "РНОМЦ ДЦМИ; СВФУ",
        "from": "Якутск"
    },
    {
        "name": "Васильева О.А.",
        "body": "Математическая модель одной химической реакции",
        "organization": "НИУ МГСУ",
        "from": "Москва"
    },
    {
        "name": "Белевцов Н.С., Лукащук С.Ю.",
        "body": "Моделирование однофазной фильтрации в среде с пространственной нелокальностью",
        "organization": "УГАТУ, ООО РН-БашНИПИнефть",
        "from": "Уфа"
    },
    {
        "name": "Арсланбекова С.А., Дик Е.Н., Зиянгирова С.А.",
        "body": "Особенности математической модели процесса сушки ячменя",
        "organization": "БашГАУ",
        "from": "Уфа"
    },
    {
        "name": "Oksogoeva I.P.",
        "body": "Mathematical modeling of plasma transport in a spiral magnetic field",
        "organization": "Peoples' Friendship University of Russia",
        "from": "Moscow"
    },
    {
        "name": "Ashimov Ye., Zhunussova Z.",
        "body": "Calculation of the minimum density of three circles of different sizes of a triangular packing",
        "organization": "Institute of mathematics and mathematical modeling",
        "from": "Almaty"
    },
    {
        "name": "Калинин А.В., Тюхтина А.А., Капкаев Н.В., Малов С.А.",
        "body": "Математические задачи атмосферного электричества",
        "organization": "ННГУ им. Н.И. Лобачевского",
        "from": "Нижний Новгород"
    },
    {
        "name": "Dusmatov O.M., Khodjabekov M.U.",
        "body": "Mathematical modeling of longitudinal vibrations of the vibroprotected BEAM",
        "organization": "СамГУ, СамГАСИ",
        "from": "Самарканд"
    },
    {
        "name": "Кожевникова Л.М., Кашникова А.П.",
        "body": "Эквивалентность энтропийных и ренормализованных решений",
        "organization": "нелинейной эллиптической задачи в неограниченных областях",
        "from": "СФ БашГУ"
    },
    {
        "name": "Сулейманов Б.И.",
        "body": "Симметрийное решение уравнения Бюргерса с асимптотикой, описываемой корнем кубического уравнения обратной сборки",
        "organization": "ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Сучкова Д.А.",
        "body": "О стохастическом уравнении Кортевега - де Фриза",
        "organization": "УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Эшбеков Р.Х., Нормуродов Х.Н., Алланазарова Т.Ж.",
        "body": "Задачи Коши для комплексного модифицированного уравнения Кортевега-де Фриза в классе периодических бесконечнозонных функций",
        "organization": "Самаркандский государственный университет, Каракалпакский государственный университет",
        "from": "Самаканд, Нукус"
    },
    {
        "name": "Калинин А.В., Тюхтина А.А., Бусалов А.А.",
        "body": "Начально-краевая задача для нелинейной системы теории переноса излучения в плоско-параллельном слое",
        "organization": "ННГУ им. Н.И. Лобачевского",
        "from": "Нижний Новгород"
    },
    {
        "name": "Бурлаков Е.О., Мальков И.Н.",
        "body": "О кольцевых решениях уравнений нейронного поля с микроструктурой",
        "organization": "ТюмГУ",
        "from": "Тюмень"
    },
    {
        "name": "Ботороева М.Н., Будникова О.С., Орлов С.С.",
        "body": "Безытерационные методы численного решения нелинейных интегральных уравнений Вольтерра I рода",
        "organization": "ИГУ",
        "from": "Иркутск"
    },
    {
        "name": "Баринова М.К., Шустова Е.К.",
        "body": "Динамические свойства прямых произведений дискретных динамических систем",
        "organization": "НИУ ВШЭ",
        "from": "Нижний Новгород"
    },
    {
        "name": "Баранов Д.А., Ноздринова Е.В., Починка О.В.",
        "body": "Об устойчивой изотопности градиентно-подобных диффеоморфизмов тора",
        "organization": "НИУ ВШЭ",
        "from": "Нижний Новгород"
    },
    {
        "name": "Валеев Н.Ф., Назирова Э.А., Султанаев Я.Т.",
        "body": "Новый метод построения асимптотики решений уравнения Штурма-Лиувилля с осциллирующим потенциалом",
        "organization": "ИМсВЦ УФИЦ РАН, БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Савин АЮ.",
        "body": "Регуляризованные следы операторов в R^n, ассоциированных с метаплектической группой",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Абузярова Н.Ф.",
        "body": "О развитии теоремы Карлемана",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Бутерин С.А.",
        "body": "Функционально-дифференциальные операторы на геометрических графах с глобальным запаздыванием",
        "organization": "Саратовский университет,",
        "from": "Саратов"
    },
    {
        "name": "Ремизов А.Л., Коледин С.Н.",
        "body": "Моделирование электрического поля в разведочной скважине",
        "organization": "УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Масаева О.Х.",
        "body": "Функция Грина краевой задачи для обобщенного уравнения Лапласа",
        "organization": "ИПМА",
        "from": "Нальчик"
    },
    {
        "name": "Брайчев Г.Г.",
        "body": "Задача Сильвестра и теоремы единственности для целых функций",
        "organization": "МПГУ",
        "from": "Москва"
    },
    {
        "name": "Бакир А.Э., Повещенко Ю.А., Подрыга В.О., Рагимли П.И.",
        "body": "Математическое моделирование газогидратной флюидодинамики в пористой среде с учетом льда и соли",
        "organization": "МФТИ, ИПМ им. М.В. Келдыша РАН",
        "from": "Москва"
    },
    {
        "name": "Шерстюков В.Б.",
        "body": "Об асимптотическом поведении экстремального типа целой",
        "organization": "функции с корнями на луче",
        "from": "МГУ им. М.В. Ломоносова"
    },
    {
        "name": "Федоров В.Е.",
        "body": "Нелокальная краевая задача для уравнения смешанно-составного типа",
        "organization": "СВФУ",
        "from": "Якутск"
    },
    {
        "name": "Жаворонков Ю.А., Ульянов С.В., Вальков А.Ю., Кузьмин В.Л.",
        "body": "Исследование многослойной биоткани на основе моделирования обратного рассеяния инфракрасного излучения",
        "organization": "СПбГУ",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Хуснуллин Ш.Р., Коледина К.Ф., Алимбекова С.Р., Ишмуратов Ф.Г.",
        "body": "Машинное обучение в анализе влияния частоты электромагнитного поля при различных скоростях потока раствора на скорость коррозии",
        "organization": "УГНТУ, УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Носов Ю.О., Каменских А.А.",
        "body": "Идентификация термомеханических свойств полимерных и смазочных материалов",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Александрова Е.А., Шамматова А.А., Башмаков Р.А.",
        "body": "Приближенное решение задачи о связи между давлением и дебитом нефтяной скважины при наличии трещины ГРП",
        "organization": "БашГУ, ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Астафьева П.Ю., Киселев О.М.",
        "body": "Subresonance growth of the oscillation amplitude",
        "organization": "УГНТУ, ИМсВЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Акманова С.В.",
        "body": "Анализ устойчивости гибридной системы с непрерывной и дискретными подсистемами и ограничением на переключение сигналов",
        "organization": "МГТУ им. Г.И. Носова",
        "from": "Магнитогорск"
    },
    {
        "name": "Хамдамов Ш.Дж., Муродов К.Н.",
        "body": "Точные значения поперечников некоторых классов функций в l_{2,\\nu}",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Кузнецова И.Ю., Сухинов А.И., Чистяков А.И.,",
        "body": "Никитина А.В., Белова Ю.В.",
        "organization": "Метод решения сеточных уравнений для расчета давления для областей с \"вытянутой\" геометрией\" ",
        "from": "ДГТУ, ЮФУ"
    },
    {
        "name": "Хасанов Т.Г., Хоитметов У.А.",
        "body": "Интегрирование нагруженного уравнения Кортевега-де Фриза с источником в случае движущихся собственных значений",
        "organization": "УрГУ",
        "from": "Екатеринбург"
    },
    {
        "name": "Эрмаматова З.Э., Сатторов Э.Н.",
        "body": "Численные методы решения задачи Коши для уравнения Гельмгольца",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Семёнова Д.Г., Коледина К.Ф.",
        "body": "Хранение и анализ данных для процесса компаундирования при производстве товарных бензинов",
        "organization": "ИМех УФИЦ РАН, УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Коледина К.Ф.",
        "body": "Моделирование оптимальных условий проведения каталитической реакции",
        "organization": "ИМех УФИЦ РАН, УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Турсунов Ф.Р.",
        "body": "Задача Коши для линейных эллиптических систем первого порядка",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Ходжабеков М.У.",
        "body": "Analysis of mode shapes for the system of differential equations of motion of beam and liquid section dynamic absorber",
        "organization": "СФ ТГЭУ",
        "from": "Самарканд"
    },
    {
        "name": "Masharipov S.",
        "body": "Invariant of multiplication nonlinear operators",
        "organization": "National University of Uzbekistan",
        "from": "Tashkent"
    },
    {
        "name": "Усманов А.Р., Модестов В.С.",
        "body": "Определение случайного прогиба ротора в работе с применением метода сил",
        "organization": "СПбПУ",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Белова Ю.В., Чистяков А.Е.",
        "body": "Решение задачи транспорта тепла и солей в Азовском море на оптимальных гранично-адаптивных сетках",
        "organization": "ДГТУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Федотов А.А.",
        "body": "Минимальные решения разностных уравнений и теория почти периодических операторов",
        "organization": "СПбГУ",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Шумилова В.В.",
        "body": "Спектр собственных частот одномерных колебаний слоистых упругих композитов",
        "organization": "Института проблем механики им. А.Ю. Ишлинского РАН",
        "from": "Москва"
    },
    {
        "name": "Самсонов К.Ю., Екомасов Е.Г., Фахретдинов М.И., Муртазин Р.Р, Назаров В.Н.",
        "body": "Динамика нелинейных волн, описываемые уравнением Клейна-Гордона, в модели с протяжёнными примесями",
        "organization": "ТюмГУ, БашГУ",
        "from": "Тюмень, Уфа"
    },
    {
        "name": "Вильданова В.Ф.",
        "body": "Об энтропийном решении задачи Дирихле для уравнения с сингулярным потенциалом в гиперболическом пространстве",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Шамсудинов Ф.М., - Валиев Р.С.",
        "body": " Об одной переопределенной системе дифференциальных уравнений второго порядка с двумя внутренными сверхсингулярными линиями",
        "organization": "Бохтарский государственный университет",
        "from": "Бохтар"
    },
    {
        "name": "Сафаров Д.С.",
        "body": "Точное двоякопериодическое решение одной нелинейной эллиптической системы уравнений второго порядка с демпфированными членами",
        "organization": "Бохтарский государственный университет",
        "from": "Бохтар"
    },
    {
        "name": "Исаев К.П., Луценко А.В., Юлмухаметов Р.С.",
        "body": "Безусловные базисы из воспроизводящих ядер в нерадиальных пространствах Фока",
        "organization": "БашГУ, ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Исаев К.П., Путинцева А.А., Юлмухаметов Р.С.",
        "body": "Целые функции типа синуса для выпуклых бесконечноугольников",
        "organization": "БашГУ, ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Туйчиев А.М.",
        "body": "Среднеквадратическое приближении функций суммами Фурье-Чебышева",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Посадский А.Ф. ",
        "body": "CONFORMAL MAPPING ONTO A POLYGON WITH SEVERAL CUTS",
        "organization": "Московский физико-технический институт",
        "from": "Москва"
    },
    {
        "name": "Туров М.М. ",
        "body": "Квазилинейные уравнения с несколькими дробными производными в линейной части",
        "organization": "Челябинский государственный университет",
        "from": "Челябинск"
    },
    {
        "name": "Hannachi Marwa ",
        "body": "Modelling an earthquake using a bi-nonlinear Volterra integral equation",
        "organization": "Laboratoire de Mathématiques Appliquées et de Modélisation , University 8 mai 1945 , Guelma",
        "from": "Algeria"
    },
    {
        "name": "Валеев Н.Ф. ",
        "body": "Задача оптимизации для первого собственного значения матричного оператора Штурма-Лиувилля",
        "organization": "ИМ ВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Собиров Ш.К. ",
        "body": "О задаче Коши для уравнения мКдФ с дополнительным членом и самосогласованным источником",
        "organization": "Ургенчский государственный университет",
        "from": "Ургенч"
    },
    {
        "name": "Галимнуров А.Ф. ",
        "body": "Двойственное представление функционалов на проективных пределах векторных решеток",
        "organization": "Башкирский государственный университет",
        "from": "Уфа"
    },
    {
        "name": "Адхамова А.Ш. ",
        "body": "Задача Красовского об успокоении многомерной нестационарной системы управления с последействием запаздывающего типа",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Старцев С.Я. ",
        "body": "Об одном классе квазиобратимых преобразований Бэклунда для гиперболических уравнений",
        "organization": "Институт математики с ВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Ишкин Х.К., Марванов Р.И.",
        "body": "Об аналитических возмущениях несамосопряженного ангармонического осциллятора",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Насибуллин Р.Г.",
        "body": "Одномерные неравенства Харди для весовой функции Якоби",
        "organization": "КФУ",
        "from": "Казань"
    },
    {
        "name": "Эргашбоев Т., Воситова Д.А., Зоидова М.И.",
        "body": "Построения обыкновенного дифференциального уравнения, инвариантного относительно группы Лоренца",
        "organization": "ХГУ",
        "from": "Худжанд"
    },
    {
        "name": "Муканов А.Б.",
        "body": "О теореме Лоренца",
        "organization": "КФ МГУ, ИМММ",
        "from": "Алматы, Нур-Султан"
    },
    {
        "name": "Dosmagulova K.",
        "body": "Solvability of the Laplace-Beltrami operator on a two-dimensional sphere",
        "organization": "Al-Farabi Kazakh National University",
        "from": "Almaty"
    },
    {
        "name": "Шабалин П.Л., Фаизов Р.Р.",
        "body": "Задача Римана на луче для обобщенных ????????",
        "organization": "КГАСУ",
        "from": "Казань"
    },
    {
        "name": "Садриева Р.Т., Зеркина А.В., Муртазина Р.Д., Сидельникова Н.А., Черданцев И.Ю.",
        "body": "Исследование решения одномерного радиального уравнения на бесконечности",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Сухинов А.И., Чистяков А.Е., Кузнецова И.Ю., Литвинов В.Н.",
        "body": "Метод решения сеточных уравнений для расчета давления для областей с \"вытянутой\" геометрией\" ",
        "organization": "ДГТУ, ЮФУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Юмагулов М.Г., Габдрахманов Р.И.",
        "body": "О бифуркации двумерного тора в модели Лэнгфорда",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Петров Н.Н., Мачтакова А.И.",
        "body": "Об одной задаче группового преследования с дробными производными",
        "organization": "УдГУ, ИММ УрО РАН",
        "from": "Ижевск, Екатеринбург"
    },
    {
        "name": "Федоров В.Е.",
        "body": "Один класс нелинейных обратных задач для уравнений с дробными производными",
        "organization": "ЧелГУ",
        "from": "Челябинск"
    },
    {
        "name": "Лийко В.В.",
        "body": "О гладкости обобщенных решений смешанных краевых задач для эллиптических дифференциально-разностных уравнений в произвольной ограниченной области",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Абдуллаев О., Холжигитов С., Бутаев Р.",
        "body": "Смешанный метод конечных элементов для решения задач теории упругости",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Мардиев Р.",
        "body": "Об односторонней обратимости функциональных операторов со сдвигом",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Sakhno A., Lebedev S.",
        "body": "Adaptation of satellite altimetry data for their assimilation in numerical models of the dynamics of the Black Sea",
        "organization": "MIPT University",
        "from": "Moscow"
    },
    {
        "name": "Поркшеян М.В., Рахимбаева Е.О.",
        "body": "Декомпозиция типового проекта в области математического моделирования: общий случай",
        "organization": "ДГТУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Никитина А.В., Филина А.А.",
        "body": "Моделирование развития биологических сообществ зоопланктона мелководного водоема при его загрязнении частицами нано- и микропластика",
        "organization": "ДГТУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Ибрагимова Л.С., Белова А.С.",
        "body": "О сильной и слабой устойчивости автономных и периодических гамильтоновых систем",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Еникеева Л.В., Язовцева О.С.",
        "body": "Устойчивость и корректность задач химической кинетики",
        "organization": "УГНТУ, ИНК УФИЦ РАН, Национальный исследовательский Мордовский государственный университет",
        "from": "Уфа, Саранск"
    },
    {
        "name": "Абушахмина Г.Р.",
        "body": "Бифуркации в  распределенных моделях, описывающих реакцию Белоусова-Жаботинского",
        "organization": "БГМУ",
        "from": "Уфа"
    },
    {
        "name": "Nursultanov E.D., Kopezhanova A.N.",
        "body": "Interpolation theorem for anisotropic Lorentz spaces",
        "organization": "L.N.Gumilyov Eurasian National University",
        "from": "Nur-Sultan"
    },
    {
        "name": "Фазлытдинов М.Ф.",
        "body": "Вопрос об устойчивости бифурцирующих точек равновесия в седло-узловой бифуркации",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Фазуллин З.Ю.",
        "body": "Формула регуляризованного следа оператора Лапласа на квадрате",
        "organization": "БашГУ",
        "from": "Уфа"
    }


]

  function throttle(func, ms) {

	let isThrottled = false,
	  savedArgs,
	  savedThis;
  
	function wrapper() {
  
	  if (isThrottled) { // (2)
		savedArgs = arguments;
		savedThis = this;
		return;
	  }
  
	  func.apply(this, arguments); // (1)
  
	  isThrottled = true;
  
	  setTimeout(function() {
		isThrottled = false; // (3)
		if (savedArgs) {
		  wrapper.apply(savedThis, savedArgs);
		  savedArgs = savedThis = null;
		}
	  }, ms);
	}
  
	return wrapper;
  }
export default function Members() {
	const classes = useStyles();
	const [members, setMembers] = useState(initialState);
	const filterMember = (e) => {
		let variant;
		e.currentTarget.id === 'search-name' ? (variant = 'name') : (variant = 'body');

		const value = e.target.value;
		let copy = [...initialState];
		variant === 'name'
			? setMembers(copy.filter((el) => el.name.search(new RegExp(value, 'ig')) !== -1))
			: setMembers(copy.filter((el) => el.body.search(new RegExp(value, 'ig')) !== -1));
	};
	return (
		<Container maxWidth='lg' className={classes.container}>
			<Paper className={classes.paper}>
				<Box p={4} className={classes.heading}>
					<Typography variant='h3' className={classes.title}>
						Принятые заявки: {initialState.length}
					</Typography>
					<Box className={classes.inputs}>
						<TextField
							id='search-name'
							size='small'
							fullWidth
							onChange={throttle(filterMember, 400)}
							label='Поиск по фамилии'
						/>
						<TextField
							id='search-body'
							size='small'
							fullWidth
							onChange={filterMember}
							label='Поиск по названию'
						/>
					</Box>
				</Box>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>№</TableCell>
								<TableCell align='center'>ФИО</TableCell>
								<TableCell align='center'>Название доклада</TableCell>
								<TableCell align='center'>Организация</TableCell>
								<TableCell align='center'>Город</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{members.map((row, index) => (
								<TableRow key={row.name + ' ' + row.body}>
									<TableCell component='th' scope='row'>
										{index + 1}
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.body}</TableCell>
									<TableCell>{row.organization}</TableCell>
									<TableCell>{row.from}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
}
