import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { throttle } from '../../../helpers/utils';
import { data23 } from './data23';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {
			padding: 15,
			marginTop: 10,
		},
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			padding: 4,
			marginTop: 10,
			flexDirection: 'column',
		},
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	title: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.9rem',
		},
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		width: '260px',
	},
}));

const initialState = data23

export default function Members() {
	const classes = useStyles();
	const [members, setMembers] = useState(initialState);
	const filterMember = (e) => {
		let variant;
		e.currentTarget.id === 'search-name' ? (variant = 'name') : (variant = 'body');

		const value = e.target.value;
		let copy = [...initialState];
		variant === 'name'
			? setMembers(copy.filter((el) => el.name.search(new RegExp(value, 'ig')) !== -1))
			: setMembers(copy.filter((el) => el.body.search(new RegExp(value, 'ig')) !== -1));
	};

	return (
		<Container maxWidth='lg' className={classes.container}>
			<Paper className={classes.paper}>
				<Box p={4} className={classes.heading}>
					<Typography variant='h3' className={classes.title}>
						Архив принятых заявок: {initialState.length}
					</Typography>
					<Box className={classes.inputs}>
						<TextField
							id='search-name'
							size='small'
							fullWidth
							onChange={throttle(filterMember, 400)}
							label='Поиск по фамилии'
						/>
						<TextField
							id='search-body'
							size='small'
							fullWidth
							onChange={filterMember}
							label='Поиск по названию'
						/>
					</Box>
				</Box>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>№</TableCell>
								<TableCell align='center'>ФИО</TableCell>
								<TableCell align='center'>Название доклада</TableCell>
								<TableCell align='center'>Организация, город</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{members.map((row, index) => (
								<TableRow key={row.name + ' ' + row.body}>
									<TableCell component='th' scope='row'>
										{index + 1}
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.body}</TableCell>
									<TableCell>{row.from}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
}
