import React, { useState } from 'react';

import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 320;

function Layout({ children }) {
	const useStyles = makeStyles(theme => ({
		root: {
			display: 'flex'
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0
			}
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth
			}
		},
		
		// necessary for content to be below app bar
		toolbar: theme.mixins.toolbar,
		drawerPaper: {
			width: drawerWidth
		},
		content: {
			flexGrow: 1,
			marginTop: '64px'					
		}
	}));
	const [sideIsOpen, setSideIsOpen] = useState(false);
	const classes = useStyles();
	const toggleHandler = () => {
		setSideIsOpen(!sideIsOpen);
	};
	const onCloseSidebar = () => {
		setSideIsOpen(false)
	}
	return (
		<div className={classes.root}>
			<Navbar
				sideHandler={toggleHandler}
				// className={classes.appBar}
				
				// logout={signOut}
				
			/>
			<nav className={classes.drawer} aria-label='mailbox folders'>
				<Sidebar					
					sideHandler={toggleHandler}
					classes={{
						paper: classes.drawerPaper
					}}
					isOpen={sideIsOpen}
					onCloseSidebar={onCloseSidebar}
				/>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar}>{children}</div>
			</main>
		</div>
	);
}



export default Layout;
