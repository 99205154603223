import React from 'react';
import LayoutPage from '../../hoc/LayoutPage/LayoutPage';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function ZoomLinks() {
	return (
		<LayoutPage>
			<div>
				{/* <Box fontWeight={800}>
					<Typography variant='h3' align='center' paragraph component='h1'>
					Видиотека
					</Typography>					
				</Box>
				<Box >
					<Typography>
					Пленарные и секционные доклады конференции УОМШ-21 выложены на youtube-канале ФМиИТ БашГУ 
					<Typography variant='body1'>Ссылка на youtube-канал: <a href="https://youtu.be/PczdWvOwtqQ">https://youtu.be/PczdWvOwtqQ</a></Typography>					
					</Typography>					
				</Box> */}
				{/* <Box fontWeight={800}>
					<Typography variant='h3' align='center' paragraph component='h1'>
					Формат проведения конференции
					</Typography>					
				</Box>
				<Box >
					<Typography></Typography>
					Конференция проводится в гибридном формате: для тех, кто сможет
приехать в Уфу, конференция пройдет в классическом формате (очно), а тем,
кто не сможет приехать, предлагается провести дистанционную презентацию
в Zoom. Все пленарные и секционные заседания будут транслироваться в
соответствующих Zoom-конференциях.
					</Typography>					
				</Box>

				<Box>
					<Typography paragraph component='h4'>
						Ссылки на Zoom-конференции:
					</Typography>
					<Typography align='center' paragraph style={{fontWeight: 800}}>
					Пленарные доклады
					</Typography>
					<div>
					<Typography variant='body1'>Подключиться к конференции Zoom</Typography>
					<Typography variant='body1'><a href="https://us02web.zoom.us/j/86763639817?pwd=eC9LajlmdWRsbVdpdzFTb3p1RUI3QT09">https://us02web.zoom.us/j/86763639817?pwd=eC9LajlmdWRsbVdpdzFTb3p1RUI3QT09</a></Typography>					
					<Typography variant='body1'>Идентификатор конференции: 867 6363 9817</Typography>
					<Typography variant='body1'>Код доступа: 111</Typography>
					</div>
					<Typography align='center' paragraph style={{fontWeight: 800}}>Секции</Typography>
					<div style={{display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
					<div>
					<Typography variant='h6' style={{fontWeight: 800}}>1. Спектральная теория операторов</Typography>			
					<Typography variant='body1'>Подключиться к конференции Zoom</Typography>
					<Typography variant='body1'><a href="https://us02web.zoom.us/j/84832485754?pwd=ZklmQkI1VFhOYWRPMWtwd2pFVGVxQT09">https://us02web.zoom.us/j/84832485754?pwd=ZklmQkI1VFhOYWRPMWtwd2pFVGVxQT09</a></Typography>					
					<Typography variant='body1'>Идентификатор конференции: 848 3248 5754</Typography>
					<Typography variant='body1'>Код доступа: 698918</Typography>	
					</div>			
					<div>
					<Typography variant='h6' style={{fontWeight: 800}}>2. Комплексный и функциональный анализ</Typography>			
					<Typography variant='body1'>Подключиться к конференции Zoom</Typography>
					<Typography variant='body1'><a href="https://us02web.zoom.us/j/84764418720?pwd=ZktPbGpzbjFnMm5YQjBhbk9mSWRDUT09">https://us02web.zoom.us/j/84764418720?pwd=ZktPbGpzbjFnMm5YQjBhbk9mSWRDUT09</a></Typography>					
					<Typography variant='body1'>Идентификатор конференции: 847 6441 8720</Typography>
					<Typography variant='body1'>Код доступа: 111</Typography>					

					</div>
					<div>
					<Typography variant='h6' style={{fontWeight: 800}}>3. Нелинейные уравнения</Typography>			
					<Typography variant='body1'>Подключиться к конференции Zoom</Typography>
					<Typography variant='body1'><a href="https://us02web.zoom.us/j/83996550548?pwd=djVqRGJENGJaOGY3M3RrMCtTME85QT09">https://us02web.zoom.us/j/83996550548?pwd=djVqRGJENGJaOGY3M3RrMCtTME85QT09</a></Typography>					
					<Typography variant='body1'>Идентификатор конференции: 839 9655 0548</Typography>
					<Typography variant='body1'>Код доступа: 111</Typography>					

					</div>
					<div>
					<Typography variant='h6' style={{fontWeight: 800}}>4. Дифференциальные уравнения и их приложения</Typography>			
					<Typography variant='body1'>Подключиться к конференции Zoom</Typography>
					<Typography variant='body1'><a href="https://us02web.zoom.us/j/89895598827?pwd=ZDE1VEN6cmRVRVdyM1V6elN4cjFjdz09">https://us02web.zoom.us/j/89895598827?pwd=ZDE1VEN6cmRVRVdyM1V6elN4cjFjdz09</a></Typography>					
					<Typography variant='body1'>Идентификатор конференции: 898 9559 8827</Typography>
					<Typography variant='body1'>Код доступа: 111</Typography>					

					</div>
						<div>
					<Typography variant='h6' style={{fontWeight: 800}}>5. Математическое моделирование</Typography>			
					<Typography variant='body1'>Подключиться к конференции Zoom</Typography>
					<Typography variant='body1'><a href="https://us02web.zoom.us/j/85610830703?pwd=QjR0TjhPVTF4RG9sWGRmaGFaV1pUdz09">https://us02web.zoom.us/j/85610830703?pwd=QjR0TjhPVTF4RG9sWGRmaGFaV1pUdz09</a></Typography>					
					<Typography variant='body1'>Идентификатор конференции: 856 1083 0703</Typography>
					<Typography variant='body1'>Код доступа: 111</Typography>					
							</div>							
						</div>			
				</Box> */}
			</div>
		</LayoutPage>
	);
}

export default ZoomLinks;
