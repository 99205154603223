import doclad1 from '../../assets/doclads/doclad1.pdf'
import doclad2 from '../../assets/doclads/doclad2.pdf'
import doclad3 from '../../assets/doclads/doclad3.pdf'
import doclad4 from '../../assets/doclads/doclad4.pdf'
import doclad5 from '../../assets/doclads/doclad5.pdf'
import doclad6 from '../../assets/doclads/doclad6.pdf'
import doclad7 from '../../assets/doclads/doclad7.pdf'
import doclad8 from '../../assets/doclads/doclad8.pdf'
import doclad9 from '../../assets/doclads/doclad9.pdf'
import doclad10 from '../../assets/doclads/doclad10.pdf'
import doclad11 from '../../assets/doclads/doclad11.pdf'
import doclad12 from '../../assets/doclads/doclad12.pdf'
import doclad13 from '../../assets/doclads/doclad13.pdf'
import doclad14 from '../../assets/doclads/doclad14.pdf'
import doclad15 from '../../assets/doclads/doclad15.pdf'
// import doclad16 from '../../assets/doclads/doclad16.pdf'
import doclad17 from '../../assets/doclads/doclad17.pdf'
import React from 'react'

const Doclad2020 = () => {
  return (
    <ul>                   
                    <li><a href={doclad1}>Болотнова Р.Х., Гайнуллина Э.Ф., Коробчинская В.А., Файзуллина Э.А. (ИМех УФИЦ РАН, г. Уфа) Моделирование пространственных динамических процессов в водных пенах и вскипающих струях</a></li>
                    <li><a href={doclad2}>Бравый Е.И. (ПНИПУ, г. Пермь) Об условиях разрешимости периодической краевой задачи для гибридной системы  двух линейных  функционально-дифференциальных уравнений с поточечными ограничениями на функциональны операторы</a></li>
                    <li><a href={doclad3}>Булатова А.З., Солнышкина О.А., Фаткуллина Н.Б. (БашГУ, г. Уфа) Трехмерное моделирование динамики одиночных пузырьков в треугольном микроканале</a></li>
                    <li><a href={doclad4}>Доломатов М.Ю., Коледин О.С., Ахтямова К.Р. (УГНТУ, г. Уфа) Прогнозирование температур вспышек углеводородов методом QSPR</a></li>
                    <li><a href={doclad5}>Ермоленко И.П., Михайлова В.А., Иванов А.И. (ВолГУ, г. Волгоград) Моделирование нестационарных спектров флуоресценции молекул в полярных растворителях</a></li>
                    <li><a href={doclad6}>Конечная Н.Н. (САФУ имени М.В. Ломоносова, г. Архангельск) Главный член асимптотики решений  дифференциальных  уравнений четвертого порядка с негладкими коэффициентами</a></li>
                    <li><a href={doclad7}>Курбанова Д.Р., Муртазаев А.К., Рамазанов М.К., Магомедов М.А. (Институт физики им. Х.И. Амирханова ДФИЦ РАН, г. Махачкала) Компьютерное моделирование критического поведения фрустрированной модели Поттса</a></li>
                    <li><a href={doclad8}>Меньшикова Э.Б. (БашГУ, г. Уфа) Ограничения на распределение меры Рисса субгармонической функции в области</a></li>
                    <li><a href={doclad9}>Муртазаев К.Ш., Муртазаев А.К., Рамазанов М.К., Магомедов М.А (Институт физики ДФИЦ РАН, г. Махачкала) Исследование фазовых переходов в фрустрированной модели Изинга с магнитным полем</a></li>
                    <li><a href={doclad10}>Навроцкая Е.В.  Смирнов Д.А.  Безручко Б.П.  Разработка метода восстановления стркуктуры связей в системе из трех осцилляторов с помощью моделирование фазовой динамики</a></li>
                    <li><a href={doclad11}>Самсонов К.Ю. (ТюмГУ, г. Тюмень), Гумеров А.М., Кудрявцев Р.В. (БашГУ, г. Уфа), Екомасов Е.Г. (ТюмГУ, г. Тюмень; БашГУ, г. Уфа) Описание динамики нелинейных волн</a></li>
                    <li><a href={doclad12}>Сафаров Д.С., Миратов С.К. (БохГУ, г. Бохтар) Решение уравнения Дуффинга с отклоняющимся аргументом</a></li>
                    <li><a href={doclad13}>Ситдикова Л.Ф. (УГНТУ, г. Уфа) Задача о распространении фильтрационных волн в пористой среде, насыщенной пузырьковой жидкостью</a></li>
                    <li><a href={doclad14}>Степанов С.В., Екомасов А.Е., Антонов Г.И. (БашГУ, г. Уфа) Динамика и трансформация вихрей в проводящих наностолбчатых магнитных структурах</a></li>
                    <li><a href={doclad15}>Шамсудинов Ф.М., Ханимкулов А.С. Интегральные представления решений для одной переопределенной системы дифференциальных уравнений второго порядка c особыми коэффициентами</a></li>
                    {/* <li><a href={doclad16}>Шамсудинов Ф.М., Хомиддин С.  Об одной переопределенной системе дифференциальных уравнений второго порядка с сингулярными коэффициентами</a></li> */}
                    <li><a href={doclad17}>Шварева Е.Н. (УГНТУ, г. Уфа), Еникеева Л.В. (НГУ, г. Новосибирск) Алгоритмы поиска гармонии и электромагнетизма в оптимизационных задачах</a></li>
                </ul> 
  )
}

export default Doclad2020