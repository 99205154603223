import React from 'react'
import Typography from '@material-ui/core/Typography'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'

const Contact = () => {
    return (
        <LayoutPage headling='Контакты'>
            <>
                <Typography variant='body1' paragraph><strong>Контактные данные Оргкомитета:</strong> 450074, г. Уфа, ул. З.Валиди, 32а, УУНиТ, институт математики и информационных технологий.</Typography>
                <Typography variant='body1' paragraph><strong>Тел.:</strong><a href="(+7) 917-766-24-88">(+7) 917-766-24-88</a></Typography>
                <Typography variant='body1' paragraph><strong>Сайт:</strong><a href="https://conf-bashedu-fmit.ru/"> www.conf-bashedu-fmit.ru</a></Typography>
                <Typography variant='body1' paragraph><strong>e-mail:</strong><a href="ufa.mat.sh@mail.ruu"> ufa.mat.sh@mail.ruu</a> </Typography>

            </>
        </LayoutPage>
    )
}

export default Contact
