import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {
			padding: 15,
			marginTop: 10,
		},
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			padding: 4,
			marginTop: 10,
			flexDirection: 'column',
		},
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	title: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.9rem',
		},
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		width: '260px',
	},
}));

const initialState = [
    {
        "name": "Alfimov G., Slobodyanyuk A.O., Zezyulin D.A.",
        "body": "NLS equation with complex potential: modes and pseudo-modes",
        "organization": "MIET University",
        "from": "Москва"
    },
    {
        "name": "Ashimov Y.",
        "body": "Applying probability theory to neural networks",
        "organization": "Al-Farabi Kazakh National University",
        "from": "Almaty"
    },
    {
        "name": "Babajanov B.A., Babadjanova  A.K.,  Azamatov A.Sh.",
        "body": "Integration of the discrete sine-Gordon equation with a self-consistent source",
        "organization": "Urgench State University",
        "from": "Urgench"
    },
    {
        "name": "Bekjan T.",
        "body": "Young type inequalities of measurable operators",
        "organization": "L. N. Gumilyov Eurasian National University",
        "from": "Астана"
    },
    {
        "name": "Bekjan T., Raikhan M.",
        "body": "Dual space of noncommutative weak Orlicz-Hardy space",
        "organization": "L. N. Gumilyov Eurasian National University",
        "from": "Астана"
    },
    {
        "name": "Bolikulov F.M., Niyozov I.E.",
        "body": "The Cauchy problem for the system of  elasticity",
        "organization": "Samarkand State University",
        "from": "Самарканд"
    },
    {
        "name": "Djaghout M., Chaoui A., Labadla A.",
        "body": "An optimal error estimates of rothe-finite element method for nonlocal evolution equation",
        "organization": "University 8 May 1945 Guelma",
        "from": "Гельма"
    },
    {
        "name": "Dosmagulova K.",
        "body": "Spectral analysis of Laplace-Beltrami operator on Riemannian manifolds",
        "organization": "Al-Farabi Kazakh National University",
        "from": "Almaty"
    },
    {
        "name": "Kakharman N.",
        "body": "Mixed Cauchy boundary value problem for noncharacteristic degenerate hyperbolic equations",
        "organization": "Institute of Mathematics and Mathematical Modeling",
        "from": "Almaty"
    },
    {
        "name": "Klevtsova Yu.Yu. .",
        "body": "On the different size of the white noise perturbation in the problem on the inviscid limit of the stationary measure  for\none model of the atmosphere",
        "organization": "Federal State Budgetary Institution",
        "from": "Novosibirsk"
    },
    {
        "name": "Litvinov V., Litvinova V. K.",
        "body": "Mathematical modeling of nonlinear vibrations of a rope with a moving boundary",
        "organization": "Head of Dept., Dept. of General–Theoretical Disciplines,",
        "from": " "
    },
    {
        "name": "Абанин А.В.",
        "body": "Линейно связные компоненты множества (весовых) композиционных операторов в пространствах Бергмана",
        "organization": "ЮМИ ВНЦ РАН, ЮФУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Абдурагимов Г.Э.",
        "body": "О существовании положительного решения краевой задачи для одного нелинейного функционально-дифференциального уравнения второго порядка",
        "organization": "ДГУ",
        "from": "Махачкала"
    },
    {
        "name": "Абузярова Н.Ф.",
        "body": "Оператор дифференцирования в пространствах ультрадифференцируемых функций",
        "organization": "Башкирский государственный университет",
        "from": "Уфа"
    },
    {
        "name": "Абушахмина Г.Р.",
        "body": "О построении ляпуновских величин в задаче о бифуркации Андронова-Хопфа в системе ''реакция-диффузия''",
        "organization": "БГМУ",
        "from": "Уфа"
    },
    {
        "name": "Акманова С.В.",
        "body": "Асимптотическая устойчивость и бифуркации нелинейных непрерывно-дискретных динамических систем",
        "organization": "Магнитогорский государственный технический университет им. Г.И.Носова",
        "from": "Магнитогорск"
    },
    {
        "name": "Александрова А.А., Коледина К. Ф.",
        "body": "Многокритериальная оптимизация реакции синтеза бензилалкиловых эфиров на Python",
        "organization": "УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Алланазарова Т.Ж., Хасанов Т.Г., Искандаров А.У.",
        "body": "Интегрирование модифицированного уравнения Кортевега-де Фриза с нагруженными членами и интегральным источником в классе периодических функций",
        "organization": "Каракалпакский государственный университет имени Бердаха",
        "from": "Нукус"
    },
    {
        "name": "Аллахвердян А.А.",
        "body": "Произведение собственных функций и вронскианы",
        "organization": "Адыгейский государственный университет",
        "from": "Майкоп"
    },
    {
        "name": "Антипина Е.В., Мустафина С.А., Антипин А.Ф.",
        "body": "Поиск оптимальных условий протекания химической реакции на основе кинетической модели",
        "organization": "Стерлитамакский филиал БашГУ",
        "from": "Стерлитамак"
    },
    {
        "name": "Арабов М.К.",
        "body": "Анализ и сравнение поведений решений кусочно-нелинейных дифференциальных уравнений второго порядка",
        "organization": "Российский таджикский (Славянский) университет",
        "from": "Душанбе"
    },
    {
        "name": "Арефьев И.А., Еникеева Л.В., Агзамова М.Р.",
        "body": "Оптимизация процесса сернокислотного алкилирования изобутана олефинами путем разработки математической модели",
        "organization": "Институт нефтехимии и катализа УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Асташкин С.В.",
        "body": "О локальной $\\ell^p$-структуре симметричных пространств последовательностей фундаментального типа.",
        "organization": "Самарский национальный исследовательский университет",
        "from": "Самара"
    },
    {
        "name": "Ахмедов Д.Т., Нуров И.Д.",
        "body": "К вопросу о периодических решениях нелинейного дифференциального уравнения второго порядка",
        "organization": "ДНУ",
        "from": "Душанбе"
    },
    {
        "name": "Бабенко М.Г., Кучеров Н.Н.",
        "body": "Вычисления определителя зашифрованной квадратной матрицы",
        "organization": "ФГАОУ ВО «Северо-Кавказский федеральный университет»",
        "from": "Ставрополь"
    },
    {
        "name": "Байзаев С., Садиков М.О.",
        "body": "О решениях обобщенного уравнения бианалитичиских функций",
        "organization": "ТГУПБП",
        "from": "Худжанд"
    },
    {
        "name": "Баймурзаева А.Б., Кусаинова Л.К",
        "body": "О мультипликаторах в весовых пространствах потенциалов",
        "organization": "ЕНУ им. Л.Н. Гумилева",
        "from": "Нур-Султан"
    },
    {
        "name": "Башмаков Р.А., Шамматова А.А.",
        "body": "Применение метода ПССС для описания переходных режимов работы скважины при наличии трещины ГРП",
        "organization": "БашГУ (Уфа, Россия), ИМех УФИЦ РАН (Уфа, Россия)",
        "from": "Уфа"
    },
    {
        "name": "Бегматов А., Исмоилов А.С.",
        "body": "Слабо некорректные задачи интегральной геометрии на плоскости с возмущением",
        "organization": "Самаркандский государственный университет",
        "from": "Самарканд"
    },
    {
        "name": "Бобренёва Ю.О., Узянбаев Р.М., Губайдуллин И.М.",
        "body": "Численное моделирование пьезопроводных процессов двухфазной жидкости в трещиновато-поровом коллекторе",
        "organization": "УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Бондаренко Н.П.",
        "body": "Конструктивное решение обратной задачи для матричного оператора Штурма-Лиувилля",
        "organization": "Саратовский государственный университет, Самарский университет",
        "from": "Саратов"
    },
    {
        "name": "Борздыко В.И.",
        "body": "Об исследовании на асимптотическую устойчивость по показательному закону положительного стационарного решения системы <<хишник-жертва>> с запаздыванием,\nмодифицированной по схемам Лесли-Гоувера и принадлежащей к II типу Холлинга",
        "organization": "Институт математики им. А.Джураева НАНТ",
        "from": "Душанбе"
    },
    {
        "name": "Бострем И.Г., Екомасов Е.Г., Овчинников А.С., Синицын В.Е",
        "body": "Темные дискретные бризерные моды в моноаксиальном хиральном гелимагнетике с анизотропией типа легкая плоскость",
        "organization": "УрФУ",
        "from": "Екатеринбург"
    },
    {
        "name": "Брайчев Г.Г., Шерстюкова О.В.",
        "body": "Оценки типа целой функции с заданной подпоследовательностью нулей",
        "organization": "МПГУ",
        "from": "Москва"
    },
    {
        "name": "Бутерин С.А., Джурич Н.",
        "body": "О неединственности решения обратной задачи Штурма--Лиувилля с запаздыванием",
        "organization": "Саратовский государственный университет",
        "from": "Саратов"
    },
    {
        "name": "Валеев Н.Ф.",
        "body": "Оптимизационная обратная  спектральная задача для оператора Штурма-Лиувилля",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Валеев Н.Ф., Назирова Э.А., Султанаев Я.Т.",
        "body": "Контрпример теоремы Левинсона",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Варманаев Р.П., Хисаметдинова .Г.К.",
        "body": "Математическое моделирование процесса заболевания сахарным диабетом",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Вильданова В.Ф.",
        "body": "О задаче Коши для уравнения агрегации с переменным показателем нелинейности в гиперболическом пространстве",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Власов В.В.",
        "body": "Спектральный анализ вольтерровых интегро-дифференциальных уравнений",
        "organization": "МГУ имени М.В.Ломоносова",
        "from": "Москва"
    },
    {
        "name": "Выборный Е.В.",
        "body": "Расщепление уровней Ландау при резонансе в искривленном слое",
        "organization": "НИУ ВШЭ",
        "from": "Москва"
    },
    {
        "name": "Габдрахманова Н.Т., Пильгун М.А.",
        "body": " ",
        "organization": "Математический институт им.С.М. Никольского, РУДН",
        "from": "Москва"
    },
    {
        "name": "Гадоев М.Г., Исхоков Дж.С.",
        "body": "О базисности по Абелю системы корневых функций одного класса эллиптических операторов с несогласованным вырождением",
        "organization": "Политехнический институт (филиал) Северо-Восточного федерального университета им. М.К.Аммосова в г.Мирном",
        "from": "Мирный"
    },
    {
        "name": "Гайсин А.М., Гайсин Р.А.",
        "body": "Ряды квазиполиномов с положительными показателями и связь с интерполяцией",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Гайсин Р.А.",
        "body": "Критерий существования специальной регулярной мажоранты для последовательности класса E",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Гайсина Г.А.",
        "body": "Представление аналитических функций рядами экспонент в полуплоскости с учетом оценки целой составляющей",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Гизатуллин Р.Р., Пещеренко С.Н.",
        "body": "Неструктурированное течение жидкости в каналах магнитной муфты",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Гладков А.В., Бабенко М.Г., Кучеров Н.Н.",
        "body": "Модификация метода проекций для локализации ошибки в системе остаточных классов",
        "organization": "ФГАОУ ВО «Северо-Кавказский федеральный университет»",
        "from": "Ставрополь"
    },
    {
        "name": "Джамалов С.З., Туракулов Х.Ш.",
        "body": "Об одной нелокальной краевой задаче  для трехмерного уравнения Чаплыгина  в призматической неограниченной области",
        "organization": "Институт математики АН.Руз",
        "from": "Ташкент"
    },
    {
        "name": "Домрин А.В., Шумкин М.А.",
        "body": "Безмонодромные операторы и солитонные уравнения",
        "organization": "Московский Государственный Университет",
        "from": "Москва"
    },
    {
        "name": "Дубовцев Д.А., Еникеева Л.В.",
        "body": "Изучение изменения термодинамических параметров реактора синтеза МТБЭ на основе математической модели",
        "organization": "УФИЦ РАН ИНК",
        "from": "Уфа"
    },
    {
        "name": "Дышаев М.М., Федоров  В.Е.",
        "body": "Модификация уравнения Блэка-Шоулса для учета стоимости ликвидности и транзакционных издержек",
        "organization": "ЧелГУ",
        "from": "Челябинск"
    },
    {
        "name": "Еникеев Д.Г., Мустафина С.А.",
        "body": "Разработка системы распознавания русского дактильного языка",
        "organization": "СФ БашГУ",
        "from": "Стерлитамак"
    },
    {
        "name": "Жибер А.В., Кузнецова М.Н.",
        "body": "Условия интегрируемости полудискретных систем уравнений",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Загидуллин Ш.Г., Коледина К.Ф.",
        "body": "Расчет кинетических параметров процесса гидрирования полициклических ароматических углеводородов",
        "organization": "ИНК УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Зайнуллин Р.Г.",
        "body": "Нестационарная задача теплообмена для уравнения параболического типа в нецилиндрической област",
        "organization": "УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Иванов А.В., Харук Н.В.",
        "body": "Комбинации коэффициентов Сили--деВитта  и их свойства",
        "organization": "ПОМИ РАН",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Иванова О.А.",
        "body": "Умножение в пространствах аналитических функционалов и произведение Дюамеля",
        "organization": "ЮФУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Игнатьев М.Ю.",
        "body": "Характеризация данных рассеяния систем дифференциальных уравнений с особенностью",
        "organization": "Саратовский государственный университет",
        "from": "Саратов"
    },
    {
        "name": "Илолов М.И., Рахматов Д.Ш.",
        "body": "Об эквивалентности экспоненциальной дихотомии и устойчивости по Хайерсу-Улам линейных периодических дифференциальных уравнений в банаховом пространстве",
        "organization": "Центр инновационного развития науки и новых технологий АН  Респ. Таджикистан",
        "from": "Душанбе"
    },
    {
        "name": "Имомкулов С.А., Собиров У.М",
        "body": "Некоторое обобщение  основной леммы  Хартогса об аналитическом  продолжении функций многих комплексных переменных",
        "organization": "Институт математики имени В.И.Романовского Академии наук Республики Узбекистан",
        "from": "Ургенч"
    },
    {
        "name": "Исхакова Р.В., Гайнетдинова А.А.",
        "body": "Оператор инвариантного дифференцирования для алгебры Ли контактных преобразований",
        "organization": "УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Ишкин Х.К.",
        "body": "Регуляризованный след оператора Штурма -- Лиувилля на кривой с регулярными особыми точками  на хорде",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Какушкин С.Н.",
        "body": "Развитие нового метода нахождения собственных функций возмущенных дискретных операторов, заданных на компактных графах",
        "organization": "ИТиСО",
        "from": "Белорецк"
    },
    {
        "name": "Каменских А.А., Носов Ю.О.",
        "body": "Математическое моделирование деформационного поведения сферических опорных частей мостов с технологическими углублениями под смазку при нормативной вертикальной нагрузке",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Каменских А.А., Панькова А.П.",
        "body": "Численное моделирование сопряжения элементов сферических опорных частей в рамках фрикционного контакта",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Капустин В.В.",
        "body": "Пространства де Бранжа и кси-функция Римана",
        "organization": "ПОМИ РАН",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Кац Д.Б.",
        "body": "Краевая задача Римана для бианалитических функций",
        "organization": "Московский политехнический университет",
        "from": "Москва"
    },
    {
        "name": "Каюмов Р.А.",
        "body": "Закритическое поведение сжатых стержней  с нелинейно упругими опорами",
        "organization": "КазГАСУ",
        "from": "Казань"
    },
    {
        "name": "Каюмов Р.А., Мухамедова И.З.",
        "body": "Моделирование процесса деградации стеклопластика под действием напряжений и щелочной среды",
        "organization": "КазГАСУ",
        "from": "Казань"
    },
    {
        "name": "Каюмов Ш.Р., Марданов А.П., Каюмов А.Б.",
        "body": "Математическое моделирование задачи теории фильтрации структурированных флюидов в слоистых средах",
        "organization": "ТГТУ",
        "from": "Ташкент"
    },
    {
        "name": "Кожевникова Л.М., Кашникова А.П.",
        "body": "Энтропийные решения задачи Неймана для квазилинейных эллиптических уравнений в пространствах Музилака-Орлича-Соболева",
        "organization": "СФ БашГУ",
        "from": "Стерлитамак"
    },
    {
        "name": "Коледина К.Ф., Коледин С.Н., Губайдуллин И.М.",
        "body": "Математическое описание кинетики каталитических реакций",
        "organization": "н.с. лаборатории математической химии ИНК УФИЦ РАН, доцент кафедры ИТМ УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Костин А.Б., Шерстюков В.Б.",
        "body": "Об интегральных представлениях гамма-функции",
        "organization": "НИЯУ МИФИ",
        "from": "Москва"
    },
    {
        "name": "Кризский В.Н., Викторов С.В., Лунтовская Я.А.",
        "body": "Об определении переходного сопротивления катодно-поляризуемого магистрального трубопровода по измерениям модуля вектора магнитной индукции",
        "organization": "СПГУ",
        "from": "Санкт-Петербург"
    },
    {
        "name": "Кужаев А.Ф.",
        "body": "О представлении функций рядами экспоненциальных мономов",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Кузнецова М.А.",
        "body": "Характеризация спектра оператора Штурма--Лиувилля с замороженным аргументом",
        "organization": "СГУ",
        "from": "Саратов"
    },
    {
        "name": "Куликов И.М.",
        "body": "Использование комбинации методов Годунова и Русанова на основе кусочно-параболического представления решения для моделирования взрыва сверхновых типа Ia",
        "organization": "ИВМиМГ СО РАН",
        "from": "Новосибирск"
    },
    {
        "name": "Кутлымуратов Б.Ж.",
        "body": "Граничная теорема Мореры для интегрируемых функции в неограниченной реализации шаре",
        "organization": "НГПИ. им. Ажинияза",
        "from": "Нукус"
    },
    {
        "name": "Латыпов И.И., Бигаева Л.А., Чудинов В.В.",
        "body": "Моделирование импульсной лазерной\nабляции материала",
        "organization": "БФ БашГУ",
        "from": "Бирск"
    },
    {
        "name": "Лесникова Ю.И., Труфанов А.Н.,  Каменских А.А., Пащенко М.М.",
        "body": "Анализ влияния характера контактного взаимодействия и коэффициента линейного температурного расширения на деформационные и оптические характеристики волокна типа Panda",
        "organization": "ПНИПУ",
        "from": "Пермь"
    },
    {
        "name": "Лискина Е.Ю., Бельман С.А.",
        "body": "Каскады бифуркаций в динамической модели\nсоциально-педагогического взаимодействия в учебной группе\nс отрицательным неформальным лидером",
        "organization": "РГУ имени С.А. Есенина",
        "from": "Рязань"
    },
    {
        "name": "Лобода А.В., Нгуен Т.Т.З.",
        "body": "О компьютерных алгоритмах описания однородных гиперповерхностей в R^4",
        "organization": "ВГТУ",
        "from": "Воронеж"
    },
    {
        "name": "Лубышев Ф.В., Файрузов М.Э.",
        "body": "Об одном итерационном методе решения задачи оптимального управления системой эллиптического типа",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Мазепа  Е.А., Рябошлыкова Д.К.",
        "body": "Краевые задачи для стационарного уравнения Шредингера в классе $C^{1}$ -- эквивалентных функций на римановых многообразиях",
        "organization": "ВолГУ",
        "from": "Волгоград"
    },
    {
        "name": "Макин А.С.",
        "body": "Характеристика спектра квазипериодической краевой задачи, порожденной несамосопряженным оператором Дирака",
        "organization": "МИРЕА",
        "from": "Москва"
    },
    {
        "name": "Максимов В.П.",
        "body": "Об одном подходу к оценке выходных характеристик динамических моделей с неполной информацией",
        "organization": "Пермский государственный национальный исследовательский университет",
        "from": "Пермь"
    },
    {
        "name": "Малькова Я.Ю.",
        "body": "Формирование целевой функции при решении оптимизационной задачи размещения возобновляемой генерации",
        "organization": "ТПУ",
        "from": "Томск"
    },
    {
        "name": "Малютин  К.Г., Кабанко М. В., Малютин В. А.",
        "body": "Интерполяция функциями порядка меньше первого в полуплоскости",
        "organization": "КГУ",
        "from": "Курск"
    },
    {
        "name": "Малютин  К.Г., Костенко",
        "body": "Интерполяция целыми функциями нормального типа при уточненном порядке Бутру",
        "organization": "КГУ",
        "from": "Курск"
    },
    {
        "name": "Малютин  К.Г., Наумова",
        "body": "Коэффициенты Фурье мероморфных функций в единичном круге",
        "organization": "КГУ",
        "from": "Курск"
    },
    {
        "name": "Марванов Р.И.",
        "body": "Об условиях локализации спектра модельного оператора для уравнения Орра–Зоммерфельда",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Марковский А.Н., Гамаюнова Д.Ю",
        "body": "Алгоритм присоединенного вихря плоской области в задаче обтекания",
        "organization": "КубГУ",
        "from": "Краснодар"
    },
    {
        "name": "Махмутова Д., Еникеева Л.В.",
        "body": "Моделирование реакции сернокислотного алкилирования изобутанов олефинами методом роя частиц и генетическим алгоритмом",
        "organization": "УГНТУ",
        "from": "Уфа"
    },
    {
        "name": "Мелихов С.Н.",
        "body": "Подпространства, инвариантные относительно оператора обобщенного обратного сдвига, и рациональные функции",
        "organization": "ЮФУ",
        "from": "Ростов-на-Дону"
    },
    {
        "name": "Меньшикова Э.Б.",
        "body": "Развитие интегральных формул Карлемана и Б.Я. Левина на основе инверсии",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Мирзаев О.Э.",
        "body": "О изоспектральном операторе Дирака на конечном отрезке",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Муминов У.Б., Ибрагимов Р.К.",
        "body": "Интегрирование нелинейного дефокусирующего уравнения Шредингера с дополнительными членами",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Муравник А.Б.",
        "body": "Эллиптические дифференциально-разностные уравнения в полупространстве: старые и новые результаты",
        "organization": " АО «Концерн «Созвездие»",
        "from": "Воронеж"
    },
    {
        "name": "Мурат Г., Кусаинова Л.К.",
        "body": "Весовые пространства бесова. теоремы вложения и интерполяции",
        "organization": "Евразийский национальный университет имени Л.Н.Гумилева",
        "from": "Нур-Султан"
    },
    {
        "name": "Мухамадиев Э., Назимов А.Б., Очилова М.А.",
        "body": "О предельных значениях гармонической функции на границе куба",
        "organization": "Вологодский госуниверситет",
        "from": "Вологда"
    },
    {
        "name": "Мухамадиев Э., Наимов А.Н.",
        "body": "О гомотопических классах положительно однородных функций от трех переменных",
        "organization": " ",
        "from": " "
    },
    {
        "name": "Насибуллин Р.Г.",
        "body": "Достаточные условия однолистности Нехари-Покорного и неравенства типа Харди",
        "organization": "Казанский федеральный университет",
        "from": "Казань"
    },
    {
        "name": "Насыров С.Р.",
        "body": "Квазиконформное отражение относительно границы равнобедренной трапеции",
        "organization": "КФУ",
        "from": "Казань"
    },
    {
        "name": "Насыров Ф.С.",
        "body": "Обратные стохастические дифференциальные уравнения с симметричным интегралом",
        "organization": "УГАТУ",
        "from": "Уфа"
    },
    {
        "name": "Невский М.В.",
        "body": "Интерполяция линейными функциями на евклидовом  шаре",
        "organization": "ЯрГУ им. П.Г. Демидова",
        "from": "Ярославль"
    },
    {
        "name": "Олими А.Г., Охунов Н.К.",
        "body": "Интегральное представление общего решения и граничные задачи для обыкновенного дифференциального уравнения специального типа с тремя слабо сингулярными точками",
        "organization": "ХГУ им. Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Павленко В.А.",
        "body": "<<Квантование>> гамильтоновой системы Кимуры H(2+3)",
        "organization": "Институт математики с вычислительным центром УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Павленко В.Н., Потапов Д.К.",
        "body": "Уравнения эллиптического типа с разрывными нелинейностями экспоненциального роста",
        "organization": "Челябинский государственный университет",
        "from": "Челябинск"
    },
    {
        "name": "Петросян Г.Г., Сорока М. С.",
        "body": "О разрешимости периодической задачи для полулинейных дифференциальных включений дробного порядка  2 <q <3",
        "organization": "ВГПУ",
        "from": "Воронеж"
    },
    {
        "name": "Пиров Р.",
        "body": "Изученные одного класса систем четырех дифференциальных уравнений второго порядка с двумя неизвестными функциями на плоскости",
        "organization": "ТГПУ имени С. Айни",
        "from": "Душанбе"
    },
    {
        "name": "Пиров Р., Рахимзода Ф.Ш.",
        "body": "Исследование некоторых переопределенных систем второго порядка с тремя неизвестными функциями в пространстве",
        "organization": "ТГПУ имени С. Айни",
        "from": "Душанбе"
    },
    {
        "name": "Поляков Д.М.",
        "body": "Формула регуляризованного следа дифференциального оператора четвертого порядка с многоточечными краевыми условиями",
        "organization": "ЮМИ ВНЦ РАН",
        "from": "Владикавказ"
    },
    {
        "name": "Рассадин А.Э.",
        "body": "Динамические системы в пространстве двусторонних последовательностей и детерминированный хаос",
        "organization": "Высшая школа экономики",
        "from": "Нижний Новгород"
    },
    {
        "name": "Раутиан Н.А.",
        "body": "Исследование вольтерровых интегро-дифференциальных уравнений  с ядрами, представимыми интегралами Стилтьеса",
        "organization": "МГУ им. М.В. Ломоносова",
        "from": "Москва"
    },
    {
        "name": "Рахимова М.А.",
        "body": "Многомерные переопределенные системы уравнений в частных производных с двумя комплексными переменными",
        "organization": "ТГУПБП (Таджикский государственный университет права, бизнеса и политики)",
        "from": "Худжанд"
    },
    {
        "name": "Румянцева С.В., Выборный Е.В.",
        "body": "Асимптотика решений разностных уравнений в неограниченной области",
        "organization": "НИУ ВШЭ",
        "from": "Москва"
    },
    {
        "name": "Савчук А.М., Садовничая И.В.",
        "body": "Оценки скорости равносходимости спектральных разложений для одномерной системы Дирака",
        "organization": "МГУ имени М.В.Ломоносова",
        "from": "Москва"
    },
    {
        "name": "Салимова А.Е., Хабибуллин Б.Н.",
        "body": "Распространение теоремы Мальявена -- Рубеля  с распределений положительных нулей на комплексные",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Сатторов Э.Н., Ermamatova Z.E.",
        "body": "Carleman's formula of a solutions of the poisson equation in bounded domain",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Сафаров Д., Миратов С.К.",
        "body": "Точное решение функционально - дифференциального уравнения Ван-дер-Поля —Дуффинга с постоянными отклонениями аргумента",
        "organization": "Бох.ГУ",
        "from": "Бохтар"
    },
    {
        "name": "Сергеев А.Г.",
        "body": "От вихрей Гинзбурга-Ландау к уравнениям Зайберга-Виттена",
        "organization": "Математический институт имени В.А.Стеклова РАН",
        "from": "Москва"
    },
    {
        "name": "Синельщиков Д.И.",
        "body": "Нелокальные преобразования и инвариантные кривые для осцилляторов с кубической нелинейностью",
        "organization": "НИУ ВШЭ",
        "from": "Moscow"
    },
    {
        "name": "Сипайло П.А.",
        "body": "Волновое уравнение на римановом многообразии и оператор сферического среднего",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Скубачевский А.Л., Беляева Ю.О., Гебхард Б.",
        "body": "Стационарные решения уравнений Власова--Пуассона на торе и применения к теории высокотемпературной плазмы",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Солиев Ю.С.",
        "body": "О квадратурных формулах с кратными узлами для интегралов с косекансным и логарифмическим ядром",
        "organization": "МАДИ",
        "from": "Москва"
    },
    {
        "name": "Старченко А.В., Данилкин Е.А., Лещинский Д.В.",
        "body": "Численное моделирование распространения выбросов автотранспорта в уличном каньоне",
        "organization": "ТГУ",
        "from": "Томск"
    },
    {
        "name": "Сулейманов Б.И.",
        "body": "Интегрируемое уравнение Абеля второго рода, возникающее при асимптотическом интегрировании симметрийного решения уравнения Кортевега -- де Вриза",
        "organization": "ИМВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Султанов М.А., Мисилов В.Е., Нурланулы Е.",
        "body": "Параллельный алгоритм решения уравнения диффузии с дробной производной по времени",
        "organization": "МКТУ им. Х. А. Ясави",
        "from": "Туркестан"
    },
    {
        "name": "Тасевич А.Л.",
        "body": "О гладкости обобщенных решений первой краевой задачи для функционально-дифференциального уравнения с ортотропными сжатиями внутри области",
        "organization": "РУДН",
        "from": "Москва"
    },
    {
        "name": "Ташпулатов С.М., Парманова Р.Т.",
        "body": "Structure of essential spectra and discrete spectrum of the energy operator of six electron systems in the Hubbard model. First quintet state",
        "organization": "Институт ядерной физики Академии наук Республики Узбекистан (ИЯФ АН РУз.)",
        "from": "Ташкент"
    },
    {
        "name": "Туленов К.С.",
        "body": "Boundedness of the Hilbert transform in Marcinkiewicz spaces with applications",
        "organization": "Институт математики и математического моделированиz",
        "from": "Алматы"
    },
    {
        "name": "Турсунов Ф.Р., Шодиев Д.С., Раззоков Ж.",
        "body": "О задаче Коши для бигармонического уравнения",
        "organization": "Самаркандский государственный университет",
        "from": "Самарканд"
    },
    {
        "name": "Фазуллин З.Ю.",
        "body": "Формулы следов относительно компактных возмущений операторов с дискретным спектром",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Федоров В.Е., Филин Н.В.",
        "body": "Аналитические разрешающие семейства операторов уравнений с дискретно распределенной дробной производной",
        "organization": "ЧелГУ",
        "from": "Челябинск"
    },
    {
        "name": "Фокеева Н.О., Башмаков Р.А.",
        "body": "Распределение давления в трещине ГРП при переходных режимах работы скважины",
        "organization": "ИМех УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Хабибуллин Б.Н.",
        "body": "Огибающие из подклассов почти субгармонических функций и их применения",
        "organization": "Башкирский государственный университет",
        "from": "Уфа"
    },
    {
        "name": "Хабиров С.С.",
        "body": "Эволюция трещины ромбического сечения",
        "organization": "Институт механики им. Р.Р. Мавлютова УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Хаймина Л.Э., Зеленина Л.И., Деменкова Е.А., Деменков М.Е., Хаймин Е.С., Хрипунов Д.Д.",
        "body": "Моделирование и анализ процессов на основе временных рядов",
        "organization": "Северный (Арктический) федеральный университет имени М.В. Ломоносова",
        "from": "Архангельск"
    },
    {
        "name": "Хакимова А.Р.",
        "body": "Обобщенные инвариантные многообразия и их приложения",
        "organization": "ОМФ,\nИнститут математики с ВЦ УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Ханазарян А.Д., Шпак А.Н., Голуб М.В.",
        "body": "К применению гибридного подхода для решения динамических задач теории упругости на основе метода спектральных элементов",
        "organization": "Кубанский государственный университет",
        "from": "Краснодар"
    },
    {
        "name": "Хасанов А.Б., Муминов У.Б.,  Данияров С.",
        "body": "Задача Коши для дефокусируещего нелинейного уравнения Шредингера с нагруженными членами",
        "organization": "Самаркандский государственный университет",
        "from": "Самарканд"
    },
    {
        "name": "Хасанов А.Б., Хоитметов У.А.\nДанияров Сахобиддин, магистрант СамГУ",
        "body": "Интегрирование модифицированного уравнения Кортевега-де Фриза с дополнительным членом",
        "organization": "Самаркандский государственный университет",
        "from": "Самарканд"
    },
    {
        "name": "Худайбердиева С.Р., Мухтаров Я.",
        "body": "Исследование поведения фазовых траекторий плоской системы дифференциальных уравнений в бесконечности",
        "organization": "СамГУ",
        "from": "Самарканд"
    },
    {
        "name": "Шавлуков А.М.",
        "body": "Особенность типа гиперболической формального решения системы уравнений газовой динамики",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Шагапов В.Ш., Галиакбарова Э.В., Хакимова З.Р",
        "body": "Диагностика поврежденных участков трубопроводов волновым зондированием",
        "organization": "Институт  механики  им. Р.Р. Мавлютова УФИЦ РАН",
        "from": "Уфа"
    },
    {
        "name": "Шакирзянов Ф.Р., Каюмов Р.А., Паймушин В.Н., Холмогоров С.А.",
        "body": "О причинах потери несущей способности композитной балки при трехточечном изгибе",
        "organization": "КазГАСУ",
        "from": "Казань"
    },
    {
        "name": "Шамсудинов Ф.М., Хомиддин С.",
        "body": "Об одной переопределенной системе дифференциальных уравнений второго порядка с особыми коэффициентами",
        "organization": "Бох.ГУ",
        "from": "Бохтар"
    },
    {
        "name": "Шарипов Б., Джумаев Э.Х.",
        "body": "Об одной нелинейной обобщённой системы Коши-Римана с сингулярными коэффициентами",
        "organization": "ТГФЭУ",
        "from": "Душанбе"
    },
    {
        "name": "Шарипов Р.А.",
        "body": "Псевдоадамаровы матрицы",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Шерстюков В.Б., Костин А.Б.",
        "body": "Об интегральных представлениях гамма-функции",
        "organization": "НИЯУ МИФИ",
        "from": "Москва"
    },
    {
        "name": "Шишкин А.Б.",
        "body": "О спектральном анализе в пространствах решений однородных уравнений типа свертки",
        "organization": "КубГУ",
        "from": "Краснодар"
    },
    {
        "name": "Шуклина А.Ф., Плеханова М.В.",
        "body": "Смешанное управление решениями вырожденного нелинейного дробного уравнения",
        "organization": "ЧелГУ",
        "from": "Челябинск"
    },
    {
        "name": "Эгамов А.И.",
        "body": "О связи решений двух начально-краевых задач",
        "organization": "ННГУ им. Н.И,Лобачевского, ИИТММ, каф. ДУМЧА",
        "from": " "
    },
    {
        "name": "Эргашбоев Т., Воситова Д.А., Набиева М.Ш.",
        "body": "Построения обыкновенного дифференциального уравнения второго порядка, допускающий заданную группу Ли",
        "organization": "ХГУ имени Б.Гафурова",
        "from": "Худжанд"
    },
    {
        "name": "Юлмухаметов Р.С.",
        "body": "Сопряженные пространства к весовым пространствам локально интегрируемых функций",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Юмагулов М.Г., Ибрагимова Л.С., Белова А.С.",
        "body": "Методы теории возмущений в задаче о параметрическом резонансе для линейных периодических гамильтоновых систем",
        "organization": "БашГУ",
        "from": "Уфа"
    },
    {
        "name": "Язовцева О.С., Губайдуллин И.М., Пескова Е.Г.",
        "body": "Исследование математической модели окислительной регенерации катализатора",
        "organization": "Национальный исследовательский Мордовский государственный университет",
        "from": "Саранск"
    },
    {
        "name": "Яндыбаева И.Г.",
        "body": "Формула следа двумерного гармонического осциллятора в полосе",
        "organization": "БашГУ",
        "from": "Уфа"
    }
]
  function throttle(func, ms) {

	let isThrottled = false,
	  savedArgs,
	  savedThis;
  
	function wrapper() {
  
	  if (isThrottled) { // (2)
		savedArgs = arguments;
		savedThis = this;
		return;
	  }
  
	  func.apply(this, arguments); // (1)
  
	  isThrottled = true;
  
	  setTimeout(function() {
		isThrottled = false; // (3)
		if (savedArgs) {
		  wrapper.apply(savedThis, savedArgs);
		  savedArgs = savedThis = null;
		}
	  }, ms);
	}
  
	return wrapper;
  }
export default function Members() {
	const classes = useStyles();
	const [members, setMembers] = useState(initialState);
	const filterMember = (e) => {
		let variant;
		e.currentTarget.id === 'search-name' ? (variant = 'name') : (variant = 'body');

		const value = e.target.value;
		let copy = [...initialState];
		variant === 'name'
			? setMembers(copy.filter((el) => el.name.search(new RegExp(value, 'ig')) !== -1))
			: setMembers(copy.filter((el) => el.body.search(new RegExp(value, 'ig')) !== -1));
	};

	return (
		<Container maxWidth='lg' className={classes.container}>
			<Paper className={classes.paper}>
				<Box p={4} className={classes.heading}>
					<Typography variant='h3' className={classes.title}>
						Принятые заявки: {initialState.length}
					</Typography>
					<Box className={classes.inputs}>
						<TextField
							id='search-name'
							size='small'
							fullWidth
							onChange={throttle(filterMember, 400)}
							label='Поиск по фамилии'
						/>
						<TextField
							id='search-body'
							size='small'
							fullWidth
							onChange={filterMember}
							label='Поиск по названию'
						/>
					</Box>
				</Box>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>№</TableCell>
								<TableCell align='center'>ФИО</TableCell>
								<TableCell align='center'>Название доклада</TableCell>
								<TableCell align='center'>Организация</TableCell>
								<TableCell align='center'>Город</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{members.map((row, index) => (
								<TableRow key={row.name + ' ' + row.body}>
									<TableCell component='th' scope='row'>
										{index + 1}
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.body}</TableCell>
									<TableCell>{row.organization}</TableCell>
									<TableCell>{row.from}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
}
