export const data23 = [
    {
      "name": "Абдрахманов С.И.",
      "body": "О нелинейном стохастическом уравнении теплопроводности и горения с воздействием шума на различные слагаемые в правой части",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Абдрахманова Р.П.",
      "body": "Разрешимость краевых задач для эллиптических систем",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Абдуллаев О.А., Рузиев И.Х.",
      "body": "Разрешимость обобщенной смешанной задачи теории упругости.",
      "organization": "Самаркадский госуниверситет",
      "from": "Самарканд"
    },
    {
      "name": "Абдурагимов Г.Э.",
      "body": "О существовании положительного решения краевой задачи для одного нелинейного функционально – дифференциального уравнения дробного порядка",
      "organization": "Дагестанский государственный университет",
      "from": "Махачкала"
    },
    {
      "name": "Абузярова Н.Ф.",
      "body": "О чисто мнимых возмущениях нулевых множеств, сохраняющих заданный класс целых функций",
      "organization": "Институт математики с ВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Авхадиев Ф.Г.",
      "body": "Степенные моменты области относительно ее границы и их применения",
      "organization": "Казанский федеральный университет",
      "from": "Казань"
    },
    {
      "name": "Агаркова Н.Н., Васильев В.Б.",
      "body": "Об одной краевой задаче в области с щелью",
      "organization": "НИУ БелГУ",
      "from": "Белгород"
    },
    {
      "name": "Адхамова А.Ш.",
      "body": "Гладкость решений задачи об успокоении системы управления с последействием нейтрального типа на подынтервалах",
      "organization": "РУДН, Математический институт им. С.М. Никольского",
      "from": "Уфа"
    },
    {
      "name": "Аиткужина Н.Н.",
      "body": "Асимптотика целого ряда Дирихле из заданого класса на кривых ограниченного К-наклона",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Аиткужина Н.Н., Гайсин А.М.",
      "body": "Асимптотика целого ряда Дирихле на кривых ограниченного наклона",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Акманов А.А.",
      "body": "Соприкосновение плоских кривых: соприкасающаяся окружность",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Акманова С.В.",
      "body": "Об управляемости и стабилизируемости нелинейных гибридных систем",
      "organization": "Магнитогорский государственный технический университет им. Г.И.Носова",
      "from": "Магнитогорск"
    },
    {
      "name": "Александров П.Н., Звежинский С.С., Кокурин Л.А.",
      "body": "Некоторые вопросы системного анализа временных рядов при моделировании военных действий",
      "organization": "ИФЗ им. О.Ю. Шмидта РАН",
      "from": "Москва"
    },
    {
      "name": "Александров П.Н., Кризский В.Н.",
      "body": "О решении линейных обратных задач математической геофизики",
      "organization": "ИФЗ им. О.Ю. Шмидта РАН; СПГУ императрицы Екатерины II",
      "from": "Москва; Санкт-Петербург"
    },
    {
      "name": "Антипина Е.В., Мустафина С.А., Антипин А.Ф.",
      "body": "Применение генетических алгоритмов при решении задач оптимального управления",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Апушкинская Д.Е.",
      "body": "Задачи Вентцеля с разрывными коэффициентами",
      "organization": "РУДН",
      "from": "Москва"
    },
    {
      "name": "Арабов М.К.",
      "body": "Анализ существования предельных циклов в кусочно-линейных динамических системах с помощью компьютерных методов",
      "organization": "КФУ",
      "from": "Казань"
    },
    {
      "name": "Аргучинцев А.В.",
      "body": "Неклассическое условие оптимальности в задаче минимизации нормы конечного состояния каскадной системой гиперболических и обыкновенных дифференциальных уравнений",
      "organization": "Иркутский государственный университет",
      "from": "Иркутск"
    },
    {
      "name": "Арсланбекова С.А., Дик Е.Н., Зиянгирова С.Р., Павленко В.А.",
      "body": "Построение математической модели процесса сушки зерновых",
      "organization": "БГАУ",
      "from": "Уфа"
    },
    {
      "name": "Асхабов С.Н.",
      "body": "Интегральные уравнения со степенным суммарным ядром и монотонной нелинейностью",
      "organization": "Чеченский государственный педагогический университет",
      "from": "Грозный"
    },
    {
      "name": "Бекежанова В.Б., Гилёв Н.И.",
      "body": "Об одном точном решении задачи испарительной конвекции с разными типами граничных условий для функции температуры",
      "organization": "ИВМ СО РАН",
      "from": "Красноярск"
    },
    {
      "name": "Белевцов Н.С.",
      "body": "Мультипольные алгоритмы численного моделирования нелокальных процессов",
      "organization": "ООО <<РН-БашНИПИнефть>>, УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Белова А.С.",
      "body": "Об устойчивости движения динамически симметричного спутника относительно центра масс",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Бибердорф Э.А., Ван Л.",
      "body": "Исследование роста решений линейных систем ОДУ в зависимости от псевдоспектра матрицы",
      "organization": "ИМ СО ПАН",
      "from": "Новосибирск"
    },
    {
      "name": "Богатырева Ф.Т.",
      "body": "О разрешимости уравнения в частных производных первого порядка с операторами Джрбашяна--Нерсесяна",
      "organization": "ИПМА КБНЦ РАН",
      "from": "Нальчик"
    },
    {
      "name": "Богданова А.П.",
      "body": "Исследование деформирования полимерной прослойки при\nматематическом описании поведения материала в рамках упругопластичности и\nвязкоупругости",
      "organization": "Пермский национальный исследовательский политехнический университет",
      "from": "Пермь"
    },
    {
      "name": "Бодренко И.И.",
      "body": "Некоторые свойства комплексно-аналитических поверхностей в евклидовых пространствах",
      "organization": "ООО ``Интерактивные системы'",
      "from": "Волгоград"
    },
    {
      "name": "Бойко К.В.",
      "body": "Вырожденное дробное дифференциальное уравнение с производными Герасимова - Капуто",
      "organization": "ЧелГУ",
      "from": "Челябинск"
    },
    {
      "name": "Бондаренко Н.П.",
      "body": "Обратная спектральная задача для дифференциального уравнения четвертого порядка",
      "organization": "СГУ",
      "from": "Саратов"
    },
    {
      "name": "Борисов Д.И., Сулейманов Р.Р.",
      "body": "Об операторных оценках для эллиптических операторов со смешанными краевыми условиями в двумерных областях с быстро осциллирующей границей",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Борисов Д.И., Сулейманов Р.Р.",
      "body": "Усреднение и операторные оценки полулинейных эллиптических систем в областях с сильно изрезанной границей",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Брайчев Г.Г.",
      "body": "О множествах единственности для классов целых функций",
      "organization": "МПГУ, РУДН",
      "from": "Москва"
    },
    {
      "name": "Буланов С.Г.",
      "body": "О критериях устойчивости по Ляпунову систем обыкновенных дифференциальных уравнений",
      "organization": "Таганрогский институт имени А.П. Чехова (филиал) РГЭУ (РИНХ)",
      "from": "Таганрог"
    },
    {
      "name": "Бурлаков Е.О., Мальков И.Н.",
      "body": "Математическое моделирование в задаче разработки экологического метода контроля фузариоза колоса пшеницы",
      "organization": "Тюменский государственный университет",
      "from": "Тюмень"
    },
    {
      "name": "Бурлаков Е.О., Мальков И.Н.",
      "body": "О двухслойных моделях электрической активности нейронного поля",
      "organization": "ТюмГУ",
      "from": "Тюмень"
    },
    {
      "name": "Бутерин С.А.",
      "body": "Об успокоении системы управления произвольного порядка на графе с глобальным последействием",
      "organization": "Саратовский университет",
      "from": "Саратов"
    },
    {
      "name": "Валеев Н.Ф.",
      "body": "Об одной обратной спектральной задаче с неполными данными",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Валеева Л.Н.",
      "body": "Моделирование распределения поля темного фотона между резонансными полостями",
      "organization": "МГУ им. М.В. Ломоносова",
      "from": "Москва"
    },
    {
      "name": "Валитов Д.М., Ямилева А.М., Грахова Е.П., Кутлуяров Р.В., Ямилева А.М.",
      "body": "Математическая модель процесса оптической когерентной томографии с перестраиваемым источником",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Васильева О.А.",
      "body": "Исследование математической модели одной химической реакции",
      "organization": "НИУ МГСУ",
      "from": "Москва"
    },
    {
      "name": "Вильданова В.Ф., Мукминов Ф.Х.",
      "body": "О существовании энтропийного решения задачи Неймана для уравнения с мерозначным потенциалом",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Вирченко Ю.П., Черкашин Д.А.",
      "body": "Определение индекса племенной ценности на основе байесовского оценивания",
      "organization": "Белгородский государственный технологический университет им. В.Г.Шухова",
      "from": "Белгород"
    },
    {
      "name": "Воронцова А.",
      "body": "Построение нечетких кластеров на основе метода K-MEANS",
      "organization": "САФУ им М.В. Ломоносова",
      "from": ""
    },
    {
      "name": "Габдрахманов Р.И. .",
      "body": "БИФУРКАЦИЯ АНДРОНОВА-ХОПФА В НЕГЛАДКИХ СИСТЕМАХ",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Гаджимурадов Т.А., Агаларов А.М.",
      "body": "Линейная интерференция нелинейных волн",
      "organization": "ДФИЦ РАН",
      "from": "Махачкала"
    },
    {
      "name": "Гадзова Л.Х.",
      "body": "Нелокальная краевая задача для обыкновенного дифференциального уравнения дробного порядка",
      "organization": "ИПМА КБНЦ РАН",
      "from": "Нальчик"
    },
    {
      "name": "Гайдуков Р.К.",
      "body": "Существование решения уравнения типа Рэлея с потенциалом типа Кулона",
      "organization": "НИУ ВШЭ",
      "from": "Москва"
    },
    {
      "name": "Гайсин Р.А.",
      "body": "Об одной оценке специальных чисел Ln сверху через Mn^c. Применение",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Галиакбарова Э.В., Каримова Г.Р.",
      "body": "Поверхностные акустические волны на границе двух пористых сред (песок, насыщенный гидратом метана или водой (льдом)",
      "organization": "ИМех УФИЦ РАН, УГНТУ",
      "from": "Уфа"
    },
    {
      "name": "Галлямов В.В., Полупанов Д.В., Абдюшева С.Р.",
      "body": "Применение машинного обучения к задаче моделирования налогового поведения",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Гарифуллин Р.Н.",
      "body": "О преобразование полудискретных уравнений и их высших симметрий",
      "organization": "Институт математики с ВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Гудков Е.",
      "body": "Симплектическое обобщение аксиоматики Хаага- Араки и его приложения в физике геодезических причинных структур",
      "organization": "Государственный университет «Дубна»",
      "from": "Московская область"
    },
    {
      "name": "Гусев А.Л.",
      "body": "Множества, близкие к интерполяционным, в пространстве функций конечного порядка в полуплоскости",
      "organization": "Курский ГАУ",
      "from": "Курск"
    },
    {
      "name": "Давлетова Л.Г., Ишкин Х.К. .",
      "body": "О критерии отсутствия асимптотически кратных собственных чисел оператора Штурма-Лиувилля с мероморфным потенциалом",
      "organization": "УГНТУ; УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Данилкин Е.А., Старченко А.В., Лещинский Д.В., Юмин К.В.",
      "body": "Моделирование распространения выбросов автотранспорта в уличном каньоне с растительностью",
      "organization": "ТГУ",
      "from": "Томск"
    },
    {
      "name": "Даулетбай Б.Н., Кангужин Б.Е.",
      "body": "Граничное управление температурным распределением, описываемым уравнением Гупкина-Пипкина",
      "organization": "КазНУ",
      "from": "Алматы"
    },
    {
      "name": "Деревцов Е.Ю.",
      "body": "О лучевых преобразованиях моментов 2D тензорных полей",
      "organization": "ИМ СО РАН",
      "from": "Новосибирск"
    },
    {
      "name": "Доброхотов С.Ю.",
      "body": "Операторные пучки и задача о возбуждении длинных линейных волн на поверхности слоя жидкости источниками в упругом основании",
      "organization": "ИПМехРАН",
      "from": "Москва"
    },
    {
      "name": "Дрожжинов Ю.Н.",
      "body": "Обобщенные функции однородные относительно фазовых траекторий вырожденных узлов",
      "organization": "МИАН им. В.А. Стеклова",
      "from": "Москва"
    },
    {
      "name": "Дютин А.Ю., Насыров С.Р.",
      "body": "Параметрический метод нахождения акцессорных параметров для конформных отображений двусвязных многоугольных областей",
      "organization": "Казанский федеральный университет",
      "from": "Казань"
    },
    {
      "name": "Евсеев Ф.А.",
      "body": "Обобщенная разрешимость краевых задач для квазигидродинамической системы уравнений в случае слабосжимаемой жидкости",
      "organization": "Югорский государственный университет",
      "from": "Ханты-Мансийск"
    },
    {
      "name": "Загитов Р.Р.",
      "body": "Обобщение точного решения неустановившихся движений несжимаемой жидкости с линейным полем скоростей",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Зайдель М.И.",
      "body": "Индексы устойчивости и экспоненциальной устойчивости линейной системы, зависящей от параметра",
      "organization": "МГУ им. М.В. Ломоносова",
      "from": "Москва"
    },
    {
      "name": "Зайнуллин Р.Г.",
      "body": "Двухмерная двухфазная задача теплообмена с фазовым переходом",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Закирзянов Ш.И., Байков В.А.",
      "body": "Исследование неустойчивости задачи о развитии трещины ГРП",
      "organization": "ООО РН-БашНИПИНефть",
      "from": "Уфа"
    },
    {
      "name": "Захарова Т.А., Федоров В.Е.",
      "body": "Однозначная разрешимость задачи Коши для квазилинейного уравнения дробного порядка",
      "organization": "ЧелГУ",
      "from": "Челябинск"
    },
    {
      "name": "Избяков И.Я.",
      "body": "О восстановлении вектор-сигнала с помощью конечных фреймов",
      "organization": "Самарский университет",
      "from": "Самара"
    },
    {
      "name": "Изместьев И.В., Ухоботов В.И.",
      "body": "Однотипная дифференциальная игра с многократным изменением в динамике",
      "organization": "ИММ УрО РАН",
      "from": "Екатеринбург"
    },
    {
      "name": "Ильясов Я.Ш.",
      "body": "Построение вариационными методами $S$-образной бифуркационной кривой решений с катастрофой дуальной сборки",
      "organization": "ИМ ВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Исаев К.П., Луценко А.В., Юлмухаметов Р.С.",
      "body": "О базисах Рисса из нормированных воспроизводящих ядер в гильбертовых пространствах целых функций",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Ишкин Х.К.",
      "body": "О полноте и базисности системы корневых функций оператора Штурма--Лиувилля на кривой, порожденного интегральными краевыми условиями",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Кабанко М.В.",
      "body": "Уточненный порядок относительно модельной функции роста",
      "organization": "КГУ",
      "from": "Курск"
    },
    {
      "name": "Кабанко М.В., Малютин К.Г.",
      "body": "Анализ треугольных представлений алгебры операторов в\nпарах пространств аналитических функций",
      "organization": "Курский государственный университет",
      "from": "Курск"
    },
    {
      "name": "Кабанов Д.К., Фахретдинов М.И., Самсонов К.Ю., Дмитриев С.В., Екомасов Е.Г.",
      "body": "ИЗУЧЕНИЕ НЕЛИНЕЙНЫХ ВОЛН УРАВНЕНИЙ КЛЕЙНА-ГОРДОНА В МОДЕЛИ С ПРИМЕСЯМИ",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Какушкин С.Н.",
      "body": "Асимптотические апостериорные формулы оценок погрешности нахождения собственных функций возмущенных самосопряженных операторов",
      "organization": "ИТиСО, Администрация МР Белорецкий район РБ",
      "from": "Белорецк"
    },
    {
      "name": "Калинин А.В., Тюхтина А.А.",
      "body": "Асимптотический анализ влияния возмущений проводимости на квазистационарные электромагнитные поля в атмосфере",
      "organization": "ННГУ имени Н.И.Лобачевского",
      "from": "Нижний Новгород"
    },
    {
      "name": "Каменских А.А., Носов Ю.О.",
      "body": "Математическое моделирование термовязкоупругого и термовязкопластического поведения смазочных материалов",
      "organization": "ПНИПУ",
      "from": "Пермь"
    },
    {
      "name": "Каменских А.А., Стражец Ю.А.",
      "body": "Анализ влияния геометрической конфигурации защитного полимерного покрытия на оптические и деформационные характеристики волокна типа Panda",
      "organization": "ПНИПУ",
      "from": "Пермь"
    },
    {
      "name": "Кангужин Б.Е., Досмагулова К.",
      "body": "Correctly solvable problem for the Laplace-Beltrami equation on a three-dimensional sphere with a cut along the circle",
      "organization": "КазНУ",
      "from": "Алматы"
    },
    {
      "name": "Кангужин Б.Е., Даулетбай Б.Н.",
      "body": "Однозначное определение условий сопряжения во внутренних вершинах графа–дерева по конечному набору собственных значений",
      "organization": "КазНУ",
      "from": "Алматы"
    },
    {
      "name": "Каримов К.Т., Шокиров А.М.",
      "body": "Об одной задаче для трехмерного уравнения смешанного типа с сингулярным коэффициентом",
      "organization": "Ферганский государственный университет",
      "from": "Фергана"
    },
    {
      "name": "Каримова Г.Р.",
      "body": "Поверхностные акустические волны на границе двух пористых сред (песок, насыщенный гидратом метана или водой (льдом)",
      "organization": "ИМех УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Кащенко А.А.",
      "body": "Нелокальная динамика кольца осцилляторов при различных видах связи между осцилляторами",
      "organization": "ЯрГУ им. П.Г. Демидова",
      "from": "Ярославль"
    },
    {
      "name": "Каюмов Р.А., Мухамедова И.З.",
      "body": "Об одном варианте энергетического метода решении задачи устойчивости балок",
      "organization": "КГАСУ",
      "from": "Казань"
    },
    {
      "name": "Клевцова Ю.Ю.",
      "body": "О некоторых неравенствах для стационарных мер модели Лоренца",
      "organization": "ИСИ СО РАН, СибГУТИ",
      "from": "Новосибирск"
    },
    {
      "name": "Клячин А.А.",
      "body": "Выделение признаков на изображениях методом интегральных преобразований для решения задач распознавания",
      "organization": "ВолГУ",
      "from": "Вологда"
    },
    {
      "name": "Кожевникова Л.М.",
      "body": "Существование решений нелинейной эллиптической задачи с $L_1$-данными в пространствах Музилака-Орлича",
      "organization": "СФ УУНиТ",
      "from": "Стерлитамак"
    },
    {
      "name": "Колонских Д.М., Иванов В.Н.",
      "body": "Математическая модель повышения точности акселерометров IMU",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Конечная Н.Н.",
      "body": "Об асимптотике решений двучленных дифференциальных уравнений с сингулярными коэффициентами",
      "organization": "САФУ",
      "from": "Архангельск"
    },
    {
      "name": "Кораблина Ю.В.",
      "body": "Компактные операторы в весовых квазибанаховых пространствах голоморфных функций",
      "organization": "ЮФУ",
      "from": "Ростов-на-Дону"
    },
    {
      "name": "Корзюк В.И., Рудько Я.В.",
      "body": "Cauchy Problem for a Semilinear Nonstrictly Hyperbolic Equation on a Half-Plane in the Case of a Single Characteristic",
      "organization": "Белорусский государственный университет",
      "from": "Минск"
    },
    {
      "name": "Кузнецова М.А.",
      "body": "Решения Биркгофа систем дифференциальных уравнений на полуоси",
      "organization": "СГУ",
      "from": "Саратов"
    },
    {
      "name": "Кузнецова М.Н.",
      "body": "Построение локализованных частных решений цепочек с тремя независимыми переменными",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Кузнецова Ю.С., Сукрушева Е.В.",
      "body": "Оценка напряженно-деформированного состояния дорожного полотна под воздействием статической нагрузки",
      "organization": "ПНИПУ",
      "from": "Пермь"
    },
    {
      "name": "Лукащук С.Ю.",
      "body": "Об одном дробно-дифференциальном обобщении уравнения Бюргерса",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Кулагин А.Е., Шаповалов А.В.",
      "body": "Квазиклассически сосредоточенные решения нелокального нелинейного уравнения Шредингера с неконсервативной частью",
      "organization": "ТПУ, ИОА СО РАН",
      "from": "Томск"
    },
    {
      "name": "Куликов И.М.",
      "body": "Об одной конструкции схемы Лакса - Фридрихса для математического моделирования релятивистских струй",
      "organization": "ИВМиМГ СО РАН",
      "from": "Новосибирск"
    },
    {
      "name": "Кучкарова А.Н.",
      "body": "Система собственных функций задачи Трикоми- Неймана для вырождающегося оператора смешанного типа",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Лангаршоев М.Р.",
      "body": "Наилучшее приближении аналитических в единичном круге функций в весовом пространстве Бергмана $B_{2,\\mu}$",
      "organization": "Подмосковный колледж <<Энергия>>",
      "from": "Старая Купавна"
    },
    {
      "name": "Линчук Л.В.",
      "body": "Факторизация уравнений с частными производными 2-го порядка",
      "organization": "СПбПУ",
      "from": "Санкт-Петербург"
    },
    {
      "name": "Литвинова К.В.",
      "body": "Mathematical modeling of oil and gas production based on the construction of self-simular traveling wave solutions for two-component filtration equations",
      "organization": "МГУ им. М.В. Ломоносова",
      "from": "Москва"
    },
    {
      "name": "Лобода А.В., Акопян Р.С.",
      "body": "7-мерные алгебры Ли и невырожденные голоморфно однородные гиперповерхности в C^4",
      "organization": "ВГТУ; МИРЭА",
      "from": "Воронеж; Москва"
    },
    {
      "name": "Лыу Т.Х.",
      "body": "О косвенной вариационной формулировке одного дифференциального уравнения с частными производными пятого порядка",
      "organization": "РУДН",
      "from": "Москва"
    },
    {
      "name": "Мажгихова М.Г.",
      "body": "Задача Дирихле -- Неймана для дифференциального уравнения дробного порядка с запаздывающим аргументом",
      "organization": "ИПМА КБНЦ РАН",
      "from": "Нальчик"
    },
    {
      "name": "Макин А.С.",
      "body": "О полноте системы корневых функций несамосопряженного оператора Дирака",
      "organization": "ООО С.П.К",
      "from": "Москва"
    },
    {
      "name": "Мальцева С.В.",
      "body": "Восстановление нескалярных полей по ограниченным лучевым преобразованиям",
      "organization": "Институт математики им. С. Л. Соболева СО РАН",
      "from": "Новосибирск"
    },
    {
      "name": "Малютин В.Б., Нуржанов Б.О.",
      "body": "Квазиклассическая аппроксимация функциональных интегралов",
      "organization": "Институт математики Национальной академии наук Беларуси; Институт математики имени В. И. Романовского",
      "from": "Минск; Ташкент"
    },
    {
      "name": "Малютин К.Г., Кабанко М.В.",
      "body": "Уточненный порядок относительно модельной функции\nроста",
      "organization": "Курский государственный университет",
      "from": "Курск"
    },
    {
      "name": "Маматов А.Р.",
      "body": "Метод решения одной максиминной задачи уклонения-сближения",
      "organization": "Самаркандский государственный университет",
      "from": "Самарканд"
    },
    {
      "name": "Мамедова А.А.",
      "body": "Об аналитическом разрешающем семействе операторов уравнения с распределенной производной",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Мануйлов В.М.",
      "body": "О топологических препятствиях к существованию непериодических базисов Ваннье",
      "organization": "МГУ им. М.В. Ломоносова",
      "from": "Москва"
    },
    {
      "name": "Машарипов С.И.",
      "body": "Analysis of asymptotic behaviour of the trajectory of several degenerate States for the Volterra operator in S^3",
      "organization": "Национальный университет Узбекистана",
      "from": "Ташкент"
    },
    {
      "name": "Медведев А., Кузенков О.",
      "body": "ФУНКЦИЯ КОНКУРЕНТОСПОСОБНОСТИ ДЛЯ ОБОБЩЁННОЙ МОДЕЛИ АБРАМСА-СТРОГАТТИ В СЛУЧАЕ НЕПОСТОЯННОЙ ЧИСЛЕННОСТИ СООБЩЕСТВА",
      "organization": "Нижегородский государственный университет им. Н. И. Лобачевского",
      "from": "Нижний Новгород"
    },
    {
      "name": "Медведев А., Кузенков",
      "body": "Modeling language competition in a bilingual community",
      "organization": "Нижегородский государственный университет им. Н. И. Лобачевского",
      "from": "Нижний Новгород"
    },
    {
      "name": "Мелихов С.Н.",
      "body": "О делении на многочлены в пространствах аналитических функционалов",
      "organization": "ЮФУ",
      "from": "Ростов-на-Дону"
    },
    {
      "name": "Мирзоев К.А., Сафонова Т.А.",
      "body": "Рекуррентные соотношения с пропусками длины 2n для многочленов Бернулли и Эйлера",
      "organization": "МГУ им. М.В. Ломоносова, САФУ им. М.В. Ломоносова",
      "from": "Москва; Архангельск"
    },
    {
      "name": "Мифтахов Э.Н., Мустафина С.А.",
      "body": "Имитационный подход к решению обратной задачи формирования молекулярно-массового распределения",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Мищенко Е.В., Гуань С.",
      "body": "Об исследовании ЭГД течений пуазейлевского типа для полимерной жидкости в плоском канале",
      "organization": "НГУ, ИМ СОРАН",
      "from": "Новосибирск"
    },
    {
      "name": "Миянов М.Р., Ямилева А.М., Лукащук С.Ю.",
      "body": "Решение обыкновенных дробно-дифференциальных уравнений на физико-информированных нейронных сетях",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Морозкин Н.Д., Морозкин Н.Н.",
      "body": "Нестационарная неизотермическая фильтрация\nвязкопластичной нефти с учетом зависимости коэффициента вязкости от градиента\nдавления",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Муравник А.Б.",
      "body": "Дифференциально-разностные гиперболические уравнения\nс произвольно направленными сдвигами потенциалов",
      "organization": "РУДН",
      "from": "Москва"
    },
    {
      "name": "Мустафина С.И.",
      "body": "Алгоритм математического моделирования трехмерных изображений с использованием нейронной сети",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Мухамадиев Э.М., Нуров И.Д.",
      "body": "Предельные циклы кусочно - линейного дифференциального уравнения второго порядка с разрывной линией переключения",
      "organization": "Вологодский государственный университет; Таджикский национальный университет",
      "from": "Вологда; Душанбе"
    },
    {
      "name": "Мухсинов Е.М.",
      "body": "Разрешимость задачи преследования для одной линейной дифференциальной игры дробного порядка",
      "organization": "ТГУПБП",
      "from": "Худжанд"
    },
    {
      "name": "Нагуманова А.В., Цивилёв Е.А.",
      "body": "Один класс уравнений с дробной производной Капуто - Фабицио по времени",
      "organization": "ЧелГУ",
      "from": "Челябинск"
    },
    {
      "name": "Наимов А.Н.",
      "body": "О разрешимости периодической задачи для системы нелинейных обыкновенных дифференциальных уравнений второго порядка",
      "organization": "ВоГУ",
      "from": "Вологда"
    },
    {
      "name": "Наливкин В.В., Лукащук В.О.",
      "body": "Симметрийный анализ нелинейного уравнения Клейна-Гордона со слабой диссипацией",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Напалков В.В., Нуятов А.А.",
      "body": "Задача интерполяции в прообразе оператора свертки",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Напалков В.В., Нуятов А.А.",
      "body": "Необходимые и достаточные условия вложения гильбертовых пространств с воспроизводящим ядром",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Насыров Ф.С.",
      "body": "Метод фиктивных множителей Лагранжа и потраекторно-детерминированный принцип максимума для СДУ",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Насырова Д.А.",
      "body": "Колебания жидкости в горизонтальной скважине с трещинами ГРП",
      "organization": "Институт механики им Р.Р. Мавлютова УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Невский М.В.",
      "body": "Минимальная норма проектора при интерполяции линейными функциями на евклидовом шаре",
      "organization": "ЯрГУ им. П.Г. Демидова",
      "from": "Ярославль"
    },
    {
      "name": "Низамова А.Д., Муртазина Р. Д. , Сидельников А. В., Васенина Н. А., Шарипова Л. Д.",
      "body": "Конечно-разностные и спектральные методы и их перспективы применения в вольтамперометрических мультисенсорных системах",
      "organization": "Институт Механики УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Одинабеков Д.М.",
      "body": "О двумерных сингулярных интегральных операторах с\nфиксированными особенностями",
      "organization": "Филиал МГУ имени М.В. Ломоносова",
      "from": "Душанбе"
    },
    {
      "name": "Оксогоева И.П., Лазарева Г.Г.",
      "body": "Математическая модель переноса вещества в винтовом магнитном поле с использованием граничных условий на бесконечности",
      "organization": "РУДН им. Патриса Лумумбы",
      "from": "Москва"
    },
    {
      "name": "Орипов Т.С.",
      "body": "О вырождающейся системе нелинейных уравнений в частных производных 2-го порядка с сингулярными коэффициентами",
      "organization": "Денауский институт предпринимательство и педагогики",
      "from": "Денау"
    },
    {
      "name": "Павленко В.А.",
      "body": "Построение решений аналогов временных уравнений Шредингера, соответствующей одной из гамильтоновых систем Кимуры",
      "organization": "ИМ ВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Павлюк Ю.А., Вайцель Д.Э.",
      "body": "О выборе начального приближения в реологии расплавов полимеров и результаты, полученные на его основе",
      "organization": "Алтайский государственный педагогический университет",
      "from": "Барнаул"
    },
    {
      "name": "Погребняк М.А.",
      "body": "Моделирование движения транспортного потока",
      "organization": "ЯрГУ",
      "from": "Ярославль"
    },
    {
      "name": "Подклетнова С.В.",
      "body": "Ряд краевых задач для уравнения Эйлера-Дарбу с двумя линиями вырождения",
      "organization": "Самарский университет",
      "from": "Самара"
    },
    {
      "name": "Поляков Д.М.",
      "body": "Спектральные свойства дифференциального оператора четвертого порядка на отрезке",
      "organization": "ЮМИ ВНЦ РАН",
      "from": "Владикавказ"
    },
    {
      "name": "Попов А.Ю., Шерстюков В.Б.",
      "body": "Оценка снизу минимума модуля целой функции нулевого рода через отрицательную степень максимума модуля",
      "organization": "МГУ им. М.В. Ломоносова",
      "from": "Москва"
    },
    {
      "name": "Починка О.В.",
      "body": "О топологии 3-многообразий, допускающих диффеоморфизмы Морса-Смейла с 4 неподвижными точками попарно различных индексов Морса",
      "organization": "НИУ ВШЭ",
      "from": "Нижний Новгород"
    },
    {
      "name": "Псху А.В.",
      "body": "Об обращении оператора дифференцирования распределенного порядка",
      "organization": "ИПМА КБНЦ РАН",
      "from": "Нальчик"
    },
    {
      "name": "Раутиан Н.А.",
      "body": "Представления решений вольтерровых интегро-дифференциальных уравнений, возникающих в теории вязкоупругости",
      "organization": "МГУ им. М.В.Ломоносова",
      "from": "Москва"
    },
    {
      "name": "Родин А.С.",
      "body": "Об одном случае уравнения Гамильтона-Якоби и репрезентативной формуле его минимаксного решения",
      "organization": "ИММ УрО РАН",
      "from": "Екатеринбург"
    },
    {
      "name": "Сабитов К.Б.",
      "body": "Колебания круглой пластины при шарнирном закреплении края",
      "organization": "Институт математики с вычислительным центром УФИЦ\nРАН, Стерлитамакский филиал Уфимского университета\nнауки и технологий",
      "from": "Стерлитамак"
    },
    {
      "name": "Сагитова А.Р., Асылгужина Н.Ю., Марданов Б.И.",
      "body": "Асимптотическое поведение решений двучленных дифференциальных уравнений четного порядка с осциллирующими коэффициентами",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Садриева Р.Т.",
      "body": "Асимптотика решений некоторой системы дифференциальных уравнений в случае кратных собственных значений",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Сазонов Е.О., Хабибуллин И.Л.",
      "body": "Использование граничного условия третьего рода при моделировании билинейного потока жидкости к вертикальной трещине гидроразрыва с учетом измененных свойств малой зоны пласта",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Сакс Р.С.",
      "body": "Пространства Соболева-Вейля потенциальных и вихревых полей. Применения.",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Салимова А.Е., Хабибуллин Б.Н.",
      "body": "Рост субгармонических функций вдоль прямой и распределение их мер Рисса",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Сафаров Д.С., Абдулвохиди О.",
      "body": "Двоякопериодические решения одной нелинейной эллиптической системы уравнений второго",
      "organization": "Бохтарский государственный университет,",
      "from": "Бохтар"
    },
    {
      "name": "Сафаров Д.С., Миратов С.К.",
      "body": "Точное положительное ограниченное и периодическое решение для одного нелинейного одномерного псевдопараболического уравнения",
      "organization": "Бохтарский государственный университет",
      "from": "Бохтар"
    },
    {
      "name": "Сафиуллин А.Р.",
      "body": "Устойчивость перегретой пузырьковой жидкости",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Седов А.И.",
      "body": "О задаче восстановления непрерывного запаздывания по спектральным данным",
      "organization": "Уральский государственный экономический университет",
      "from": "Магнитогорск"
    },
    {
      "name": "Сидельников А.В., Шарипова Л.Д., Черняева Е.Ю., Муртазина Р.Д., Биткулов М.Д., Юмагулов М. Г., Васенина Н. А.",
      "body": "Применение вольамперных временных рядов в моноторинге качества молочной продукции",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Сио А.",
      "body": "Оценки отклонения приближенного решения от точного в задаче с тонким препятствием для эллиптического оператора второго порядка",
      "organization": "РУДН",
      "from": "Москва"
    },
    {
      "name": "Сипатдинова Б.К., Джамалов С.З.",
      "body": "О нелокальной краевой задаче для трёхмерного уравнения смешанного типа второго рода второго порядка в неограниченном параллелепипеде",
      "organization": "Институт Математики АН Руз",
      "from": "Ташкент"
    },
    {
      "name": "Сираева Д.Т.",
      "body": "Инвариантное решение с коллапсом для уравнений газовой динамики со специальным уравнением состояния из 1-параметрической 3-мерной подалгебры",
      "organization": "Институт механики им. Р.Р. Мавлютова УФИЦ РАН,",
      "from": "Уфа"
    },
    {
      "name": "Сметанников О.Ю., Ильиных Г.В., Фасхутдинова Ю.Б.",
      "body": "Модель термомеханического поведения фотополимеров с памятью формы в условиях терморелаксационного перехода",
      "organization": "ПНИПУ",
      "from": "Пермь"
    },
    {
      "name": "Солдатов О.А.",
      "body": "О некоторых классах коэффициентных обратных задачах для параболических уравнений",
      "organization": "Югорский государственный университет",
      "from": "Ханты-Мансийск"
    },
    {
      "name": "Солиев Ю.С.",
      "body": "О квадратурных формулах с кратными узлами для гиперсингулярного интеграла по действительной оси",
      "organization": "МАДИ",
      "from": "Москва"
    },
    {
      "name": "Солонуха О.В.",
      "body": "О существовании решений существенно нелинейных эллиптических дифференциальных уравнений с нелокальными краевыми условиями типа Бицадзе-Самарского",
      "organization": "ФИЦ ИУ РАН",
      "from": "Москва"
    },
    {
      "name": "Степанова И.В., Зотов И.Н., Магденко Е.П.",
      "body": "Построение и анализ точных решений задач моделирования слабого испарения бинарной смеси",
      "organization": "Институт вычислительного моделирования СО РАН",
      "from": "Красноярск"
    },
    {
      "name": "Сулейманов Б.И.",
      "body": "Кривые нулей L–A- пар и линеаризумые обыкновенные дифференциальные уравнения",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Сулейменов К.М., Есмаханова К.Р., Закариева З.А.",
      "body": "(2+1)-мерные солитонные решения нелокальных нелинейных уравнении Шредингера",
      "organization": "ЕНУ имени Л.Н. Гумилева",
      "from": "Астана"
    },
    {
      "name": "Султанаев Я.Т., Назирова Э.В.",
      "body": "О новых асимптотических формулах решений уравнения Штурма-Лиувилля с осциллирующим потенциалом",
      "organization": "БГПУ",
      "from": "Уфа"
    },
    {
      "name": "Сучкова Д.А.",
      "body": "Уравнение Кортевега - де Фриза с шумом в дисперсионном члене",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Талбаков Ф.М.",
      "body": "Об абсолютной сходимости двойных рядов Фурье",
      "organization": "ТГПУ",
      "from": "Душанбе"
    },
    {
      "name": "Тахиров Ж.О.",
      "body": "О математической модели со свободной границей зависимости «доза-реакция» противоопухолевых препаратов",
      "organization": "ИМ АН РУз",
      "from": "Ташкент"
    },
    {
      "name": "Ташпулатов С.М.",
      "body": "Спектр оператора энергии двухмагнонных систем с трехспиновым обменным гамильтонианом",
      "organization": "ИЯФ АН Руз",
      "from": "Ташкент"
    },
    {
      "name": "Ткачев В.И.",
      "body": "Исследование влияния коэффициента температурного расширения на максимальные термические напряжения в изделиях сложной геометрической формы",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Томаев М.Р., Тотиева Ж.Д.",
      "body": "Двумерная задача определения двух коэффициентов в уравнении вязкоупругоcти",
      "organization": "СОГУ",
      "from": "Владикавказ"
    },
    {
      "name": "Турсунов Ф.Р., Шодиев Д.С.",
      "body": "On the regularization of the solution of the Cauchy problem for the biharmonic equation on R^3",
      "organization": "Самаркандский государственный университет им.Шарофа Рашидова",
      "from": "Самарканд"
    },
    {
      "name": "Тухлиев Д.К.",
      "body": "О совместном приближении функций и ее производных полиномами Чебышева",
      "organization": "Худжандский государственный университет имени академика Б.Гафурова",
      "from": "Худжанд"
    },
    {
      "name": "Тухлиев Д.К.",
      "body": "Точные теоремы для скорости сходимости сумм Фейера",
      "organization": "Худжандский государственный университет имени академика Б.Гафурова",
      "from": "Худжанд"
    },
    {
      "name": "Тухлиев Д.К., Бекназаров Дж.Х.",
      "body": "Оптимальные приближения функций суммами Фурье-Чебышева в пространстве L2,µ[−1; 1]",
      "organization": "Худжандский государственный университет имени академика Б.Гафурова",
      "from": "Худжанд"
    },
    {
      "name": "Уткин А.",
      "body": "Полугрупповой подход к описанию стохастической динамики квантовой системы",
      "organization": "МФТИ",
      "from": "Москва"
    },
    {
      "name": "Фадеева О.В.",
      "body": "Обратные задачи по определению правой части уравнения колебаний балки",
      "organization": "Самарский государственный технический университет,",
      "from": "Самара"
    },
    {
      "name": "Фазуллин З.Ю.",
      "body": "О необходимом и достаточном условии в теории регуляризованных следов",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Файрузов М.Э., Лубышев Ф.В., Болотнов А.М.",
      "body": "Математические модели совместного расчёта электрических и тепловых полей в электрохимических системах\nв электролитах",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Фаттахов С.Р.",
      "body": "Исследование динамики волн давления в канале с пузырьковым кластером",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Федоров В.Е., Годова А.Д.",
      "body": "Генераторы аналитических разрешающих семейств интегро-дифференциальных уравнений типа Римана - Лиувилля",
      "organization": "ЧелГУ",
      "from": "Челябинск"
    },
    {
      "name": "Федоров К.М., Гильманов А.Я., Шевелёв А.П.",
      "body": "Алгоритм интерпретации параметров канала низкого фильтрационного сопротивления",
      "organization": "Тюменский государственный университет",
      "from": "Тюмень"
    },
    {
      "name": "Филин Н.В., Федоров В.Е.",
      "body": "Задача Коши для одного класса квазилинейных уравнений с распределенной дробной производной Герасимова - Капуто, заданной интегралом Стилтьеса",
      "organization": "ЮГУ; ЧелГУ",
      "from": "Ханты-Мансийск; Челябинск"
    },
    {
      "name": "Хабибуллин И.Т., Кузнецова М.Н., Хакимова А.Р.",
      "body": "Интегрируемые нелинейные дискретные уравнения с тремя независимыми переменными",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Хабибуллин И.Т., Хакимова А.Р.",
      "body": "Преобразования типа Миуры для нелинейных цепочек в 3D",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Хабибуллин И.Т., Хакимова А.Р.",
      "body": "Алгебраические редукции дискретных уравнений типа Хироты-Мивы",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Хайруллоев Ш.А.",
      "body": "Применение алгоритма оптимизации экспоненциальных пар к исследованию нулей производной функции Харди",
      "organization": "Таджикский национальный университет",
      "from": "Душанбе"
    },
    {
      "name": "Хакимов Д.Р., Аминова А.В.",
      "body": "Проективные симметрии 5-мерных жестких H-пространствах типа {32}",
      "organization": "КФУ",
      "from": "Казань"
    },
    {
      "name": "Харук Н.В., Иванов А.В.",
      "body": "Свойства упорядоченной экспоненты и их приложение в теории поля",
      "organization": "ПОМИ РАН",
      "from": "Санкт-Петербург"
    },
    {
      "name": "Хасанов А.Б., Нормуродов Х.Н.,Эшбеков Р.Х.",
      "body": "Интегрирование модифицированного уравнения Кортевега-де Фриза-Лиувилля-синус Гордона (мКдФ-Л-сГ) с дополнительным членом в классе периодических бесконечнозонных функций",
      "organization": "Самаркандский государственный университет",
      "from": "Самарканд"
    },
    {
      "name": "Хасанов Ю.Х., Талбаков Ф.М., Сафарзода Э.Х.",
      "body": "О достаточных условиях абсолютной сходимости двойных рядов Фурье",
      "organization": "РТСУ; ТГПУ",
      "from": "Душанбе"
    },
    {
      "name": "Хокиев Д.Д.",
      "body": "Оценка двойных сумм значений характера Дирихле",
      "organization": "ТНУ",
      "from": "Душанбе"
    },
    {
      "name": "Худойкулов Ш.Ш., Джамалов С.З.",
      "body": "О линейной двухточечной обратной задаче для многомерного волнового уравнения с нелокальными граничными условиями периодического типа",
      "organization": "Институт Математики АН Руз",
      "from": "Ташкент"
    },
    {
      "name": "Чубатов А.А.",
      "body": "Стохастическая интерпретация решения полностью нелинейного параболического уравнения",
      "organization": "НТУ Сириус",
      "from": ""
    },
    {
      "name": "Шабалин П.Л.",
      "body": "Краевая задача Римана для обобщенных аналитических функций со\nсверхсингулярными точками на контуре краевого условия",
      "organization": "Казанский государственный архитектурно-строительный университет",
      "from": "Казань"
    },
    {
      "name": "Шавлуков А.М.",
      "body": "О катастрофах решений уравнений одномерной газовой динамики в случаях Чаплыгина и Бехерта-Станюковича",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Шайхиев Э.Р., Муртазина Р. Д. , Низамова А. Д., Сидельникова Н. А., Галина Г. К.",
      "body": "Уравнение Шредингера и законы сохранения",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Шакирзянов Ф.Р., Каюмов Р.А.",
      "body": "Большие прогибы, потеря устойчивости и закритическое поведение пологих панелей и арок с учетом поперечного сдвига",
      "organization": "Казанский государственный архитектурно-строительный университет",
      "from": "Казань"
    },
    {
      "name": "Шамсудинов Ф.М., Бобоназаров И.О.",
      "body": "Интегральные представления решений для одной системы дифференциальных уравнений в частных производных второго порядка с одной внутренной сингулярной линией",
      "organization": "Бохтарский государственный университет,",
      "from": "Бохтар"
    },
    {
      "name": "Шамсудинов Ф.М., Валиев Р.С.",
      "body": "Интегралные представления решений для одной системы дифференциальных уравнений в частных производных второго порядка с двумя внутренными сингулярными линиями",
      "organization": "Бохтарский государственный университет,",
      "from": "Бохтар"
    },
    {
      "name": "Шарафутдинов В.А.",
      "body": "Геометрия идеальной жидкости",
      "organization": "Институт математики им. С.Л. Соболева СО РАН,",
      "from": "Новосибирск"
    },
    {
      "name": "Шарипов Р.А.",
      "body": "Вселенная как 3D-брана без постулата эквидистантности",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Шарипов Б., Джумаев Э.Х.",
      "body": "Представления решений одной системы с сингулярными коэффициентами в комплексной плоскости",
      "organization": "ТГУЭФ, Филиал МГУ им. М.В. Ломоносова",
      "from": "Душанбе"
    },
    {
      "name": "Шарова А.В., Бибердорф Э.А.",
      "body": "Численное сравнение спектров системы Навье-Стокса и уравнения Орра-Зоммерфельда",
      "organization": "ИМ СО ПАН",
      "from": "Новосибирск"
    },
    {
      "name": "Шишкина О.Ю., Барановский Е.С.",
      "body": "Модель неизотермического течения неньютоновской жидкости с усредненной диссипативной функцией",
      "organization": "Воронежский государственный университет",
      "from": "Воронеж"
    },
    {
      "name": "Шмидт Е.С.",
      "body": "Радиус Бора для лийнено-инвариантных семейств аналитических функций",
      "organization": "Петрозаводский государственный университет",
      "from": "Петрозаводск"
    },
    {
      "name": "Эргашбоев Т.С., Воситова Д.А.",
      "body": "Об инвариантных обыкновенных дифференциальных уравнениях, заданных групп преобразований",
      "organization": "Худжандский государственный университет им. Б.Гафурова",
      "from": "Худжанд"
    },
    {
      "name": "Эфендиев Б.",
      "body": "Начальная задача для обыкновенного дифференциального уравнения второго порядка с оператором распределенного дифференцирования",
      "organization": "ИПМА КБНЦ РАН",
      "from": "Нальчик"
    },
    {
      "name": "Юлмухаметов Р.С., Мусин И.Л.",
      "body": "Описание сопряженных пространств к подпространствам бесконечно дифференцируемых функций",
      "organization": "ИМВЦ УФИЦ РАН",
      "from": "Уфа"
    },
    {
      "name": "Юмагулов М.Г., Ибрагимова Л.С.",
      "body": "Уравнения четных порядков и эквивалентные гамильтоновы системы",
      "organization": "УУНиТ",
      "from": "Уфа"
    },
    {
      "name": "Юрко В.А.",
      "body": "Inverse problems for differential operators with indefinite discontinuous\nweights",
      "organization": "СГУ",
      "from": "Саратов"
    },
    {
      "name": "Dzianis S.Z.",
      "body": "Symmetries and spectral parameter",
      "organization": "Белостокский государственный университет",
      "from": "Белосток"
    },
    {
      "name": "Volkan A.",
      "body": "Analytical Solutions of a stochastic fractional equation perturbed by the multiplicative Wiener process",
      "organization": "Mersin University",
      "from": "Mersin"
    }
  ]