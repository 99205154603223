import React from "react";
import Layout from "./hoc/Layout/Layout";
import { StylesProvider } from "@material-ui/core/styles";
import { Switch, Route, withRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Sections from "./pages/Sections/Sections";
import RegisterOnSec from "./pages/RegisterSection/RegisterOnSec";
import Commitet from "./pages/Сommitet/Commitet";
import CommitetOrg from "./pages/СommitetOrg/СommitetOrg";
import Fees from "./pages/Fees/Fees";
import News from "./pages/News/News";
import Application from "./pages/Application/Application";
import Accommodation from "./pages/Accommodation/Accommodation";
import Program from "./pages/Program/Program";
import Guide from "./pages/Guide/Guide";
import Сontact from "./pages/Сontacts/Contacts";
import Members from "./pages/Members";
import PastConf from "./pages/PastConf/PastConf";
import Collection from "./pages/Collection/Collection";
import StendReport from "./pages/StendReport/StendReport";
import Archive from "./pages/Archive/Archive";
import ZoomLinks from "./pages/ZoomLinks/ZoomLinks";

function App({ loggedIn, emailVerified, isAdmin }) {
  let routes;
  routes = (
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/sections" component={Sections} />
      <Route path="/commitet" component={Commitet} />
      <Route path="/orgcommitet" component={CommitetOrg} />
      <Route path="/program" component={Program} />
      <Route path="/registeronsection" component={RegisterOnSec} />
      <Route path="/fees" component={Fees} />
      <Route path="/news" component={News} />
      <Route path="/guide" component={Guide} />
      <Route path="/contact" component={Сontact} />
      <Route path="/application" component={Application} />
      <Route path="/accommodation" component={Accommodation} />
      <Route path="/members" component={Members} />
      <Route path="/pastconf" component={PastConf} />
      <Route path="/collection" component={Collection} />
      <Route path="/stendreport" component={StendReport} />
      <Route path="/archive" component={Archive} />
      <Route path="/zoom-links" component={ZoomLinks} />
      <Route path="/" exact component={Home} />
    </Switch>
  );
  return (
    <StylesProvider injectFirst>
      <Layout
        loggedIn={loggedIn}
        emailVerified={emailVerified}
        isAdmin={isAdmin}
      >
        {routes}
      </Layout>
    </StylesProvider>
  );
}

export default withRouter(App);
