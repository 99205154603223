import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { YEAR } from "../../constants";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    cursor: "pointer",
  },
  menu: {
    display: "none",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Navbar = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  let history = useHistory();
  return (
    <>
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={props.sideHandler}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            onClick={() => {
              history.push("/");
            }}
            variant="h6"
            className={classes.title}
          >
            {matches ? "УОМШ" : `УФИМСКАЯ ОСЕННЯЯ МАТЕМАТИЧЕСКАЯ ШКОЛА — ${YEAR}`}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

Navbar.propTypes = {
  auth: PropTypes.bool,
  sideHandler: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

export default Navbar;
