
import React from 'react'

import i0 from "../../assets/doclads22/Арабов М.К..pdf";
import i1 from "../../assets/doclads22/Dosmagulova K..pdf";
import i2 from "../../assets/doclads22/Hannachi M., Guebbai H..pdf";
import i3 from "../../assets/doclads22/Абушахмина Г.Р..pdf";
import i4 from "../../assets/doclads22/Айсагалиев С., Корпебай Г.Т..pdf";
import i5 from "../../assets/doclads22/Александрова Е.А. Шамматова А.А., Башмаков Р.А..pdf";
import i6 from "../../assets/doclads22/Байзаев С.,  Баротов  Р.Н..pdf";
import i7 from "../../assets/doclads22/Барабанов Е.А., Быков В.В..pdf";
import i8 from "../../assets/doclads22/Гребенникова И.В..pdf";
import i9 from "../../assets/doclads22/Духновский С.А..pdf";
import i10 from "../../assets/doclads22/Литвинов В.Л. Литвинова К.В..pdf";
import i11 from "../../assets/doclads22/Морева М.А., Орлов С.С..pdf";
import i12 from "../../assets/doclads22/Мустафина И.Ж..pdf";
import i13 from "../../assets/doclads22/Невский М.В..pdf";
import i14 from "../../assets/doclads22/Нормуродов Х.Н., Хасанов А.Б., Худаеров У.О..pdf";
import i15 from "../../assets/doclads22/Олими А.Г., Охунов Н.К..pdf";
import i16 from "../../assets/doclads22/Салимова А.Е..pdf";
import i17 from "../../assets/doclads22/Саматов Б.Т., Akbarov A.Kh..pdf";
import i18 from "../../assets/doclads22/Саматов Б.Т., Juraey B.I..pdf";
import i19 from "../../assets/doclads22/Сафаров Дж..pdf";
import i20 from "../../assets/doclads22/Султанова А.А., Махота А.А., Яхина К.Р.pdf";
import i21 from "../../assets/doclads22/Суяргулова Э.Э..pdf";
import i22 from "../../assets/doclads22/Таран А.Е..pdf";
import i23 from "../../assets/doclads22/Тасевич А.Л..pdf";
import i24 from "../../assets/doclads22/Федоров В.Е..pdf";
import i25 from "../../assets/doclads22/Хакимов А.Г..pdf";
import i26 from "../../assets/doclads22/Шамсудинов Ф.М., Валиев Р.С..pdf";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core";

function createData(name, title, from, city, href) {
  return { name, title, from, city, href };
}
const rows = [
  createData(
    "Dosmagulova K.",
    "Solvability of the Laplace-Beltrami operator on a two-dimensional sphere",
    "Al-Farabi Kazakh National University",
    "Almaty",
    i1
  ),
  createData(
    "Hannachi Marwa ",
    "Modelling an earthquake using a bi-nonlinear Volterra integral equation",
    "Laboratoire de Mathématiques Appliquées et de Modélisation , University 8 mai 1945 , Guelma",
    "Algeria",
    i2
  ),
  createData(
    "Абушахмина Г.Р.",
    "Бифуркации в  распределенных моделях, описывающих реакцию Белоусова-Жаботинского",
    "БГМУ",
    "Уфа",
    i3
  ),
  createData(
    "Корпебай Г.Т., Айсагалиев С.",
    "Оптимальное быстродействие линейных систем с фазовыми и интегральными ограничениями",
    "КазНУ",
    "Алматы",
    i4
  ),
  createData(
    "Александрова Е.А., Шамматова А.А., Башмаков Р.А.",
    "Приближенное решение задачи о связи между давлением и дебитом нефтяной скважины при наличии трещины ГРП",
    "БашГУ, ИМех УФИЦ РАН",
    "Уфа",
    i5
  ),
  createData(
    "Арабов М.К.",
    "О существовании предельных циклов дифференциальных уравнений с кусочно-линейными правыми частями",
    "КФУ",
    "Казань",
    i0
  ),
  createData(
    "Байзаев С.,  Баротов  Р.Н.",
    "Общее решение многомерной системы, обобщающая уравнение Бицадзе",
    "ТГУПБП",
    "Худжанд, Таджикистан",
    i6
  ),
  createData(
    "Барабанов Е.А., Быков В.В.",
    "Полное описание показателя Перрона линейной дифференциальной системы с неограниченными коэффициентами",
    "Институт математики НАН Беларуси; МГУ им. М.В.Ломоносова",
    "Минск; Москва",
    i7
  ),
  createData(
    "Гребенникова И.В.",
    "К задаче управления сингулярно возмущенной системой с запаздыванием при геометрических ограничениях",
    "УГГУ",
    "Екатеринбург",
    i8
  ),
  createData(
    "Духновский С.А.",
    "Групповой анализ дискретной системы Карлемана",
    "НИУ МГСУ",
    "Москва",
    i9
  ),
  createData(
    "Литвинов В.Л.",
    "Stochastic longitudinal vibrations viscoelastic beam with moving boundaries",
    "МГУ им. М.В. Ломоносова, СамГТУ",
    "Москва, Самара",
    i10
  ),
  createData(
    "Морева М.А., Орлов С.С.",
    "Вещественнозначные общие решения разностных функциональных уравнений",
    "ИГУ",
    "Иркутск",
    i11
  ),
  createData(
    "Мустафина И.Ж.",
    "Числовые характеристики свойств гиперболичности точек равновесия нелинейных автономных систем",
    "Учалинский колледж горной промышленности",
    "Учалы",
    i12
  ),
  createData(
    "Невский М.В.",
    "О константах эквивалентности для модулей непрерывности различного вида",
    "ЯрГУ им. П.Г. Демидова",
    "Ярославль",
    i13
  ),
  createData(
    "Хасанов А.Б., Нормуродов Х.Н., Худоёров У.О.",
    "Задачи Коши для нелинейного уравнения типа синус-Гордона в классе периодических  бесконечнозонных функций",
    "СамГУ, СамГАСИ",
    "Самарканд",
    i14
  ),
  createData(
    "Олими А.Г., Охунов Н.К.",
    "Общее представление решений и задачи Коши-Рикье для одного операторно-дифференциального уравнения с тремя сингулярными точками",
    "ХГУ",
    "Худжанд",
    i15
  ),
  createData(
    "Салимова А.Е.",
    "Теорема единственности для целых функций экспоненциального типа",
    "БашГУ",
    "Уфа",
    i16
  ),
  createData(
    "Samatov B.T., Akbarov A.Kh.",
    "Pursuit differential game under Gr’onwall type constraints on controls",
    "Namangan State University, Andijan State University",
    "Namangan, Andijan",
    i17
  ),
  createData(
    "Samatov B.T., Juraey B.I.",
    "Dynamics of attainability domain for inertial motions with integro-geometric constraints",
    "Namangan State University, Andijan State University",
    "Namangan, Andijan",
    i18
  ),
  createData(
    "Сафаров Д.С.",
    "Точное двоякопериодическое решение одной нелинейной эллиптической системы уравнений второго порядка с демпфированными членами",
    "Бохтарский государственный университет",
    "Бохтар",
    i19
  ),
  createData(
    "Султанова А.А., Махота А.А., Яхина К.Р",
    "Задача об опрессовке нефтяной скважины для диагностики трещин ГРП",
    "БашГУ",
    "Уфа",
    i20
  ),
  createData(
    "Суяргулова Э.Э.",
    "Уточнение параметра вовлечения окружающей воды для математической модели течения затопленной струи",
    "СФ БашГУ",
    "Стерлитамак",
    i21
  ),
  createData(
    "Таран А.Е., Кажарова И.А., Фоменко Н.А.",
    "Функция влияния света в модели динамики биомассы фитопланктона, математическое моделирование",
    "ИКТИБ ЮФУ; НИУ им. И.М. Губкина",
    "Таганрог; Москва",
    i22
  ),
  createData(
    "Тасевич А.Л.",
    "О сильно эллиптическом функционально-дифференциальном уравнении с ортотропными сжатиями",
    "ФИЦ ИУ РАН, РУДН",
    "Москва",
    i23
  ),
  createData(
    "Федоров В.Е.",
    "Нелокальная краевая задача для уравнения смешанно-составного типа",
    "СВФУ",
    "Якутск",
    i24
  ),
  createData(
    "Хакимов А.Г.",
    "К статической устойчивости формы поперечного сечения трубопровода, цилиндрической оболочки, углеродной нанотрубки",
    "ИМех УФИЦ РАН",
    "Уфа",
    i25
  ),
  createData(
    "Шамсудинов Ф.М., - Валиев Р.С.",
    " Об одной переопределенной системе дифференциальных уравнений второго порядка с двумя внутренными сверхсингулярными линиями",
    "Бохтарский государственный университет",
    "Бохтар",
    i26
  ),
];
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
const Doclad2020 = () => {
  const classes = useStyles();
  return (
    <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">ФИО</TableCell>
              <TableCell align="left">Организация</TableCell>
              <TableCell align="right">Название доклада</TableCell>
              <TableCell align="right">Город</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.title}</TableCell>
                <TableCell align="right">{row.from}</TableCell>
                <TableCell align="right">{row.city}</TableCell>
                <TableCell align="right">
                  <a href={row.href}>Просмотреть</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
  )
}

export default Doclad2020