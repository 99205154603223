import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LayoutPage from '../../hoc/LayoutPage/LayoutPage';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 60
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: theme.typography.fontWeightRegular
	}
}));



const Sections = () => {    
    const classes = useStyles();
    const data = [
        {headling: 'Спектральная теория операторов', body: 'Исследования по спектральной теории операторов охватывают проблемы спектральной устойчивости дифференциальных операторов, связанных с краевыми задачами при варьировании области их определения, а также проблемы сходимости и суммируемости спектральных разложений по собственным функция дифференциальных операторов, таких как оператор Лапласа в многомерной области.'},
        {headling: 'Комплексный и функциональный анализ', body: 'Исследование динамических характеристик системы путем определения процессов изменения ее состояния с течением времени на основании принятых алгоритмов функционирования. Объектами исследования функционального анализа являются реализуемые системой методы и алгоритмы управления.'},
        {headling: 'Нелинейные уравнения', body: 'Подраздел теории управления, изучающий процессы управления в нелинейных системах. Поведение нелинейных систем не может быть описано линейными функциями состояния или линейными дифференциальными уравнениями.'},
        {headling: 'Математическое моделирование', body: 'Идеальное научное знаковое формальное моделирование, при котором описание объекта осуществляется на языке математики, а модели проводится с использованием тех или иных математических методов'},
        {headling: 'Дифференциальные уравнения и их приложения', body: ''},
    ]
    const ExpPanel = ({headling, body}) => (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography  component='div' className={classes.heading}><Box fontWeight={600}>{headling}</Box></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography>
                    {body}
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

	return (
        <LayoutPage headling='Тематические направления' subheading='Научная программа конференции представлена следующими направлениями:'>
            <>
            { data.map((e) => <ExpPanel key={e.headling.slice(0,3)} headling={e.headling} body={e.body} />) }
            </>
        </LayoutPage>		
	);
};

export default Sections;
