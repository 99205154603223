import React from 'react'
import Typography from '@material-ui/core/Typography'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import tesizPDF from '../../assets/tezis23/tezis.pdf'
import tesizTEX from '../../assets/tezis23/tezis.tex'
import { YEAR } from '../../constants.js'
import { importantDates } from '../Home/Home'

const Application = () => {
    return (
        <LayoutPage headling='Требования к тезисам'>
            <>
                <Typography variant='body1' paragraph>Тезисы докладов следует подготовить с использованием издательской системы LaTeX. Шаблон и пример оформления в приложенном файле. Тезисы (в виде двух файлов: tex и pdf) подаются на русском или английском языке и не должны превышать две полные страницы формата А4.</Typography>
                <Typography variant='body1' paragraph><strong>Тезисы должны быть присланы {importantDates.register}</strong></Typography>
                <Typography variant='body1' paragraph>Материалы конференции будут опубликованы к открытию конференции.</Typography>
                <ul>
                    <li><a href={tesizPDF}>Пример оформления тезисов в PDF</a></li>
                    <li><a href={tesizTEX}>Пример оформления тезисов в TEX</a></li>
                </ul>

            </>
        </LayoutPage>
    )
}

export default Application
