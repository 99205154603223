import React from "react";
import LayoutPage from "../../hoc/LayoutPage/LayoutPage";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { YEAR } from "../../constants";

function Fees() {
  return (
    <LayoutPage>
      <div>
        <Box fontWeight={800}>
          <Typography variant="h3" align="center" paragraph component="h1">
            Правила оплаты организационных взносов
          </Typography>
        </Box>

        <Box>
          <ul style={{ listStyleType: "decimal" }}>
            <li>
              <Typography variant="body1" paragraph>
                Организационный взнос для участников конференции равен 2000
                руб.; участие студентов и аспирантов бесплатное.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" paragraph>
                Тезисы докладов и статьи будут представлены к опубликованию
                только при условии поступления оргвзноса.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" paragraph>
                Корешок квитанции об оплате оргвзноса следует привезти с собой
                или отправить сканированную копию по адресу оргкомитета
                ufa.mat.sh@mail.ru . При невозможности оплаты в банке Вы можете
                оплатить оргвзнос непосредственно в УУНиТ во время регистрации
                (об этом следует сообщить оргкомитету не позднее
                <strong> 15 сентября {YEAR} г.</strong>).
              </Typography>
            </li>
          </ul>

          <Typography variant="h5" align="center" paragraph component="h2">
            <strong>Реквизиты организации для оплаты оргвзноса:</strong>
          </Typography>
          <Typography variant="body1">
            Наименование организации в платежных документах: ООО «ВинТех»
          </Typography>
          <Typography variant="body1">ИНН: 0278107476</Typography>
          <Typography variant="body1">
            Расчетный счет № <strong>40702810623620000096</strong> в ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ"
            БАНКА ВТБ (ПАО)
          </Typography>
          <Typography variant="body1">
            Кор. счет: 30101810145250000411
          </Typography>
          <Typography variant="body1">БИК 044525411</Typography>
          <Typography variant="body1" paragraph>
            В назначении платежа <strong>ОБЯЗАТЕЛЬНО!</strong> указать: Ф.И.О.,
            город, за участие в конференции «УОМШ-24», без налога (НДС).
          </Typography>
        </Box>
      </div>
    </LayoutPage>
  );
}

export default Fees;
