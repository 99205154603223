import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

let theme = createMuiTheme({
  palette: {    
    secondary: {
      main: '#f44336',
    },
  },  
});

theme = responsiveFontSizes(theme);



const app = (
      <BrowserRouter>
        <ThemeProvider theme={theme}>   
        <App />    
        </ThemeProvider>
      </BrowserRouter> 
)


ReactDOM.render(app,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
