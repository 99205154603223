import React from 'react'
import Typography from '@material-ui/core/Typography'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'


const Guide = () => {    
    return (
        <LayoutPage headling='Как подать заявку на участие?'>
             <>
                <Typography variant='body1' paragraph>Регистрация производится с помощью общероссийского сервиса научных конференций "Ломоносов" . Регистрация проводится в два этапа.</Typography>
                <Typography variant='body1' paragraph>- На первом этапе (после нажатия кнопки «Регистрация»)  Вы попадаете на указанный сервис и после нажатия еще одной кнопки  «Регистрация» производите свою регистрацию. При правильной регистрации Вы получите на свой e-mail сообщение, пользуясь которым следует активировать свою регистрацию. В результате Вы получите свою страничку на  сервисе  "Ломоносов" . </Typography>
                <Typography variant='body1' paragraph>- На втором этапе  Вы должны зайти на свою страничку на указанном сервисе. Для этого можно снова воспользоваться кнопкой «Регистрация».  Оказавшись на  сервисе "Ломоносов" , следует нажать  кнопку  «Вход». В результате Вы   попадете на свою страничку. Затем выбираете вкладку «Мои заявки» и среди предложенных Вам  конференций выберите нашу конференцию «УОМШ».  Нажмите вкладку «Подать заявку» и подавайте заявку:  автор, секция, название доклада, тезисы в формате tex  и  pdf.  При правильных действиях  Вы получите на свою почту сообщение о принятой заявке.
</Typography>
                <Typography variant='body1' paragraph>В случае проблем с указанной регистрацией Вы можете зарегистрироваться и подать заявку, отослав следующую информацию на e-mail конференции:  ufa.mat.sh@mail.ru</Typography>
               <ol>
                   <li>
                       <Typography>Сведения об авторе (соавторах): ФИО, ученая степень, ученое  звание, место работы, должность.</Typography>
                   </li>
                   <li>
                       <Typography>Название доклада, секция.</Typography>
                   </li>
                   <li>
                       <Typography>Тезисы (два файла: в формате tex  и  pdf ).</Typography>
                   </li>
               </ol>
                
             </>
        </LayoutPage>
    )
}

export default Guide
