import React from "react";
import LayoutPage from "../../hoc/LayoutPage/LayoutPage";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import a from "../../assets/logo.png";
import b from "../../assets/logo.svg";
import c from "../../assets/rlogo.png";
import { Link } from "@material-ui/core";
import { TIMING, YEAR } from "../../constants";

export const importantDates = {
  register: `до 15 сентября ${YEAR} г.`,
  notify: `до 15 сентября ${YEAR} г.`,
  startConf: `2 октября ${YEAR} г.`,
  endConf: `5 октября ${YEAR} г.`
}

function Home() {
  return (
    <LayoutPage>
      <div>
        <Box fontWeight={800}>
          <Typography variant="h5" align="center" component="h1">
            МЕЖДУНАРОДНАЯ НАУЧНАЯ КОНФЕРЕНЦИЯ
          </Typography>
          <Typography variant="h4" align="center" component="h1" paragraph>
            "УФИМСКАЯ ОСЕННЯЯ МАТЕМАТИЧЕСКАЯ ШКОЛА — {YEAR}"
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1" paragraph>
            Уфимский университет науки и технологий, Институт математики с ВЦ УФИЦ РАН (г. Уфа,
            Россия) и НОМЦ Приволжского федерального округа
            <Box component="span" fontWeight="fontWeightBold">
              {" "}{TIMING}{" "}
            </Box>
            проводят Международную научную конференцию «УФИМСКАЯ ОСЕННЯЯ
            МАТЕМАТИЧЕСКАЯ ШКОЛА — {YEAR}». Место проведения – Институт информатики, математики и робототехники УУНиТ (г. Уфа, ул. З. Валиди 32а).
          </Typography>
          <Typography variant="body1">
            Регламент конференции предполагает
            <Box component="span" fontWeight="fontWeightBold">
              {" "}пленарные,{" "}
            </Box>
            <Box component="span" fontWeight="fontWeightBold">
              {" "}секционные{" "}
            </Box>
            и
            <Box component="span" fontWeight="fontWeightBold">
              {" "}стендовые{" "}
            </Box>
            доклады.
          </Typography>
          <Typography variant="body1" paragraph>Важное место в работе школы займут обзорные лекции ведущих
            ученых для аспирантов и молодых ученых.</Typography>
          <Typography variant="subtitle1" paragraph>
            Научная программа конференции охватывает следующие направления:
          </Typography>
          <ul>
            <li>
              <Typography>спектральная теория операторов;</Typography>
            </li>
            <li>
              <Typography>комплексный и функциональный анализ;</Typography>
            </li>
            <li>
              <Typography>нелинейные уравнения;</Typography>
            </li>
            <li>
              <Typography>дифференциальные уравнения и приложения;</Typography>
            </li>
            <li>
              <Typography>математическое моделирование.</Typography>
            </li>
          </ul>
          <Typography variant="body1" paragraph>
            Для участия в работе конференции необходимо зарегистрироваться,
            подать тезисы доклада и оплатить оргвзнос{" "}
            <strong>{importantDates.register}</strong>. Участие студентов и
            аспирантов бесплатное. Все принятые тезисы будут опубликованы в
            сборнике тезисов конференции и проиндексированы в РИНЦ. Кроме этого,
            тезисы будут проходить отбор для опубликования расширенного варианта
            доклада в виде научной статьи в журналах «Уфимский математический
            журнал», «Lobachevskii Journal of Mathematics» и «Математическое
            моделирование» (Scopus и Web of Scienes). Конференция будет
            проводиться в гибридном формате: для тех, кто сможет приехать в Уфу,
            конференция будет проводиться в классическом формате (очно), а тем,
            кто не сможет приехать, будет предложена возможность провести
            дистанционную презентацию.
          </Typography>
          <Box>
            <Typography fontWeight="fontWeightBold" variant="h6" paragraph>
              Важные даты
            </Typography>
          </Box>

          <Typography variant="h5" paragraph color='error'>
            Внимание!  Сроки регистрации и подачи тезисов продлены до  <strong>15 сентября 2024 г.</strong>
          </Typography>
          <Typography fontWeight="fontWeightBold" variant="subtitle1" paragraph>
            <Box component="span" fontWeight="fontWeightBold">
              {importantDates.register}
            </Box>
            - Регистрация и подача тезисов доклада
          </Typography>
          <Typography fontWeight="fontWeightBold" variant="subtitle1" paragraph>
            <Box component="span" fontWeight="fontWeightBold">
              {importantDates.notify}
            </Box>
            - Уведомление о включении доклада в программу конференции
          </Typography>
          <Typography fontWeight="fontWeightBold" variant="subtitle1" paragraph>
            <Box component="span" fontWeight="fontWeightBold">
              {importantDates.startConf}
            </Box>
            - дата начала конференции
          </Typography>
          <Typography fontWeight="fontWeightBold" variant="subtitle1" paragraph>
            <Box component="span" fontWeight="fontWeightBold">
              {importantDates.endConf}
            </Box>
            - дата окончание конференции
          </Typography>
          <Typography variant="h4" align="center" component="h1" paragraph>
            Партнёры
          </Typography>
          <Typography variant="body1" paragraph>
            Организационный и Программный комитеты Международной научной
            конференции «Уфимская осенняя математическая школа» выражают
            глубокую признательность и благодарность партнёрам за спонсорскую
            поддержку и помощь:
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-end",
            }}
          >
            <Box
              component="div"
              m={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={a} width={100} alt="ООО «Парус»" />
              <Typography variant="body1" paragraph>
                ООО «Парус»
              </Typography>
              <Link href="https://parus.com/">https://parus.com/</Link>
            </Box>
            <Box
              component="div"
              m={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={b} width={100} alt='ООО "ВинТех"' />
              <Typography variant="body1" paragraph>
                ООО "ВинТех"
              </Typography>
              <Link href="https://vinteh.ru/">https://vinteh.ru/</Link>
            </Box>
            <Box
              component="div"
              m={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={c} alt="ООО Компания права «Респект»" />
              <Typography variant="body1" paragraph>
                ООО Компания права «Респект»
              </Typography>
              <Link href="http://www.respectrb.ru/">
                http://www.respectrb.ru/
              </Link>
            </Box>
          </div>
        </Box>
      </div>
    </LayoutPage>
  );
}

export default Home;
