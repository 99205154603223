import React, { useEffect } from 'react'
// import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
// import RegisterSection from '../../containers/RegisterSection'
import { Progress } from '../../components/Progress/Progress'

const RegisterOnSec = () => {
    useEffect(() => {
        window.location.replace('https://lomonosov-msu.ru/rus/event/8925/')
    }, [])
    return (
        <Progress />
        // <LayoutPage headling='Подача заявки'>
        //     <RegisterSection/>
        // </LayoutPage>
    )
}


export default RegisterOnSec
