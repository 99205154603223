import { Typography } from '@material-ui/core'
import React from 'react'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import programmPDF from '../../assets/УОМШ-2020 Программа конференции.pdf'
import anotation1 from '../../assets/Аннотации_1.pdf'
import anotation2 from '../../assets/Аннотации_2.pdf'
import programm from '../../assets/Программа_УОМШ-21.pdf'
import programm22 from '../../assets/Программа конференции УОМШ-22new.pdf'
import programm23 from '../../assets/23/Программа УОМШ-2023.pdf'


import Members from '../Members/Members';
import Doclad2020 from './Doclad2020';
import Doclad2022 from './Doclad2022';
import Members2021 from '../Members/Members2021';
import Members2022 from '../Members/Members2022';
import Members2023 from '../Members/Members2023/Members2023';
import ExpansionPanelIndex from '../../components/ExpansionPanel/ExpansionPanel'

const Archive = () => {
    return (
        <>
            <LayoutPage headling='Архив'>
                <>
                    <Typography variant="h4">Программа секций</Typography>
                    <ul>
                        <li><a href={programmPDF}>УОМШ-2020 Программа конференции</a></li>
                        <li><a href={programm}>УОМШ-2021 Программа конференции</a></li>
                        <li><a href={programm22}>УОМШ-2022 Программа конференции</a></li>
                        <li><a href={programm23}>УОМШ-2023 Программа конференции</a></li>
                        <li><a href={anotation1}>Аннотация Часть 1</a></li>
                        <li><a href={anotation2}>Аннотация Часть 2</a></li>
                    </ul>
                    {/* <ExpansionPanelIndex body={<Members />} headling='Принятые заявки в 2020' /> */}
                </>
                <ExpansionPanelIndex body={<Doclad2020 />} headling='Доклады участников конференции 2020' />
                <ExpansionPanelIndex body={<Doclad2022 />} headling='Доклады участников конференции 2022' />
                <ExpansionPanelIndex body={<Members />} headling='Принятые заявки в 2020' />
                <ExpansionPanelIndex body={<Members2021 />} headling='Принятые заявки в 2021' />
                <ExpansionPanelIndex body={<Members2022 />} headling='Принятые заявки в 2022' />
                <ExpansionPanelIndex body={<Members2023 />} headling='Принятые заявки в 2023' />
            </LayoutPage>
        </>
    )
}

export default Archive