import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AlertUI from '../../components/AlertUI/AlertUI';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 60,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {
			padding: 15,
			marginTop: 10,
		}
	},
	container: {		
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(1),			
			paddingRight: theme.spacing(1),			
		}
	},
	heading: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(28)
		}
	},
	body: {
		padding: '30px 0',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			padding: 0
		}
	},
	subheading: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(14)
		}
	}
}));

function LayoutPage({ children, headling, subheading, error }) {
	const classes = useStyles();
	return (
		<Container maxWidth='lg' className={classes.container}>
			<Paper className={classes.paper}>
				{headling && <Typography component='div' variant='h3' paragraph className={classes.heading}>
					<Box fontWeight={700}>{headling}</Box>
				</Typography>}
				{subheading && (
					<Typography variant='body1' paragraph>
						{subheading}
					</Typography>
				)}
				<>{children}</>
			</Paper>
			{/* { error && <AlertUI type='error' clearError={clearMemberError} open={true} message={error} />} */}
			{/* { error === false && <AlertUI type='success' clearError={clearMemberError} open={true} message='Заявка успешно отправлена' />} */}
		</Container>
	);
}

LayoutPage.propTypes = {
	children: PropTypes.element.isRequired
};


export default LayoutPage;
