import React from 'react'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import programma from '../../assets/programma.pdf'
import sbornik from '../../assets/sbornik.pdf'
import collection1_20 from '../../assets/Сборник тезисов. Часть 1.pdf'
import collection2_20 from '../../assets/Сборник тезисов. Часть 2.pdf'
import collection1_21 from '../../assets/Тезисы_УОМШ-2021_Том_1.pdf'
import collection2_21 from '../../assets/Тезисы_УОМШ-2021_Том_2.pdf'
import collection1_22 from '../../assets/files22/Тезисы УОМШ-22 (1 том).pdf'
import collection2_22 from '../../assets/files22/Тезисы УОМШ-22 (2 том).pdf'
import collection1_23 from '../../assets/23/collectionTezis/Тезисы_УОМШ-2023, 1 том.pdf'
import collection2_23 from '../../assets/23/collectionTezis/Тезисы_УОМШ-2023, 2 том.pdf'

const PastConf = () => {
    return (
        <LayoutPage headling='Материалы прошлых конференций'>
            <>
                <ul>
                    <li><a href={sbornik}>Сборник тезисов УОМШ-19</a></li>
                    <li><a href={programma}>Программа УОМШ-19</a></li>
                </ul>
                <ul>
                    <li><a href={collection1_20}>Сборник тезисов УОМШ-20 часть 1</a></li>
                    <li><a href={collection2_20}>Сборник тезисов УОМШ-20 часть 2</a></li>
                </ul>
                <ul>
                    <li><a href={collection1_21}>Тезисы УОМШ-2021. Том 1</a></li>
                    <li><a href={collection2_21}>Тезисы УОМШ-2021. Том 2</a></li>
                </ul>
                <ul>
                    <li><a href={collection1_22}>Тезисы УОМШ-2022. Том 1</a></li>
                    <li><a href={collection2_22}>Тезисы УОМШ-2022. Том 2</a></li>
                </ul>
                <ul>
                    <li><a href={collection1_23}>Тезисы УОМШ-2023. Том 1</a></li>
                    <li><a href={collection2_23}>Тезисы УОМШ-2023. Том 2</a></li>
                </ul>

            </>
        </LayoutPage>
    )
}

export default PastConf
