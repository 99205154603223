import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import './Sidebar.css';
import { NavLink } from 'react-router-dom';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { shortName } from '../../constants';

const useStyles = makeStyles(theme => ({
	root: {
		width: 320
	},
	nested: {
		paddingLeft: theme.spacing(4),
		fontSize: '14px'
	},
	link: {
		textDecoration: 'none',
		color: 'rgb(0, 0, 0) '
	},
	title: {
		flexGrow: 1,
		marginLeft: '16px'
	},
	activeLink: {
		color: '#1976d2 !important'
	},
	mainLink: {
		textDecoration: 'none',
		color: theme.palette.text.primary,
		'&:visited': {
			color: theme.palette.text.primary
		}
	}
}));

function Sidebar(props) {
	const classes = useStyles();

	let navLink = [
		{ name: 'Главная', path: '/' },
		{
			name: 'О КОНФЕРЕНЦИИ',
			sublinks: [
				{ name: 'О конференции', path: 'about' },
				{ name: 'Тематические направления', path: 'sections' },
				{ name: 'Организационный комитет', path: 'orgcommitet' },
				{ name: 'Программный комитет', path: 'commitet' },
				{ name: 'Новости', path: 'news' },
				{ name: 'Материалы прошлых конференций', path: 'pastconf' },
				{ name: `Сборник тезисов ${shortName}`, path: 'collection' },
				{ name: 'Архив', path: 'archive' }
			]
		},
		{
			name: 'ПРОГРАММА',
			sublinks: [
				{ name: 'Программа конференции', path: 'program' },
				// { name: 'Видеотека (доклады на УОМШ-22)', path: 'zoom-links' },
			]
		},
		{
			name: 'СТЕНДОВЫЕ ДОКЛАДЫ',
			sublinks: [
				{ name: 'Доклады участников конференции', path: 'stendreport' },
			]
		},
		{
			name: 'УЧАСТНИКАМ',
			sublinks: [
				{ name: 'Регистрация', path: 'registeronsection' },
				{ name: 'Требования к тезисам', path: 'application' },
				{ name: 'Как подать заявку', path: 'guide' },
				{ name: 'Организационный взнос', path: 'fees' },
				{ name: 'Принятые заявки', path: 'members' },
			]
		},
		{
			name: 'ОРГАНИЗАЦИОННЫЕ ВОПРОСЫ',
			sublinks: [
				{ name: 'Проживание', path: 'accommodation' },
				{ name: 'Контакты', path: 'contact' }
			]
		},
		{
			name: 'ПЛОЩАДКИ КОНФЕРЕНЦИИ',
			sublinks: [{ name: 'Как добраться', path: 'venue' }]
		}
	];

	const [listState, setListState] = React.useState([true, true, true, true, true, true, true]);

	const handleClick = index => {
		const newList = [...listState];
		newList[index] = !listState[index];
		setListState(newList);
	};

	const SideMenuList = (
		<MenuList>
			{navLink.map((item, index) => (
				<div key={item.name}>
					{!!item.sublinks ? (
						<>
							<MenuItem onClick={event => handleClick(index)}>{item.name}</MenuItem>
							<Collapse in={listState[index]} timeout='auto' unmountOnExit>
								{item.sublinks.map(sublink => (
									<NavLink
										key={sublink.name}
										onClick={props.onCloseSidebar}
										className={classes.link}
										to={sublink.path}
										activeClassName={classes.activeLink}
									>
										<MenuItem className={classes.nested}>{sublink.name}</MenuItem>
									</NavLink>
								))}
							</Collapse>
						</>
					) : (
						<NavLink
							key={item.name}
							onClick={props.onCloseSidebar}
							className={classes.mainLink}
							to={item.path}
							exact
							activeClassName={classes.activeLink}
						>
							<MenuItem>{item.name}</MenuItem>
						</NavLink>
					)}
				</div>
			))}
		</MenuList>
	);

	return (
		<>
			<Hidden smUp implementation='css'>
				<Drawer
					variant='temporary'
					anchor={'left'}
					open={props.isOpen}
					onClose={props.sideHandler}
					classes={{
						paper: classes.drawerPaper
					}}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					<div className='sidenav__header'>
						<IconButton onClick={props.sideHandler} className={classes.margin}>
							<CloseOutlinedIcon />
						</IconButton>
						<Typography variant='h6' className={classes.title}>
							УОМШ
						</Typography>
					</div>
					<nav className='sidenav-root'>{SideMenuList}</nav>
				</Drawer>
			</Hidden>
			<Hidden xsDown implementation='css'>
				<Drawer variant='permanent' open transitionDuration={150}>
					<div className='sidenav__header'>
						{/* <IconButton onClick={props.sideHandler} className={classes.margin}>
						<CloseOutlinedIcon />
					</IconButton> */}
						<Typography variant='h6' className={classes.title}>
							Меню
						</Typography>
					</div>
					<nav className='sidenav-root'>{SideMenuList}</nav>
				</Drawer>
			</Hidden>
		</>
	);
}

export default Sidebar;
