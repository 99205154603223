import React from 'react'
import Typography from '@material-ui/core/Typography'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import { YEAR } from '../../constants'

const Accommodation = () => {
    return (
        <LayoutPage headling='Варианты проживания'>
            <>
                <Typography variant='body1' paragraph>Оргкомитет имеет возможность забронировать места в гостинице УУНиТ или других гостиницах г. Уфы.</Typography>
                <Typography variant='body1' paragraph>Оргкомитет просит иногородних участников о следующем:</Typography>
                <ul>
                    <li>
                        <Typography>Своевременно сообщить в оргкомитет о дате своего приезда в Уфу, а также о необходимости встретить Вас (в этом случае сообщите номер рейса поезда или самолета)</Typography>
                    </li>
                    <li>
                        <Typography>Не позднее 20 сентября {YEAR} сообщить о необходимости забронировать гостиницу</Typography>
                    </li>
                </ul>
            </>
        </LayoutPage>
    )
}

export default Accommodation
